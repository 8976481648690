import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserState {
	name: string;
	loggedIn: boolean;
	lastPlayed: number;
	config_version: number;
}

const initialState: UserState = {
	name: "",
	loggedIn: false,
	lastPlayed: Date.now(),
	config_version: 4,
};

const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		loadUserState: (state, action: PayloadAction<UserState>) => {
			Object.assign(state, action.payload);
		},
		updateLastPlayed: (state) => {
			state.lastPlayed = Date.now();
		},
		logIn: (state, action: PayloadAction<string>) => {
			state.name = action.payload;
			state.loggedIn = true;
		},
		logOut: (state) => {
			state.name = "";
			state.loggedIn = false;
		},
	},
});

export const { logIn, logOut, loadUserState, updateLastPlayed } = userSlice.actions;
export const userInitialState = initialState;
export default userSlice.reducer;

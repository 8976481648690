import React from "react";
import { Col, Image, OverlayTrigger, Popover, ProgressBar, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { PlanetType } from "../../types/PlanetType";
import { ImageSize as EImageSize, ImageSize } from "../../utils/assetsUtils";
import OreImage from "../OreImage";
import "./index.css";
import Planet from "../../engine/Planet";

interface PlanetImageProps {
	planet: PlanetType;
	size?: EImageSize;
}

const PlanetImage: React.FC<PlanetImageProps> = ({ planet, size = EImageSize.Small }) => {
	const { t } = useTranslation();
	const { maxDepth } = useSelector((state: RootState) => state.game);

	const renderPopover = (
		<Popover id={`popover-${planet.identifier}`}>
			<Popover.Header as="h3">{t(`planet.${planet.identifier}.name`)}</Popover.Header>
			<Popover.Body>
				<Row className="planetOres">
					{planet.ores.map((ore) => (
						<Col key={ore} className="my-2" md={4}>
							<OreImage size={ImageSize.Medium} ore={ore} popup={false} />
						</Col>
					))}
				</Row>
				<ProgressBar
					className={"my-3"}
					now={maxDepth}
					max={planet.ascendingDepth}
					label={`${(maxDepth > planet.ascendingDepth
						? planet.ascendingDepth
						: maxDepth
					).toLocaleString()}/${planet.ascendingDepth.toLocaleString()}`}
				/>
				<hr></hr>
				<div className={"italic"}>{t(`planet.${planet.identifier}.description`)}</div>
			</Popover.Body>
		</Popover>
	);

	return (
		<OverlayTrigger trigger={["hover", "focus"]} placement="auto" overlay={renderPopover}>
			<div className="planet-image-container">
				<Image src={new Planet(planet.id).getImage()} draggable={false} />
			</div>
		</OverlayTrigger>
	);
};

export default PlanetImage;

import BigNumber from "bignumber.js";
import { toast } from "react-toastify";
import { itemData } from "../../data/itemData";
import i18n from "../../i18n/i18n";
import { addItemEntry, equipItem, removeItemEntry, updateItemEntry } from "../../redux/slices/gameSlice";
import { store } from "../../redux/store";
import { EItemType } from "../../types/EItemType";
import GameEvent from "../../types/GameEvent";
import { ItemEntry } from "../../types/ItemEntry";
import GameEventManager from "../GameEventManager";
import { updateBonus } from "../gameState";

export const handleEquipItemAction = (item: ItemEntry, type: EItemType, index?: number) => {
	const baseItem = itemData[item.itemID];
	const { equipment } = store.getState().game;

	if (baseItem.type === EItemType.PRODUCT || baseItem.type === EItemType.POTION) {
		toast.error(i18n.t("inventory.slot.error.cantEquipType"));
		return;
	}

	if (baseItem.type === EItemType.RELIC) {
		if (index === undefined) {
			toast.error(i18n.t("inventory.slot.error.noIndex"));
			return;
		}

		if (index > 3) {
			toast.error(i18n.t("inventory.slot.error.invalidIndex"));
			return;
		}

		if (equipment[baseItem.type][index] !== null) {
			store.dispatch(addItemEntry(equipment[baseItem.type][index] as ItemEntry));
		}

		store.dispatch(equipItem({ item, type, index }));
		store.dispatch(removeItemEntry(item));

		GameEventManager.getInstance().registerEvent(new GameEvent(() => true, () => updateItemBonusToState(item), 50, false));
		return;
	}

	if(type === EItemType.RELIC){
		return;
	}

	GameEventManager.getInstance().registerEvent(new GameEvent(() => true, () => updateItemBonusToState(item), 50, false));

	if (equipment[baseItem.type] !== null) {
		store.dispatch(addItemEntry(equipment[baseItem.type] as ItemEntry));
		const item2 = equipment[baseItem.type] as ItemEntry;
		GameEventManager.getInstance().registerEvent(new GameEvent(() => true, () => updateItemBonusToState(item2), 50, false));
	}

	store.dispatch(equipItem({ item, type }));
	store.dispatch(removeItemEntry(item));
};

export const handleUnequipItemAction = (type: EItemType, index?: number) => {
	const { equipment } = store.getState().game;

	switch (type) {
		case EItemType.RELIC:
			if (index === undefined) {
				toast.error(i18n.t("inventory.slot.error.noIndex"));
				return;
			}

			if (index > 3) {
				toast.error(i18n.t("inventory.slot.error.invalidIndex"));
				return;
			}

			if (equipment[type][index] === null) {
				toast.error(i18n.t("inventory.slot.error.noItem"));
				return;
			}

			store.dispatch(addItemEntry(equipment[type][index] as ItemEntry));
			store.dispatch(equipItem({ item: null, type, index }));
			GameEventManager.getInstance().registerEvent(new GameEvent(() => true, () => updateItemBonusToState(equipment[type][index] as ItemEntry), 100, false));
			return;
		case EItemType.HELMET:
		case EItemType.PICKAXE:
		case EItemType.ARMOR:
		case EItemType.GLOVES:
		case EItemType.BOOTS:
			if (equipment[type] === null) {
				toast.error(i18n.t("inventory.slot.error.noItem"));
				return;
			}

			store.dispatch(addItemEntry(equipment[type] as ItemEntry));
			store.dispatch(equipItem({ item: null, type }));
			GameEventManager.getInstance().registerEvent(new GameEvent(() => true, () => updateItemBonusToState(equipment[type] as ItemEntry), 100, false));
			return;
		default:
			throw new Error("Invalid item type");
	}
};

export const handleCombineItemsAction = (master: ItemEntry, sacrifice: ItemEntry) => {
	if(master.id === sacrifice.id){
		return;
	}

    if(master.itemID !== sacrifice.itemID){
        toast.error(i18n.t("inventory.slot.error.combineDifferentItems"));
        return;
    }

    if(master.level !== sacrifice.level){
        toast.error(i18n.t("inventory.slot.error.combineDifferentLevels"));
        return;
    }

    if([EItemType.HELMET, EItemType.ARMOR, EItemType.GLOVES, EItemType.BOOTS, EItemType.PICKAXE, EItemType.RELIC].includes(itemData[master.itemID].type)){
        master = {...master, "level": BigNumber(master.level).plus(1).toString()};
        store.dispatch(removeItemEntry(sacrifice));
        store.dispatch(updateItemEntry(master));

		GameEventManager.getInstance().registerEvent(new GameEvent(() => true, () => updateItemBonusToState(master), 50, false));

        toast.success(i18n.t("inventory.slot.combine.success"));

        return;
    }

    master = {...master, "count": BigNumber(master.count).plus(sacrifice.count).toString()};
    store.dispatch(removeItemEntry(sacrifice));
    store.dispatch(updateItemEntry(master));

	GameEventManager.getInstance().registerEvent(new GameEvent(() => true, () => updateItemBonusToState(master), 50, false));

    toast.success(i18n.t("inventory.slot.combine.success"));
}

export const updateItemBonusToState = (item: ItemEntry) => {
	item.bonusList?.forEach((bonus) => {
		updateBonus(bonus.type);
	});

	itemData[item.itemID].bonusList?.forEach((bonus) => {
		updateBonus(bonus.type);
	});
}
import BigNumber from "bignumber.js";
import { ascensionSkillData } from "../data/ascensionSkillData";
import { store } from "../redux/store";
import { EAscensionSkill } from "../types/EAscensionSkill";
import IAscensionSkill from "../types/IAscensionSkill";
import config from "../config";
import EBonuses from "../types/EBonuses";
import { toast } from "react-toastify";
import i18n from "../i18n/i18n";
import { addAscensionPoints, increaseAscensionSkill, performAscension } from "../redux/slices/gameSlice";
import { getUserBonus, updateBonus, updateBonuses } from "./gameState";
import GameEventManager from "./GameEventManager";
import GameEvent from "../types/GameEvent";
import { ImageSize, assetsBasePath } from "../utils/assetsUtils";
import { handleGenerateNewGridAction } from "./gameActions";

export default class AscensionSkill {
	skillInterface: IAscensionSkill;

	constructor(skill: EAscensionSkill) {
		this.skillInterface = ascensionSkillData[skill];
	}

	upgrade(amount: BigNumber = BigNumber(1)) {
		const { ascensionPoints } = store.getState().game;

		const cost = this.getCost(amount);

		if (cost.isGreaterThan(ascensionPoints)) {
			toast.error(i18n.t("toasts.ascension.skill.error.notEnoughPoints"));
			return;
		}

		store.dispatch(increaseAscensionSkill({ skill: this.skillInterface.type, amount: amount.toString() }));
		store.dispatch(addAscensionPoints(cost.negated().toString()));
		GameEventManager.getInstance().registerEvent(
			new GameEvent(
				() => true,
				() => {
					updateBonus(this.skillInterface.bonusType);
				},
				50,
				false
			)
		);

		toast.success(
			i18n.t("toasts.ascension.skill.success", {
				name: i18n.t(`ascension.skills.${EAscensionSkill[this.skillInterface.type].toLowerCase()}.title`),
				value: amount.toString(),
			})
		);
	}

	getLevel(): BigNumber {
		return BigNumber(store.getState().game.ascensionSkills[this.skillInterface.type]);
	}

	getIdentifier(): string {
		return this.skillInterface.identifier;
	}

	getType(): EAscensionSkill {
		return this.skillInterface.type;
	}

	getCost(amount: BigNumber = BigNumber(1)): BigNumber {
		let cost = BigNumber(0);

		for (let i = 0; amount.isGreaterThanOrEqualTo(i); i++) {
			cost = cost.plus(this.skillInterface.costIncrease(this.getLevel().plus(i)).times(this.skillInterface.cost));
		}

		return cost;
	}

	getEffect(levelOffset: BigNumber = BigNumber(0)): BigNumber {
		return this.skillInterface.bonusIncrease(this.getLevel().plus(levelOffset)).times(this.skillInterface.bonusAmount);
	}

	getBonusType(): EBonuses {
		return this.skillInterface.bonusType;
	}

	getBonusAmount(): number {
		return this.skillInterface.bonusAmount;	
	}

    getImage = (size: ImageSize = ImageSize.Original) => {
        return `${assetsBasePath}/ascension/${this.skillInterface.identifier}${size !== ImageSize.Original ? "_" + size : ""}.webp`;

    }


	static getResetPoints(): BigNumber {
		const { maxDepth } = store.getState().game;
		const points = Math.floor(Math.log(Math.pow(maxDepth, 2)) * config.game.ascendingPointsFactor);

		return new BigNumber(points);
	}

	static getTotalBonusValueOfAscension(bonus: EBonuses): BigNumber {
		const { ascensionSkills } = store.getState().game;
		let totalBonus = BigNumber(0);

		Object.entries(ascensionSkills).forEach(([skillKey, level]) => {
			const skill: AscensionSkill = new AscensionSkill(skillKey as unknown as EAscensionSkill);

			if (skill.getBonusType() === bonus) {
				totalBonus = totalBonus.plus(skill.getEffect());
			}
		});

		return totalBonus;
	}

	static performAscension() {
		const { planet, maxDepth } = store.getState().game;
		if (planet.ascendingDepth > maxDepth) toast.error(i18n.t("toasts.ascension.error.maxDepth", { depth: planet.ascendingDepth }));

		const resetPoints = AscensionSkill.getResetPoints();

		store.dispatch(performAscension({resetPoints : resetPoints.toString(), newDepth: getUserBonus(EBonuses.ASCENDING_TO_DEPTH).toNumber()}));

		GameEventManager.getInstance().registerEvent(new GameEvent(() => true, handleGenerateNewGridAction, 50, false))
		GameEventManager.getInstance().registerEvent(new GameEvent(() => true, updateBonuses, 50, false))
	
		toast.success(i18n.t("toasts.ascension.success", { points: resetPoints }));
	}
}

import React from "react";
import { Card, ProgressBar, Button, Row, Col, ListGroup } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { formatNumber, formatTimeSpan } from "../../engine/gameUtils";
import { useTranslation } from "react-i18next";
import BigNumber from "bignumber.js";
import missionData from "../../data/missionData";
import IMissionSubjectiv, { EMissingSubjectivTypes } from "../../types/IMissionSubjectiv";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faBoxOpen, faClock, faDownload, faHammer } from "@fortawesome/free-solid-svg-icons";
import { EOreTypes } from "../../types/EOreTypes";
import { depositMissionObjectiveAction, handleCancelMissionAction, handleCompleteMissionAction } from "../../engine/gameActions/MissionActions";

export const InProgressMissionDetails = ({ missionId }: { missionId: number }) => {
	const { t } = useTranslation();
	const missionInProgress = useSelector((state: RootState) => state.game.missions.inProgress[missionId]);
	const mission = missionData[missionInProgress.missionId];

	if (!missionInProgress || !mission) {
		return <div>{t("mission.missionNotFound")}</div>;
	}

	const calculateProgress = (current: string, required: string) => {
		const currentBN = new BigNumber(current);
		const requiredBN = new BigNumber(required);
		return currentBN.dividedBy(requiredBN).multipliedBy(100).toNumber();
	};

	const renderSubjective = (index: number, subjective: IMissionSubjectiv) => {
		switch (subjective.type) {
			case EMissingSubjectivTypes.DESTROY_ORES:
				if (subjective.subjectiveItemId === undefined) {
					throw new Error("Missing subjective item id");
				}

				return (
					<Col md={4}>
						<FontAwesomeIcon icon={faHammer} />{" "}
						{t("ore." + (EOreTypes[subjective.subjectiveItemId]).toLocaleLowerCase() + ".name")}
					</Col>
				);

			case EMissingSubjectivTypes.COMPLETE_DEPHS:
				return (
					<Col md={4}>
						<FontAwesomeIcon icon={faArrowDown} /> {t("mission.depth")}
					</Col>
				);

			case EMissingSubjectivTypes.DELIVER_ITEMS:
				return (
					<Col md={4}>
						<FontAwesomeIcon icon={faBoxOpen} /> {t("items." + subjective.subjectiveItemId + ".name")}
					</Col>
				)

			case EMissingSubjectivTypes.DELIVER_ORES:
				if (subjective.subjectiveItemId === undefined) {
					throw new Error("Missing subjective item id");
				}

				return (
					<Col md={4}>
						<FontAwesomeIcon icon={faBoxOpen} />{" "}
						{t("ore." + EOreTypes[subjective.subjectiveItemId].toLocaleLowerCase() + ".name")}
					</Col>
				);
			default:
				throw new Error(`Unknown subjective type: ${subjective.type}`);
		}
	};

	return (
		<Card className="mb-4">
			<Card.Header>
				<Row>
					<Col md={9}>{t(`mission.${mission.id}.title`)}</Col>
					<Col md={3} className="align-right">
						<FontAwesomeIcon icon={faClock} />{" "}
						{formatTimeSpan((missionInProgress.acceptedTime + mission.time * 1000 - Date.now()) / 1000)}
					</Col>
				</Row>
			</Card.Header>
			<ListGroup variant="flush">
				{Object.entries(missionInProgress.currentProgress).map(([id, progress], index) => (
					<ListGroup.Item key={id}>
						<Row>
							{renderSubjective(parseInt(id), mission.subjective[parseInt(id)])}
							<Col md={6}>
								<ProgressBar
									now={calculateProgress(progress.current, progress.required)}
									label={`${formatNumber(BigNumber(progress.current))}/${formatNumber(BigNumber(progress.required))}`}
								/>
							</Col>
							<Col md={2}>
								{[EMissingSubjectivTypes.DELIVER_ITEMS, EMissingSubjectivTypes.DELIVER_ORES].includes(
									mission.subjective[parseInt(id)].type
								) && (
									<Button variant="primary" onClick={() => depositMissionObjectiveAction(missionId, parseInt(id))} disabled={calculateProgress(progress.current, progress.required) === 100}>
										<FontAwesomeIcon icon={faDownload} />
									</Button>
								)}
							</Col>
						</Row>
					</ListGroup.Item>
				))}
			</ListGroup>
			<Card.Body>
				<Row>
					<Col md={6}>
						<Button
							variant="success"
							onClick={() => handleCompleteMissionAction(missionId)}
							disabled={Object.values(missionInProgress.currentProgress).some((progress) =>
								new BigNumber(progress.current).lt(progress.required)
							) || (missionInProgress.acceptedTime + mission.time * 1000 - Date.now()) < 0}
						>
							{t("mission.completeMission")}
						</Button>
					</Col>
					<Col className="align-right" md={6}>
						<Button variant="danger" onClick={() => handleCancelMissionAction(missionId)}>
							{t("mission.cancelMission")}
						</Button>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	);
};

import BigNumber from "bignumber.js";

export enum EMissingSubjectivTypes {
	DESTROY_ORES,
	COMPLETE_DEPHS,
	DELIVER_ITEMS,
	DELIVER_ORES
}

export default interface IMissionSubjectiv {
    type: EMissingSubjectivTypes;
    id: number;

    subjectiveItemId?: number;
    value: () => BigNumber | number;
}
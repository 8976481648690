// src/engine/gameActions.ts
import BigNumber from 'bignumber.js';
import { addCredits, addMiner, addMinerUpgrade, setGrid } from '../../redux/slices/gameSlice';
import { store } from '../../redux/store';
import EBonuses from '../../types/EBonuses';
import GameEvent from '../../types/GameEvent';
import GameEventManager from '../GameEventManager';
import { updateBonus } from '../gameState';
import { calculateUpgradeCost, getMinerById } from '../gameUtils/MinerUtils';
import IMiner from '../../types/IMiner';
import Planet from '../Planet';

export const handleGenerateNewGridAction = () => {
    const { planet } = store.getState().game;

    const grid = new Planet().createMiningMap()

    store.dispatch(setGrid(grid));
}

export const handleBuyMinerAction = (id: number, amount: BigNumber) => {
    const { credits, miner } = store.getState().game;

    const currentMiner = miner.find(miner => miner.id === id) as IMiner;

    const startLevel: BigNumber = currentMiner === undefined ? BigNumber(0) : BigNumber(currentMiner.level);

    const price = calculateUpgradeCost(getMinerById(id).basePrice, startLevel, amount, getMinerById(id).factors.price);

    if(BigNumber(credits).isLessThan(price)){
        GameEventManager.getInstance().registerEvent(new GameEvent(() => true, () => handleShowBuyCreditsAction(price), 0, false));
        return;
    }

    store.dispatch(addCredits(price.negated().toString()));
    store.dispatch(addMiner({ id, amount: amount.toString() }));

    GameEventManager.getInstance().registerEvent(new GameEvent(() => true, () => updateBonus(EBonuses.CLICK_DAMAGE_CALC), 0, false));
}

export const handleBuyMinerUpgradeAction = (id: number, upgradeId: number) => {
    const { miner, credits } = store.getState().game;

    const currentMiner = miner.find(m => m.id === id);

    const upgrade = getMinerById(id).upgrades.find(u => u.id === upgradeId);   

    if(!currentMiner || !upgrade || !BigNumber(credits).gte(upgrade.price) || currentMiner.upgradeIDs.includes(upgradeId))
        return;

    store.dispatch(addCredits(upgrade.price.negated().toString()));
    store.dispatch(addMinerUpgrade({ id, upgradeId }));

    GameEventManager.getInstance().registerEvent(new GameEvent(() => true, () => updateBonus(upgrade.bonusType), 50, false));
}

export const handleShowBuyCreditsAction = (price: BigNumber) => {
    console.log(`Offer to buy ${price} credits`);
}

export const performGameActions = () => {

};
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getDefaultIconForType } from "../../engine/gameUtils/ItemUtils";
import { EItemType } from "../../types/EItemType";
import { ItemEntry } from "../../types/ItemEntry";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { itemData } from "../../data/itemData";
import { RootState } from "../../redux/store";
import { EConfig } from "../../types/EConfig";
import { InventorySlot } from "../InventorySlot/InventorySlot";
import "./index.css";

interface EquipmentSlotProps {
	type: EItemType;
	index?: number;
	item: ItemEntry | null;
	onEquip: (item: ItemEntry, type: EItemType, index?: number) => void;
	onUnequip: (type: EItemType, index?: number) => void;
}

export const EquipmentSlot: React.FC<EquipmentSlotProps> = ({ type, index, item, onEquip, onUnequip }) => {
	const { config } = useSelector((state: RootState) => state.game);
	const { t } = useTranslation();

	const handleDragOver = (event: React.DragEvent) => {
		const itemEntry: ItemEntry = JSON.parse(config[EConfig.DRAGGED_ITEM]);

		if (itemData[itemEntry.itemID].type === type) {
			event.preventDefault();
		}
	};

	const handleDrop = (event: React.DragEvent) => {
		const itemEntry: ItemEntry = JSON.parse(config[EConfig.DRAGGED_ITEM]);

		if (itemData[itemEntry.itemID].type !== type) {
			toast.error(t("inventory.slot.error.wrongType"));
			return false;
		}

		onEquip(itemEntry, type, index);
		event.preventDefault();
	};

	return (
		<div
			className="slot"
			onClick={() => (item != null ? onUnequip(type, index) : null)}
			onDragOver={handleDragOver}
			onDrop={handleDrop}
		>
			{item ? (
				<InventorySlot item={item} />
			) : (
				<FontAwesomeIcon className={"slot-icon"} icon={getDefaultIconForType(type)} />
			)}
		</div>
	);
};

import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BigNumber from "bignumber.js";
import React from "react";
import { Card, ProgressBar } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import IBlueprint from "../../types/IBlueprint";

import { formatNumber } from "../../engine/gameUtils";
import { EOreTypes } from "../../types/EOreTypes";
import { ImageSize } from "../../utils/assetsUtils";
import { InventorySlot } from "../InventorySlot/InventorySlot";
import OreImage from "../OreImage";
import "./RefineryInfoComponent.css";
import RefineryState from "../../types/RefineryState";
import Refinery from "../../engine/Refinery";

interface RefineryInfoComponentProps {
	blueprint?: IBlueprint;
	productionProgress: number;
	refinery: Refinery;
}

const RefineryInfoComponent: React.FC<RefineryInfoComponentProps> = ({ blueprint, productionProgress, refinery }) => {
	const { t } = useTranslation();

	if (!blueprint) {
		return (
			<Card>
				<Card.Body>{t("refinery.noBlueprintSelected")}</Card.Body>
			</Card>
		);
	}

	return (
		<Card className="mt-3">
			<Card.Body>
				<div className="refinery-info">
					<div className="refinery-inputs">
						{blueprint.input.map((input, index) =>
							input.itemId !== undefined ? (
								<div key={index}>
									<InventorySlot
										item={{
											id: 0,
											itemID: input.itemId,
											level: "1",
											count: formatNumber(new BigNumber(input.quantity)),
										}}
									/>
								</div>
							) : (
								<OreImage key={index} ore={input.oreType as EOreTypes} size={ImageSize.Medium} count={BigNumber(input.quantity)} />
							)
						)}
					</div>
					<div>
						<FontAwesomeIcon icon={faArrowRight} className="mx-2" />
						<br></br>
						{ formatNumber(refinery.calculateSpeedMultiplier(), 0, true) }
					</div>
					<div className="refinery-output">
						{blueprint.output.map((output, index) =>
							output.itemId !== undefined ? (
								<div key={index}>
									<InventorySlot
										item={{
											id: 0,
											itemID: output.itemId,
											level: "1",
											count: formatNumber(new BigNumber(output.quantity)),
										}}
									/>
								</div>
							) : (
								<div key={output.itemId} className={`icon-img ore ore-${output.itemId}`}>
									{output.quantity}
								</div>
							)
						)}
					</div>
				</div>
				<ProgressBar now={productionProgress} label={`${productionProgress.toFixed(2)}%`} />
			</Card.Body>
		</Card>
	);
};

export default RefineryInfoComponent;

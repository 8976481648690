import {
    faArrowDown,
    faBahtSign,
    faBoxOpen,
    faClock,
    faGear,
    faGears,
    faHammer
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BigNumber from "bignumber.js";
import { Button, Card, Col, ListGroup, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import config from "../../config";
import missionData from "../../data/missionData";
import { handleStartMissionAction } from "../../engine/gameActions/MissionActions";
import { formatNumber, formatTimeSpan } from "../../engine/gameUtils";
import { RootState, store } from "../../redux/store";
import { EOreTypes } from "../../types/EOreTypes";
import IMission from "../../types/IMission";
import IMissionReward, { EMissionRewardTypes } from "../../types/IMissionReward";
import IMissionSubjectiv, { EMissingSubjectivTypes } from "../../types/IMissionSubjectiv";

export const AvailableMissionDetails = ({ missionId }: { missionId: number }) => {
	const mission: IMission = missionData[missionId];
	const { inProgress } = useSelector((state: RootState) => state.game.missions);

	const { t } = useTranslation();

	const renderSubjective = (index: number, subjective: IMissionSubjectiv) => {
		switch (subjective.type) {
			case EMissingSubjectivTypes.DESTROY_ORES:
				if (subjective.subjectiveItemId === undefined) {
					throw new Error("Missing subjective item id");
				}

				return (
					<Row>
						<Col md={2}>
							<FontAwesomeIcon icon={faHammer} /> {t("mission.task")} {index + 1}
						</Col>
						<Col md={2}>x{formatNumber(BigNumber(subjective.value()))}</Col>
						<Col md={8}>{t("ore." + EOreTypes[subjective.subjectiveItemId].toLocaleLowerCase() + ".name")}</Col>
					</Row>
				);

			case EMissingSubjectivTypes.COMPLETE_DEPHS:
				return (
					<Row>
						<Col md={2}>
							<FontAwesomeIcon icon={faArrowDown} /> {t("mission.task")} {index + 1}
						</Col>
						<Col md={10}>
							{store.getState().game.maxDepth} / {formatNumber(BigNumber(subjective.value()))}
						</Col>
					</Row>
				);

			case EMissingSubjectivTypes.DELIVER_ITEMS:
				return (
					<Row>
						<Col md={2}>
							<FontAwesomeIcon icon={faBoxOpen} /> {t("mission.task")} {index + 1}
						</Col>
						<Col md={2}>x{formatNumber(BigNumber(subjective.value()))}</Col>
						<Col md={8}>{t("items." + subjective.subjectiveItemId + ".name")}</Col>
					</Row>
				)

			case EMissingSubjectivTypes.DELIVER_ORES:
				if (subjective.subjectiveItemId === undefined) {
					throw new Error("Missing subjective item id");
				}

				return (
					<Row>
						<Col md={2}>
							<FontAwesomeIcon icon={faBoxOpen} /> {t("mission.task")} {index + 1}
						</Col>
						<Col md={2}>x{formatNumber(BigNumber(subjective.value()))}</Col>
						<Col md={8}>{t("ore." + EOreTypes[subjective.subjectiveItemId].toLocaleLowerCase() + ".name")}</Col>
					</Row>
				);
			default:
				throw new Error(`Unknown subjective type: ${subjective.type}`);
		}
	};

	const renderReward = (reward: IMissionReward) => {
        var gain
		switch (reward.type) {
			case EMissionRewardTypes.ITEM:
				gain = reward.value();
				return (
					<Row>
						<Col md={1}>
							<FontAwesomeIcon icon={faGear} />
						</Col>
						<Col md={2}>x{formatNumber(gain)}</Col>
						<Col md={9}>{t("items." + reward.identifier + ".name")}</Col>
					</Row>
				);
			case EMissionRewardTypes.CURRENCY:
				gain = reward.value();

				return (
					<Row>
						<Col md={1}>
							<FontAwesomeIcon icon={faBahtSign} />
						</Col>
						<Col md={11}>{formatNumber(gain, 2)}</Col>
					</Row>
				);

			case EMissionRewardTypes.REFINERY:
				return (
					<Row className="legendary">
						<Col md={1}>
							<FontAwesomeIcon icon={faGears} />
						</Col>
						<Col md={11}>{t("tabs.refinery")}</Col>
					</Row>
				)

			default:
				throw new Error(`Unknown reward type: ${reward.type}`);
		}
	};

	return (
		<Card>
			<Card.Header>
				<Row>
					<Col md={10}>{t("mission." + missionId + ".title")}</Col>
					<Col md={2} className="align-right">
						<FontAwesomeIcon icon={faClock} /> {formatTimeSpan(mission.time)}
					</Col>
				</Row>
			</Card.Header>
			<ListGroup variant="flush">
				{Object.values(mission.subjective).map((subjective, index) => (
					<ListGroup.Item key={`subjective-${missionId}-${subjective.id || index}`}>
						{renderSubjective(index, subjective)}
					</ListGroup.Item>
				))}
			</ListGroup>
			<Card.Body>
				<Row>
					<Col md={8}>
						<Card.Subtitle>{t("mission.rewards")}</Card.Subtitle>
						{mission.rewards.map((reward, index) => (
							<div key={`reward-${missionId}-${reward.identifier || index}`}>
								{renderReward(reward)}
							</div>
						))}
					</Col>
					<Col md={4}>
						<Button
							onClick={() => handleStartMissionAction(missionId)}
							disabled={Object.keys(inProgress).length >= config.mission.maxMissionsActive}
						>
							{Object.keys(inProgress).length >= config.mission.maxMissionsActive
								? t("mission.startLimitReached")
								: t("mission.start")}
						</Button>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	);
};

export enum EOreTypes {
    Empty = 0,
    Sand,
    Rock,
    Iron,
    Copper,
    Gold,
    Graphite,
    MysticQuartz,
    GalacticStone,
    Coal,
    Titanium,
    Uranium,
    
    Crate = 99,
    
}
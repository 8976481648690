import { toast } from "react-toastify";
import { codesData } from "../data/codesData";
import { store } from "../redux/store";
import { ICode } from "../types/ICode";
import i18n from "../i18n/i18n";
import MissionReward from "./MissionReward";
import { addEnabledCode } from "../redux/slices/gameSlice";

export class Code {
    code: string;
    codeInterface: ICode;

    constructor(code: string) {
        this.code = code;
        this.codeInterface = codesData[code];
    }

    validate(): boolean {
        if(!this.codeInterface){
            toast.error(i18n.t("codes.invalid", { code: this.code }));
            return false;
        }
        

        if(this.isAlreadyUsed()){
            toast.error(i18n.t("codes.alreadyUsed", { code: this.code }));
            return false;
        }

        if(this.isExpired()){
            toast.error(i18n.t("codes.expired", { code: this.code }));
            return false
        }
        
        if(!this.codeInterface.available()){
            toast.error(i18n.t("codes.notAvailable", { code: this.code }));
            return false;
        }
    
        return true;
    }

    activate(): boolean {
        if(!this.validate())
            return false;

        Object.entries(this.codeInterface.rewards).forEach(([key, value]) => {
            const reward = new MissionReward(value);
            reward.activate();
        });

        store.dispatch(addEnabledCode(this.code));
        return true;
    }

    isAlreadyUsed(): boolean {
        return store.getState().game.enabledCodes.includes(this.code);
    }

    isExpired(): boolean {
        return this.codeInterface.expires < Date.now();
    }
}
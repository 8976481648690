export enum EAscensionSkill{
    MINERS_PICKAXE,
    MINERS_TECHNOLOGY,
    MINERS_CASH,
    MINERS_LUCK,
    MINERS_EFFICIENCY,
    DEEP_CORE_MINING,
    CRITICAL_ENGINEERING,
    STELLAR_NAVIGATION,
    QUANTUM_SCANNERS,
}
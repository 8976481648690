import { EOreTypes } from '../types/EOreTypes';
import { OreType } from '../types/OreType';
import BigNumber from 'bignumber.js';

export const oresData: Record<EOreTypes, OreType> = {
  [EOreTypes.Empty]: {
    id: EOreTypes.Empty, 
    identifier: "empty",
    rarity: new BigNumber(0).toString(),
    hp: new BigNumber(0).toString(), 
    sell_price: new BigNumber(0).toString()
  },
  [EOreTypes.Crate]: {
    id: EOreTypes.Crate,
    identifier: "crate",
    rarity: new BigNumber(500).toString(),
    hp: new BigNumber(10).toString(), 
    sell_price: new BigNumber(0).toString()
  },
  [EOreTypes.Sand]: {
    id: EOreTypes.Sand,
    identifier: "sand",
    rarity: new BigNumber(1).toString(),
    hp: new BigNumber(2).toString(), 
    sell_price: new BigNumber(2).toString() 
  },
  [EOreTypes.Rock]: {
    id: EOreTypes.Rock,
    identifier: "rock",
    rarity: new BigNumber(1).toString(),
    hp: new BigNumber(5).toString(), 
    sell_price: new BigNumber(5).toString() 
  },
  [EOreTypes.Coal]: {
    id: EOreTypes.Coal,
    identifier: "coal",
    rarity: new BigNumber(5).toString(),
    hp: new BigNumber(20).toString(), 
    sell_price: new BigNumber(15).toString()
  },
  [EOreTypes.Iron]: {
    id: EOreTypes.Iron, 
    identifier: "iron",
    rarity: new BigNumber(10).toString(), 
    hp: new BigNumber(150).toString(), 
    sell_price: new BigNumber(25).toString() 
  },
  [EOreTypes.Copper]: {
    id: EOreTypes.Copper,
    identifier: "copper",
    rarity: new BigNumber(15).toString(), 
    hp: new BigNumber(200).toString(), 
    sell_price: new BigNumber(35).toString() 
  },
  [EOreTypes.Gold]: {
    id: EOreTypes.Gold, 
    identifier: "gold",
    rarity: new BigNumber(100).toString(), 
    hp: new BigNumber(300).toString(), 
    sell_price: new BigNumber(500).toString() 
  },
  [EOreTypes.Graphite]: {
    id: EOreTypes.Graphite,
    identifier: "graphite",
    rarity: new BigNumber(500).toString(), 
    hp: new BigNumber(500).toString(), 
    sell_price: new BigNumber(1000).toString() 
  },
  [EOreTypes.Titanium]: {
    id: EOreTypes.Titanium, 
    identifier: "titanium",
    rarity: new BigNumber(1000).toString(), 
    hp: new BigNumber(750).toString(), 
    sell_price: new BigNumber(2000).toString() 
  },
  [EOreTypes.MysticQuartz]: {
    id: EOreTypes.MysticQuartz,
    identifier: "mysticQuartz",
    rarity: new BigNumber(2000).toString(), 
    hp: new BigNumber(1000).toString(), 
    sell_price: new BigNumber(5000).toString() 
  },
  [EOreTypes.GalacticStone]: {
    id: EOreTypes.GalacticStone, 
    identifier: "galacticStone",
    rarity: new BigNumber(10000).toString(),
    hp: new BigNumber(2000).toString(), 
    sell_price: new BigNumber(10000).toString() 
  },
  [EOreTypes.Uranium]: {
    id: EOreTypes.Uranium,
    identifier: "uranium",
    rarity: new BigNumber(1500).toString(),
    hp: new BigNumber(1000).toString(), 
    sell_price: new BigNumber(1500).toString() 
  },
};
import {
	IconDefinition,
	faArrowUp,
	faBolt,
	faClock,
	faCog,
	faCoins,
	faFistRaised,
	faHammer,
	faHeartCrack,
	faPercent,
	faScrewdriverWrench,
	faStar
} from "@fortawesome/free-solid-svg-icons";
import BigNumber from "bignumber.js";
import { store } from "../../redux/store";
import EBonuses from "../../types/EBonuses";
import { EOreTypes } from "../../types/EOreTypes";
import { getUserBonus } from "../gameState";
import { calculateMinerAutoDamage, calculateMinerClickDamage } from "./MinerUtils";

export function calculateRarityBonus(): BigNumber {
	return new BigNumber(1);
}

export const calculateTotalPlayerClickDamage = (): BigNumber => {
	const { miner } = store.getState().game;

	const clickDmg = miner.reduce(
		(total, m) => total.plus(calculateMinerClickDamage(m)),
		new BigNumber(0)
	);

	return clickDmg
		.plus(getUserBonus(EBonuses.CLICK_BASE_DMG))
		.plus(getUserBonus(EBonuses.CLICK_DAMAGE_ADDITION))
		.times(getUserBonus(EBonuses.CLICK_DAMAGE_USER_MULTIPLIER))
		.times(getUserBonus(EBonuses.ALL_DAMAGE_USER_MULTIPLIER));
};

export const calculateDamagePerSecond = (): BigNumber => {
	const currentState = store.getState().game;
	const clickDamage = getUserBonus(EBonuses.CLICK_DAMAGE_CALC);

	const autoClicksPerSecond = getUserBonus(EBonuses.AUTO_CLICKS_PER_SECOND);

	let autoDamage = new BigNumber(0);

	currentState.miner.forEach((miner) => {
		autoDamage = autoDamage.plus(calculateMinerAutoDamage(miner));
	});

	const totalDamage = clickDamage.times(autoClicksPerSecond).plus(autoDamage);

	return totalDamage;
};

export const formatTimeSpan = (seconds: number): string => {
	const hours = Math.floor(seconds / 3600);
	const minutes = Math.floor((seconds % 3600) / 60);
	const remainingSeconds = seconds % 60;

	var formattedTime = "";

	if (hours > 0) {
		formattedTime += `${hours}h `;
	}

	if (minutes > 0) {
		formattedTime += `${minutes}m `;
	}

	if (remainingSeconds > 0) {
		formattedTime += `${Math.floor(remainingSeconds)}s`;
	}

	if (formattedTime === "") {
		formattedTime = "0s";
	}

	return formattedTime;
}


export const formatNumber = (number: BigNumber, decimals: number = 1, percentageFormat: boolean = false): string => {
	const suffixes = [
		{ threshold: new BigNumber(1e3), suffix: "K" }, // Tausend
		{ threshold: new BigNumber(1e6), suffix: "M" }, // Million
		{ threshold: new BigNumber(1e9), suffix: "B" }, // Milliarde (Billion)
		{ threshold: new BigNumber(1e12), suffix: "T" }, // Billion (Trillion)
		{ threshold: new BigNumber(1e15), suffix: "q" }, // Billiarde (Quadrillion)
		{ threshold: new BigNumber(1e18), suffix: "Q" }, // Trillion (Quintillion)
		{ threshold: new BigNumber(1e21), suffix: "s" }, // Trilliarde (Sextillion)
		{ threshold: new BigNumber(1e24), suffix: "S" }, // Quadrillion (Septillion)
		{ threshold: new BigNumber(1e27), suffix: "o" }, // Quadrilliarde (Octillio
		{ threshold: new BigNumber(1e30), suffix: "n" }, // Quintillion (Nonillion)
		{ threshold: new BigNumber(1e33), suffix: "d" }, // Sextillion (Decillion)
		{ threshold: new BigNumber(1e36), suffix: "U" }, // Sextilliarde (Undecillion)
		{ threshold: new BigNumber(1e39), suffix: "D" }, // Septillion (Duodecillion)
		{ threshold: new BigNumber(1e42), suffix: "t" }, // Septilliarde (Tredecillion)
		{ threshold: new BigNumber(1e45), suffix: "qu" }, // Oktillion (Quattuordecillion)
		{ threshold: new BigNumber(1e48), suffix: "Qd" }, // Oktilliarde (Quindecillion)
		{ threshold: new BigNumber(1e51), suffix: "sd" }, // Nonillion (Sexdecillion)
		{ threshold: new BigNumber(1e54), suffix: "St" }, // Nonilliarde (Septendecillion)
		{ threshold: new BigNumber(1e57), suffix: "O" }, // Dezillion (Octodecillion)
		{ threshold: new BigNumber(1e60), suffix: "N" }, // Dezilliarde (Novemdecillion)
		{ threshold: new BigNumber(1e63), suffix: "v" }, // Undezillion (Vigintillion)
		{ threshold: new BigNumber(1e66), suffix: "uV" }, // Undezilliarde
		{ threshold: new BigNumber(1e69), suffix: "Dv" }, // Duodezillion
		{ threshold: new BigNumber(1e72), suffix: "tV" }, // Duodezilliarde
		{ threshold: new BigNumber(1e75), suffix: "qT" }, // Tredezillion
		{ threshold: new BigNumber(1e78), suffix: "QT" }, // Tredezilliarde
		{ threshold: new BigNumber(1e81), suffix: "sT" }, // Quattuordezillion
		{ threshold: new BigNumber(1e84), suffix: "ST" }, // Quattuordezilliarde
		{ threshold: new BigNumber(1e87), suffix: "Oc" }, // Quinquadecillion
		{ threshold: new BigNumber(1e90), suffix: "OQ" }, // Quinquadecilliarde
		{ threshold: new BigNumber(1e93), suffix: "sD" }, // Sedecillion
		{ threshold: new BigNumber(1e96), suffix: "SD" }, // Sedecilliarde
		{ threshold: new BigNumber(1e99), suffix: "Ov" }, // Septendecillion
		{ threshold: new BigNumber(1e102), suffix: "OV" }, // Septendecilliarde
		{ threshold: new BigNumber(1e105), suffix: "Nv" }, // Oktodecillion
		{ threshold: new BigNumber(1e108), suffix: "NV" }, // Oktodecilliarde
		{ threshold: new BigNumber(1e111), suffix: "Tg" }, // Novemdecillion
		{ threshold: new BigNumber(1e114), suffix: "TG" }, // Novemdecillion
	];

	const exponentThreshold = new BigNumber(1e117); // Zum Beispiel für Zahlen größer als 1e117

	if (number.gte(exponentThreshold)) {
		// Berechne das wissenschaftliche Format mit der gewünschten Genauigkeit
		const exponentValue = number.times(percentageFormat ? 100 : 1).toExponential(4); // Verwendet 5 Stellen gesamt, einschließlich des Exponenten
		// Überprüfe, ob ein Prozentsatzformat gewünscht ist
		const formattedValue = percentageFormat ? `${exponentValue} %` : exponentValue;
		return formattedValue;
	}

	for (let i = suffixes.length - 1; i >= 0; i--) {
		const { threshold, suffix } = suffixes[i];
		if (number.gte(threshold)) {
			if (percentageFormat) number = number.times(100);

			const value = number.div(threshold).toFixed(decimals);
			const formatted = `${value} ${suffix}${percentageFormat ? "%" : ""}`;

			return formatted;
		}
	}
	//console.log("fixed number: ", {old: number.toString(), new: number.toFixed(decimals)})

	if (percentageFormat) number = number.times(100);

	return number.toFixed(decimals) + (percentageFormat ? "%" : "");
};

export const getResourceCount = (type: EOreTypes): BigNumber => {
	const { resources} = store.getState().game;

	if (resources === undefined) return new BigNumber(0);

	return new BigNumber(resources[type] || 0);
};

export const getFontAwesomeIconForType = (type: EBonuses): IconDefinition => {
	switch (type) {
		case EBonuses.CLICK_DAMAGE_CALC:
			case EBonuses.CLICK_DAMAGE_ADDITION:
			case EBonuses.CLICK_DAMAGE_SELF_MULTIPLIER:
			  return faFistRaised;
			case EBonuses.ALL_DAMAGE_USER_MULTIPLIER:
			  return faBolt;
			case EBonuses.AUTO_DAMAGE_USER_MULTIPLIER:
			case EBonuses.AUTO_DAMAGE_CALC:
			case EBonuses.AUTO_DAMAGE_SELF_MULTIPLIER:
			  return faCog;
			case EBonuses.AUTO_DAMAGE_ADDITION:
			  return faScrewdriverWrench;
			case EBonuses.MINE_REWARD_MULTIPLIER:
			case EBonuses.UPGRADE_COST_REDUCTION:
			  return faCoins;
			case EBonuses.RARITY_BONUS:
			case EBonuses.CRATE_SPAWN_RATE_MULTIPLIER:
			case EBonuses.CRITICAL_HIT_CHANCE:
			case EBonuses.CRITICAL_HIT_DAMAGE:
			  return faStar;
			case EBonuses.CLICK_DAMAGE_USER_MULTIPLIER:
			  return faPercent;
			case EBonuses.CLICK_BASE_DMG:
			case EBonuses.CRAFTING_COST_REDUCTION:
			  return faHammer;
			case EBonuses.AUTO_CLICKS_PER_SECOND:
			case EBonuses.AFK_MINE_REWARD_MULTIPLIER:
			case EBonuses.REFINERY_SPEED:
			  return faClock;
			case EBonuses.REDUCE_ORE_HP:
			  return faHeartCrack;
			case EBonuses.ASCENDING_TO_DEPTH:
			  return faArrowUp;
		default:
			throw new Error(`Type ${type} not implemented`);
	}
};

export const isBonusTypePercentage = (type: EBonuses): boolean => {
	switch (type) {
		case EBonuses.ALL_DAMAGE_USER_MULTIPLIER:
		case EBonuses.AUTO_DAMAGE_SELF_MULTIPLIER:
		case EBonuses.AUTO_DAMAGE_USER_MULTIPLIER:
		case EBonuses.CLICK_DAMAGE_SELF_MULTIPLIER:
		case EBonuses.CLICK_DAMAGE_USER_MULTIPLIER:
		case EBonuses.MINE_REWARD_MULTIPLIER:
		case EBonuses.REDUCE_ORE_HP:
		case EBonuses.CRITICAL_HIT_CHANCE:
		case EBonuses.CRITICAL_HIT_DAMAGE:
		case EBonuses.CRATE_SPAWN_RATE_MULTIPLIER:
		case EBonuses.CRAFTING_COST_REDUCTION:
		case EBonuses.AFK_MINE_REWARD_MULTIPLIER:
		case EBonuses.UPGRADE_COST_REDUCTION:
		case EBonuses.REFINERY_SPEED:
			return true;
		default:
			return false;
	}
};

export const toRoman = (num: number) => {
  const lookup: { [key: string]: number } = { M: 1000, CM: 900, D: 500, CD: 400, C: 100, XC: 90, L: 50, XL: 40, X: 10, IX: 9, V: 5, IV: 4, I: 1 };
  let roman = "";
  for (let i in lookup) {
    while (num >= lookup[i]) {
      roman += i;
      num -= lookup[i];
    }
  }
  return roman;
}

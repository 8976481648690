enum EBonuses {
    NONE = 'none',
    RARITY_BONUS = 'RARITY_BONUS',
    CLICK_DAMAGE_CALC = 'CLICK_DAMAGE_CALC',
    MINE_REWARD_MULTIPLIER = "MINE_REWARD_MULTIPLIER",
    CLICK_DAMAGE_ADDITION = "CLICK_DAMAGE_ADDITION",
    CLICK_DAMAGE_SELF_MULTIPLIER = "CLICK_DAMAGE_SELF_MULTIPLIER",
    AUTO_DAMAGE_ADDITION = "AUTO_DAMAGE_ADDITION",
    AUTO_DAMAGE_SELF_MULTIPLIER = "AUTO_DAMAGE_SELF_MULTIPLIER",
    AUTO_DAMAGE_USER_MULTIPLIER = "AUTO_DAMAGE_USER_MULTIPLIER",
    ALL_DAMAGE_USER_MULTIPLIER = "ALL_DAMAGE_USER_MULTIPLIER",
    CLICK_DAMAGE_USER_MULTIPLIER = "CLICK_DAMAGE_USER_MULTIPLIER",
    CLICK_BASE_DMG = "CLICK_BASE_DMG",
    AUTO_DAMAGE_CALC = "AUTO_DAMAGE_CALC",
    CRATE_SPAWN_RATE_MULTIPLIER = "CRATE_SPAWN_RATE_MULTIPLIER",
    AUTO_CLICKS_PER_SECOND = "AUTO_CLICKS_PER_SECOND",
    AFK_MINE_REWARD_MULTIPLIER = "AFK_MINE_REWARD_MULTIPLIER",
    CRITICAL_HIT_CHANCE = "CRITICAL_HIT_CHANCE",
    CRITICAL_HIT_DAMAGE = "CRITICAL_HIT_DAMAGE",
    REDUCE_ORE_HP = "REDUCE_ORE_HP",
    ASCENDING_TO_DEPTH = "ASCENDING_TO_DEPTH",
    REFINERY_SPEED = "REFINERY_SPEED",
    UPGRADE_COST_REDUCTION = "UPGRADE_COST_REDUCTION",
    CRAFTING_COST_REDUCTION = "CRAFTING_COST_REDUCTION"
}

export default EBonuses;
import { Badge, Button, Card, Col, Modal, Row } from "react-bootstrap";
import { EPowerPlantType } from "../../types/EPowerPlantType"
import { useTranslation } from "react-i18next";
import IPowerPlant from "../../types/IPowerPlant";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import PowerPlant from "../../engine/PowerPlant";
import { MouseEvent, useState } from "react";
import BigNumber from "bignumber.js";
import { formatNumber } from "../../engine/gameUtils";
import { EOreTypes } from "../../types/EOreTypes";
import { toast } from "react-toastify";
import Ore from "../../engine/Ore";

interface PowerPlantProps {
    type: EPowerPlantType;
}

const PowerPlantEntry = ({ type }: PowerPlantProps) => {
    const { t } = useTranslation();
    const { credits } = useSelector((state: RootState) => state.game);
    const [show, setShow] = useState(false);
    const powerPlantInterface: IPowerPlant = useSelector((state: RootState) => state.game.powerPlants[type]);
    const powerPlant = new PowerPlant(powerPlantInterface);

    const upgradeCost = powerPlant.calculateUpgradeCost();    

    function onToggleActive(): void {
        if(powerPlant.isActive) {
            powerPlant.deactivate();
        }
        else {
            if(!powerPlant.hasEnoughResources()) {
                toast.error(t("powerPlant.notEnoughResources", { value: formatNumber(powerPlant.calculateResourceCostPerCycle(), 0), name: t("ore." + (type === EPowerPlantType.COAL ? "coal" : "uranium") + ".name") }));
                return;
            }
            powerPlant.activate();
        }
    }

    function onUpgrade(): void {
        powerPlant.upgrade()
    }

    const renderUpgreadeModal = () => {
        const newPowerPlant = new PowerPlant({...powerPlantInterface, level: powerPlantInterface.level + 1})
        return (
            <Modal show={show} centered style={{backgroundImage: `url(${powerPlant.getUpgradeBackgroundImage()})`, backgroundSize: "cover"}}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('powerPlant.upgradeModal.title')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Row>
                            <Col md={6}>
                                
                            </Col>
                            <Col md={3} className="bold">
                                {t('powerPlant.upgradeModal.current')}
                            </Col>
                            <Col md={3} className="bold">
                                {t('powerPlant.upgradeModal.new')}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} className="bold">
                                {t('powerPlant.levelLabel')}
                            </Col>
                            <Col md={3}>
                                {powerPlant.level}
                            </Col>
                            <Col md={3}>
                                {newPowerPlant.level}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} className="bold">
                                {t('powerPlant.resourceCostPerCycleLabel')}
                            </Col>
                            <Col md={3}>
                                {formatNumber(powerPlant.calculateResourceCostPerCycle(), 0)}
                            </Col>
                            <Col md={3}>
                                {formatNumber(newPowerPlant.calculateResourceCostPerCycle(), 0)}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} className="bold">
                                {t('powerPlant.productionIncreasedLabel')}
                            </Col>
                            <Col md={3}>
                                {formatNumber(powerPlant.calculateProductionMultiplier(), 0, true)}
                            </Col>
                            <Col md={3}>
                                {formatNumber(newPowerPlant.calculateProductionMultiplier(), 0, true)}
                            </Col>
                        </Row>
                    </div>
                    <hr />
                    <p>
                        {t('powerPlant.upgradeModal.credits', { value: formatNumber(powerPlant.calculateUpgradeCost()) })}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {setShow(false)}}>
                        {t('general.cancel')}
                    </Button>
                    <Button variant="primary" onClick={onUpgrade} disabled={BigNumber(credits).lt(upgradeCost)}>
                        {t('powerPlant.upgrade')}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

    return (
        <Card className="power-plant-card" style={{backgroundImage: `url(${powerPlant.getBackgroundImage()})`}}>
            <Card.Header>
                <Card.Title className="align-center">
                    {t("powerPlant.types." + EPowerPlantType[type].toLowerCase() + ".title")}
                </Card.Title>
            </Card.Header>
            <Card.Body>
                <Card.Text>
                    {t("powerPlant.types." + EPowerPlantType[type].toLowerCase() + ".description")}
                </Card.Text>
                <Card.Text>
                    {t('powerPlant.resourceCostPerCycle', { cost: formatNumber(powerPlant.calculateResourceCostPerCycle(), 0) })}
                </Card.Text>
                <Card.Text>
                    {t('powerPlant.productionIncreased', { value: formatNumber(powerPlant.calculateProductionMultiplier(), 0, true) })}
                </Card.Text>
                <Button variant="primary" onClick={onToggleActive}>
                    {powerPlant.isActive ? t('powerPlant.deactivate') : t('powerPlant.activate')}
                </Button>
                <Button variant="warning" onClick={() => setShow(true)}>
                    {t('powerPlant.upgrade')}
                </Button>
                <Row>
                    {type === EPowerPlantType.COAL ? (
                        <>
                            <Col>
                                <Badge bg="info">{t('powerPlant.level', { level: powerPlant.level })}</Badge>
                            </Col>
                            <Col align="right">
                                <Badge bg="info">{t('powerPlant.resourceAvailable', { value: formatNumber((new Ore(EOreTypes.Coal)).getCount(), 1), name: t("ore.coal.name")})}</Badge>
                            </Col>
                        </>
                    ) : (
                        <>
                            <Col align="left">
                                <Badge bg="info">{t('powerPlant.resourceAvailable', { value: formatNumber((new Ore(EOreTypes.Uranium)).getCount(), 1), name: t("ore.uranium.name")})}</Badge>
                            </Col>
                            <Col>
                                <Badge bg="info">{t('powerPlant.level', { level: powerPlant.level })}</Badge>
                            </Col>
                            </>
                    )}
                </Row>
            </Card.Body>
            {renderUpgreadeModal()}
        </Card>
    )
}

export default PowerPlantEntry;
class GameEvent {
    public readonly id: number;
    public static nextId: number = 0;

    constructor(
      public condition: () => boolean,
      public action: () => void,
      public interval: number,
      public repeat: boolean
    ) {
        this.id = GameEvent.nextId++;
    }
  
    // Methode zur Überprüfung und Ausführung des Events
    checkAndTrigger(lastTriggerTime: number, currentTime: number): boolean {
      if (this.condition()) {
        if (currentTime - lastTriggerTime >= this.interval) {
          this.action();
          return true;
        }
      }
      return false;
    }
}

export default GameEvent;
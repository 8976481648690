import React from "react";
import { Popover, OverlayTrigger, ProgressBar } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { EOreTypes } from "../../types/EOreTypes";
import "./index.css"; // CSS für die Gestaltung
import { ImageSize } from "../../utils/assetsUtils";
import BigNumber from "bignumber.js";
import { formatNumber } from "../../engine/gameUtils";
import { oresData } from "../../data/oresData";
import Ore from "../../engine/Ore";

interface OreImageProps {
	ore: EOreTypes;
	count?: BigNumber;
	popup?: boolean;
	size?: ImageSize;
	onClick?: () => void;
	currentHp?: BigNumber;
}

const OreImage: React.FC<OreImageProps> = ({
	ore,
	count = BigNumber(1),
	popup = true,
	size = ImageSize.Original,
	onClick = () => {},
	currentHp = null,
}) => {
	const { t } = useTranslation();
	const oreInstance = new Ore(ore)

	const oreSrc = oreInstance.getImage(size);
	const oreName = t(`ore.${oresData[ore].identifier}.name`);
	const oreDescription = t(`ore.${oresData[ore].identifier}.description`);

	let oreMaxHp = oreInstance.getMaxHealth();

	const renderPopover = (
		<Popover id={`popover-${ore}`}>
			<Popover.Header as="h3">{oreName}</Popover.Header>
			<Popover.Body>
				{currentHp && currentHp.isGreaterThan(0) && (
					<ProgressBar
						now={currentHp.dividedBy(oreMaxHp).times(100).toNumber()}
						label={`${formatNumber(currentHp, 1)} / ${formatNumber(oreMaxHp, 1)}`}
					/>
				)}
				<hr></hr>
				<div className="italic">{oreDescription}</div>
			</Popover.Body>
		</Popover>
	);

	return (
		<div className="ore-image-container" onClick={onClick}>
			{popup ? (
				<OverlayTrigger trigger={["hover", "focus"]} placement="auto" overlay={renderPopover}>
					<div className="ore-container">
						<img src={oreSrc} alt={oreName} draggable={false} />
						{count.gt(1) && <div className="ore-count">{formatNumber(count, 0)}</div>}
					</div>
				</OverlayTrigger>
			) : (
				<img src={oreSrc} alt={oreName} draggable={false} />
			)}
		</div>
	);
};

export default OreImage;

import { IconDefinition, faCross, faFlask, faHand, faHelmetSafety, faLayerGroup, faMitten, faVest } from "@fortawesome/free-solid-svg-icons";
import BigNumber from "bignumber.js";
import config from "../../config";
import { cuBoots } from "../../data/iconDefinitions";
import { itemData } from "../../data/itemData";
import { store } from "../../redux/store";
import EBonuses from "../../types/EBonuses";
import { EItemType } from "../../types/EItemType";
import IItem from "../../types/IItem";
import { ItemEntry } from "../../types/ItemEntry";

export const getItemById = (id: number): IItem => {
    return itemData[id];
}

export const getItemCountById = (id: number): BigNumber => {
    const { items } = store.getState().game;

    var amount = BigNumber(0);

    Object.values(items).forEach(item => {
        if(item.itemID === id)
            amount = amount.plus(item.count);
    });

    return amount;
}

export const getDefaultIconForType = (type: EItemType) : IconDefinition => {
    switch (type) {
        case EItemType.ARMOR:
            return faVest;
        case EItemType.BOOTS:
            return cuBoots;
        case EItemType.GLOVES:
            return faMitten;
        case EItemType.HELMET:
            return faHelmetSafety;
        case EItemType.PICKAXE:
            return faHand;
        case EItemType.RELIC:
            return faCross;
        case EItemType.POTION:
            return faFlask;
        case EItemType.PRODUCT:
            return faLayerGroup;
    }
};

export const calculateItemBonusValue = (baseValue: BigNumber, level: BigNumber) : BigNumber => {
    return config.item.bonusValueExponentialFactor.pow(level).times(baseValue);
}

export const calculateTotalBonusValueOfItem = (type: EBonuses, item: ItemEntry) : BigNumber => {
    const itemData = getItemById(item.itemID);
    
    var bonusValue = BigNumber(0);
    

    if(item.bonusList !== undefined)
    {
        item.bonusList
            .filter(bonus => bonus.type === type)
            .forEach(bonus => {
                bonusValue = bonusValue.plus(calculateItemBonusValue(bonus.value, BigNumber(item.level)));
            });
    }

    if(itemData.bonusList !== undefined)
    {
        itemData.bonusList
            .filter(bonus => bonus.type === type)
            .forEach(bonus => {
                bonusValue = bonusValue.plus(calculateItemBonusValue(bonus.value, BigNumber(item.level)));
            });
    }

    return bonusValue;
}

export const getTotalBonusValueOfEquipment = (type: EBonuses) : BigNumber => {
    const { equipment } = store.getState().game;

    var bonusValue = BigNumber(0);

    equipment[EItemType.ARMOR] !== null && (bonusValue = bonusValue.plus(calculateTotalBonusValueOfItem(type, equipment[EItemType.ARMOR])));
    equipment[EItemType.BOOTS] !== null && (bonusValue = bonusValue.plus(calculateTotalBonusValueOfItem(type, equipment[EItemType.BOOTS])));
    equipment[EItemType.GLOVES] !== null && (bonusValue = bonusValue.plus(calculateTotalBonusValueOfItem(type, equipment[EItemType.GLOVES])));
    equipment[EItemType.HELMET] !== null && (bonusValue = bonusValue.plus(calculateTotalBonusValueOfItem(type, equipment[EItemType.HELMET])));
    equipment[EItemType.PICKAXE] !== null && (bonusValue = bonusValue.plus(calculateTotalBonusValueOfItem(type, equipment[EItemType.PICKAXE])));
    equipment[EItemType.RELIC][0] !== null && (bonusValue = bonusValue.plus(calculateTotalBonusValueOfItem(type, equipment[EItemType.RELIC][0])));
    equipment[EItemType.RELIC][1] !== null && (bonusValue = bonusValue.plus(calculateTotalBonusValueOfItem(type, equipment[EItemType.RELIC][1])));

    return bonusValue;
}
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import RefineryComponent from './RefineryComponent';
import Refinery from '../../engine/Refinery';

const RefineryOverviewComponent: React.FC = () => {
  const refineries = useSelector((state: RootState) => state.game.refineries);

  return (
    <div className="refinery-overview">
      {Object.values(refineries).map((refinery) => (
        <RefineryComponent key={refinery.id} refinery={new Refinery(refinery)} />
      ))}
    </div>
  );
};

export default RefineryOverviewComponent;

import "./index.css"

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getFontAwesomeIconForType, formatNumber, isBonusTypePercentage } from '../../engine/gameUtils';
import EBonuses from '../../types/EBonuses';
import BigNumber from 'bignumber.js';
import { useTranslation } from 'react-i18next';
import { OverlayTrigger, Popover } from "react-bootstrap";
import Research from "../../engine/Research";

interface BonusInfoProps {
  type: EBonuses;
  baseValue: BigNumber;
  level: BigNumber;
  upgradeAmount: BigNumber;
}

const BonusInfo: React.FC<BonusInfoProps> = ({ type, baseValue, level, upgradeAmount }) => {
  const { t } = useTranslation();

  const currentEffect = Research.getBonusValue(baseValue, level);
  const nextLevelEffect = Research.getBonusValue(baseValue, level.plus(upgradeAmount));

  const displayValue = (value: BigNumber) => isBonusTypePercentage(type) ? `${value.multipliedBy(100)}%` : formatNumber(value, 2);

  const popover = (
    <Popover id={`popover-${type}`}>
      <Popover.Header as="h3">{t(`bonuses.${type}.name`)}</Popover.Header>
      <Popover.Body>
        {t(`bonuses.${type}.description`, { amount: displayValue(baseValue) })}
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger trigger={["hover", "focus"]} placement="auto" overlay={popover}>
      <div className="bonus-info">
        <FontAwesomeIcon icon={getFontAwesomeIconForType(type)} className="bonus-icon" />
        <span className="bonus-info-text">
          {displayValue(currentEffect)} → {displayValue(nextLevelEffect)}
        </span>
      </div>
    </OverlayTrigger>
  );
};

export default BonusInfo;

// PlanetTravelTab.js
import { faArrowLeft, faArrowRight, faRocket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Card, Col, Modal, OverlayTrigger, Popover, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { ImageSize } from "../../../utils/assetsUtils";
import OreImage from "../../OreImage";
import "./index.css";
import Planet from "../../../engine/Planet";

const PlanetTravelTab = () => {
	const { t } = useTranslation();
	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const currentPlanet = useSelector((state: RootState) => state.game.planet);
	const [selectedPlanetIndex, setSelectedPlanetIndex] = useState(currentPlanet.id - 1);
	const planetsUnlocked = useSelector((state: RootState) => state.game.completedPlanets);

	useEffect(() => {}, [currentPlanet]);

	const handleTravel = () => {
		setShowConfirmModal(true);
	};

	const handleCloseModal = () => setShowConfirmModal(false);

	const handleConfirmTravel = () => {
		new Planet(selectedPlanetIndex + 1).travel();
		setShowConfirmModal(false);
	};

	const renderRequirementsPopover = () => {
		const planet = new Planet(selectedPlanetIndex + 1);

		return (
			<Popover id="popover-requirements">
				<Popover.Header as="h3">{t("travel.requirements.title")}</Popover.Header>
				<Popover.Body>
					{planet.id === currentPlanet.id ? (
						<div>{t("travel.requirements.currentPlanet")}</div>
					) : (
						<div>{t("travel.requirements.description")}</div>
					)}
					{planet.getInterface().requiredPlanets.map((requirement, index) => (
						<div
							className={planetsUnlocked.includes(requirement) ? "requirements-complete" : "requirements-incomplete"}
							key={index}
						>
							{t(`planet.${new Planet(requirement).getInterface().identifier}.name`)}
						</div>
					))}
				</Popover.Body>
			</Popover>
		);
	};

	return (
		<div className="planet-travel-tab tab">
			<Card style={{ backgroundColor: "transparent", width: "100%" }}>
				<Card.Header>
					<Modal.Title>{t("tabs.planetTravel")}</Modal.Title>
				</Card.Header>
				<Card.Body
					style={{
						backgroundImage: `url(${new Planet(selectedPlanetIndex + 1).getImage()})`,
						backgroundRepeat: "no-repeat",
						backgroundSize: "cover",
					}}
				>
					<Row className="planet-navigation">
						<Col md={4}>
							<Button
								variant="secondary"
								onClick={() =>
									setSelectedPlanetIndex((prevIndex) => (prevIndex - 1 >= 0 ? prevIndex - 1 : Planet.count() - 1))
								}
							>
								<FontAwesomeIcon icon={faArrowLeft} />
							</Button>
						</Col>
						<Col className="text-center" md={4}>
							<div className="text-background">
								<h3>{t(`planet.${new Planet(selectedPlanetIndex + 1).getInterface().identifier}.name`)}</h3>
							</div>
						</Col>
						<Col md={4}>
							<Button
								className="float-right"
								variant="secondary"
								onClick={() => setSelectedPlanetIndex((prevIndex) => (prevIndex + 1) % Planet.count())}
							>
								<FontAwesomeIcon icon={faArrowRight} />
							</Button>
						</Col>
					</Row>
					<Row className="planet-ores text-center">
						{new Planet(selectedPlanetIndex + 1).getInterface().ores.map((ore) => (
							<Col key={ore} md={3}>
								<OreImage ore={ore} size={ImageSize.Medium} />
							</Col>
						))}
					</Row>
					<div className="text-center">
						{new Planet(selectedPlanetIndex + 1).canTravel() ? (
							<Button variant="primary" onClick={handleTravel}>
								<FontAwesomeIcon icon={faRocket} /> {t("travel.start")}
							</Button>
						) : currentPlanet.id === new Planet(selectedPlanetIndex + 1).getInterface().id ? (
							<OverlayTrigger trigger={["hover", "focus"]} placement="auto" overlay={renderRequirementsPopover}>
								<span className="d-inline-block">
									<Button variant="secondary" disabled style={{ pointerEvents: "none" }}>
										<FontAwesomeIcon icon={faRocket} /> {t("travel.requirements.currentPlanet")}
									</Button>
								</span>
							</OverlayTrigger>
						) : (
							<OverlayTrigger trigger={["hover", "focus"]} placement="auto" overlay={renderRequirementsPopover}>
								<span className="d-inline-block">
									<Button variant="secondary" disabled style={{ pointerEvents: "none" }}>
										<FontAwesomeIcon icon={faRocket} /> {t("travel.cannotStart")}
									</Button>
								</span>
							</OverlayTrigger>
						)}
					</div>
				</Card.Body>
			</Card>

			<Modal className={"travel-modal-content"} show={showConfirmModal} onHide={handleCloseModal}>
				<Modal.Header closeButton>
					<Modal.Title>
						{t("travel.header", { planetName: t("planet." + new Planet(selectedPlanetIndex + 1).getInterface().identifier + ".name") })}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{t("travel.descriptionFormat2")}
					<ul>
						<li>{t("travel.effectFormat")}</li>
						<li>{t("travel.effectFormat2")}</li>
						<li>{t("travel.effectFormat3")}</li>
						<li>
							{t("travel.effectFormat4", {
								planetName: t("planet." + new Planet(selectedPlanetIndex + 1).getInterface().identifier + ".name"),
							})}
						</li>
					</ul>
					{t("travel.descriptionFormat", {
						planetName: t("planet." + new Planet(selectedPlanetIndex + 1).getInterface().identifier + ".name"),
					})}
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseModal}>
						{t("travel.cancel")}
					</Button>
					<Button variant="primary" onClick={handleConfirmTravel}>
						{t("travel.confirm")}
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default PlanetTravelTab;

import React from "react";
import { Modal, ListGroup, Row, Col } from "react-bootstrap";
import IBlueprint from "../../types/IBlueprint";
import ItemIconComponent from "../ItemIconComponent";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { InventorySlot } from "../InventorySlot/InventorySlot";
import OreImage from "../OreImage";
import { EOreTypes } from "../../types/EOreTypes";
import { ImageSize } from "../../utils/assetsUtils";
import BigNumber from "bignumber.js";

interface BlueprintSelectionModalProps {
	show: boolean;
	onHide: () => void;
	onSelectBlueprint: (blueprintId: number) => void;
	blueprints: IBlueprint[];
}

const BlueprintSelectionModal: React.FC<BlueprintSelectionModalProps> = ({
	show,
	onHide,
	onSelectBlueprint,
	blueprints,
}) => {
	const { t } = useTranslation();

	return (
		<Modal show={show} onHide={onHide}>
			<Modal.Header closeButton>
				<Modal.Title>{t("blueprints.title")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<ListGroup>
					{blueprints.map((blueprint) => (
						<ListGroup.Item key={blueprint.id} action onClick={() => onSelectBlueprint(blueprint.id)}>
							<Row>
								<Col md={3}>
									<ItemIconComponent itemId={blueprint.output[0].itemId} />
									<h5>{t(`items.${blueprint.output[0].itemId}.name`)}</h5>
								</Col>
								<Col md={3}>
									{t(`blueprints.input.label`)}:
									{blueprint.input.map((input) =>
										input.itemId !== undefined ? (
											<div key={input.itemId}>
												<ItemIconComponent itemId={input.itemId} />
												{input.quantity}
											</div>
										) : (
											<div key={input.oreType}>
												<OreImage
													ore={input.oreType as EOreTypes}
													size={ImageSize.Medium}
													count={BigNumber(input.quantity)}
												/>
											</div>
										)
									)}
								</Col>
								<Col md={3}>
									<FontAwesomeIcon icon={faClock}></FontAwesomeIcon> {blueprint.productionTime}s
								</Col>
								<Col md={3}>
									{t(`blueprints.output.label`)}:
									{blueprint.output.map((output) => (
										<div key={output.itemId}>
											<InventorySlot
												item={{ itemID: output.itemId, count: output.quantity.toString(), level: "0", id: 0 }}
											/>
											{output.quantity}
										</div>
									))}
								</Col>
							</Row>
							{/* Weitere Blueprint-Details hier anzeigen, z.B. mit einem Popover */}
						</ListGroup.Item>
					))}
				</ListGroup>
			</Modal.Body>
		</Modal>
	);
};

export default BlueprintSelectionModal;

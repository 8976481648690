import BigNumber from "bignumber.js";
import IMinerType from "../types/IMinerType";
import EBonus from "../types/EBonuses";

export const minerData: IMinerType[] = [
    {
        "id": 1,
        "identifier": "pickaxe",
        "basePrice": BigNumber(5),
        "factors": {
            "price": BigNumber(1.15),
            "bonusValue": BigNumber(1.1)
        },
        "bonusType": EBonus.CLICK_DAMAGE_ADDITION,
        "bonusValue": BigNumber(1),
        "upgrades": [
            {
                "id": 1,
                "identifier": "pickaxe_1",
                "minLevel": BigNumber(5),
                "price": BigNumber(100),
                "bonusType": EBonus.CLICK_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(1)
            },
            {
                "id": 2,
                "identifier": "pickaxe_2",
                "minLevel": BigNumber(10),
                "price": BigNumber(250),
                "bonusType": EBonus.CLICK_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(1)
            },
            {
                "id": 3,
                "identifier": "pickaxe_3",
                "minLevel": BigNumber(25),
                "price": BigNumber(1000),
                "bonusType": EBonus.CLICK_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(1)
            },
            {
                "id": 4,
                "identifier": "pickaxe_4",
                "minLevel": BigNumber(50),
                "price": BigNumber(10000),
                "bonusType": EBonus.CLICK_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(1)
            },
            {
                "id": 5,
                "identifier": "pickaxe_5",
                "minLevel": BigNumber(100),
                "price": BigNumber(1000 * 1000 * 10),
                "bonusType": EBonus.CLICK_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(1)
            },
            {
                "id": 6,
                "identifier": "pickaxe_6",
                "minLevel": BigNumber(1000),
                "price": BigNumber("2.5e62"),
                "bonusType": EBonus.CLICK_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(1)
            }
        ]
    },
    {
        "id": 2,
        "identifier": "simple-drill",
        "basePrice": BigNumber(50),
        "bonusType": EBonus.AUTO_DAMAGE_ADDITION,
        "bonusValue": BigNumber(0.1),
        "factors": {
            "price": BigNumber(1.15),
            "bonusValue": BigNumber(1.1)
        },
        "upgrades": [
            {
                "id": 1,
                "identifier": "simple-drill_1",
                "minLevel": BigNumber(5),
                "price": BigNumber(250),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(0.5)
            },
            {
                "id": 2,
                "identifier": "simple-drill_2",
                "minLevel": BigNumber(10),
                "price": BigNumber(1000),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(0.75)
            },
            {
                "id": 3,
                "identifier": "simple-drill_3",
                "minLevel": BigNumber(25),
                "price": BigNumber(5000),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(1)
            },
            {
                "id": 4,
                "identifier": "simple-drill_4",
                "minLevel": BigNumber(50),
                "price": BigNumber(200000),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(1)
            },
            {
                "id": 5,
                "identifier": "simple-drill_5",
                "minLevel": BigNumber(100),
                "price": BigNumber("5e8"),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(1)
            },
            {
                "id": 6,
                "identifier": "simple-drill_6",
                "minLevel": BigNumber(1000),
                "price": BigNumber("2.5e63"),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(5)
            }
        ]
    },
    {
        "id": 3,
        "identifier": "basic-drill",
        "basePrice": BigNumber(500),
        "bonusType": EBonus.AUTO_DAMAGE_ADDITION,
        "bonusValue": BigNumber(1),
        "factors": {
            "price": BigNumber(1.149),
            "bonusValue": BigNumber(1.101)
        },
        "upgrades": [
            {
                "id": 1,
                "identifier": "basic-drill_1",
                "minLevel": BigNumber(5),
                "price": BigNumber(2500),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(0.5)
            },
            {
                "id": 2,
                "identifier": "basic-drill_2",
                "minLevel": BigNumber(10),
                "price": BigNumber(8000),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(0.8)
            },
            {
                "id": 3,
                "identifier": "basic-drill_3",
                "minLevel": BigNumber(25),
                "price": BigNumber(80000),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(1)
            },
            {
                "id": 4,
                "identifier": "basic-drill_4",
                "minLevel": BigNumber(50),
                "price": BigNumber(2000000),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(1)
            },
            {
                "id": 5,
                "identifier": "basic-drill_5",
                "minLevel": BigNumber(100),
                "price": BigNumber("5e9"),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(1)
            },
            {
                "id": 6,
                "identifier": "basic-drill_6",
                "minLevel": BigNumber(1000),
                "price": BigNumber("1e64"),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(5)
            }
        ]
    },
    {
        "id": 4,
        "identifier": "advanced-station",
        "basePrice": BigNumber(5000),
        "bonusType": EBonus.AUTO_DAMAGE_ADDITION,
        "bonusValue": BigNumber(5),
        "factors": {
            "price": BigNumber(1.15),
            "bonusValue": BigNumber(1.105)
        },
        "upgrades": [
            {
                "id": 1,
                "identifier": "advanced-station_1",
                "minLevel": BigNumber(10),
                "price": BigNumber(250000),
                "bonusType": EBonus.MINE_REWARD_MULTIPLIER,
                "bonusValue": BigNumber(0.5)
            },
            {
                "id": 2,
                "identifier": "advanced-station_2",
                "minLevel": BigNumber(25),
                "price": BigNumber(2000000),
                "bonusType": EBonus.RARITY_BONUS,
                "bonusValue": BigNumber(1)
            },
            {
                "id": 3,
                "identifier": "advanced-station_3",
                "minLevel": BigNumber(50),
                "price": BigNumber("5000000000"),
                "bonusType": EBonus.REDUCE_ORE_HP,
                "bonusValue": BigNumber(0.1)
            },
        ]
    },
    {
        "id": 5,
        "identifier": "elite-drill",
        "basePrice": BigNumber(50000),
        "bonusType": EBonus.AUTO_DAMAGE_ADDITION,
        "bonusValue": BigNumber(50),
        "factors": {
            "price": BigNumber(1.13),
            "bonusValue": BigNumber(1.11)
        },
        "upgrades": [
            {
                "id": 1,
                "identifier": "elite-drill_1",
                "minLevel": BigNumber(10),
                "price": BigNumber(2500000),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(0.5)
            },
            {
                "id": 2,
                "identifier": "elite-drill_2",
                "minLevel": BigNumber(25),
                "price": BigNumber(20000000),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(0.75)
            },
            {
                "id": 3,
                "identifier": "elite-drill_3",
                "minLevel": BigNumber(50),
                "price": BigNumber(50000000000),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(1)
            },
            {
                "id": 4,
                "identifier": "elite-drill_4",
                "minLevel": BigNumber(100),
                "price": BigNumber("5e11"),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(1)
            },
            {
                "id": 5,
                "identifier": "elite-drill_5",
                "minLevel": BigNumber(1000),
                "price": BigNumber("1e59"),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(2)
            }
        ]
    },
    {
        "id": 6,
        "identifier": "master-drill",
        "basePrice": BigNumber(1000000),
        "bonusType": EBonus.AUTO_DAMAGE_ADDITION,
        "bonusValue": BigNumber(1000),
        "factors": {
            "price": BigNumber(1.13),
            "bonusValue": BigNumber(1.12)
        },
        "upgrades": [
            {
                "id": 1,
                "identifier": "master-drill_1",
                "minLevel": BigNumber(10),
                "price": BigNumber(250000000),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(0.5)
            },
            {
                "id": 2,
                "identifier": "master-drill_2",
                "minLevel": BigNumber(25),
                "price": BigNumber(2000000000),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(0.75)
            },
            {
                "id": 3,
                "identifier": "master-drill_3",
                "minLevel": BigNumber(50),
                "price": BigNumber(5000000000000),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(1)
            },
            {
                "id": 4,
                "identifier": "master-drill_4",
                "minLevel": BigNumber(100),
                "price": BigNumber("5e13"),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(1)
            },
            {
                "id": 5,
                "identifier": "master-drill_5",
                "minLevel": BigNumber(1000),
                "price": BigNumber("1e61"),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(2)
            }
        ]
    },
    {
        "id": 7,
        "identifier": "ultimate-drill",
        "basePrice": BigNumber(10000000),
        "bonusType": EBonus.AUTO_DAMAGE_ADDITION,
        "bonusValue": BigNumber(10000),
        "factors": {
            "price": BigNumber(1.13),
            "bonusValue": BigNumber(1.13)
        },
        "upgrades": [
            {
                "id": 1,
                "identifier": "ultimate-drill_1",
                "minLevel": BigNumber(10),
                "price": BigNumber(2500000000),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(0.5)
            },
            {
                "id": 2,
                "identifier": "ultimate-drill_2",
                "minLevel": BigNumber(25),
                "price": BigNumber(20000000000),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(0.75)
            },
            {
                "id": 3,
                "identifier": "ultimate-drill_3",
                "minLevel": BigNumber(50),
                "price": BigNumber(50000000000000),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(1)
            },
            {
                "id": 4,
                "identifier": "ultimate-drill_4",
                "minLevel": BigNumber(100),
                "price": BigNumber("5e15"),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(1)
            },
            {
                "id": 5,
                "identifier": "ultimate-drill_5",
                "minLevel": BigNumber(1000),
                "price": BigNumber("1e63"),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(2)
            }
        ]
    },
    {
        "id": 8,
        "identifier": "small-mining-ship",
        "basePrice": BigNumber(100000000),
        "bonusType": EBonus.AUTO_DAMAGE_ADDITION,
        "bonusValue": BigNumber(100000),
        "factors": {
            "price": BigNumber(1.125),
            "bonusValue": BigNumber(1.14)
        },
        upgrades: [
            {
                "id": 1,
                "identifier": "small-mining-ship_1",
                "minLevel": BigNumber(10),
                "price": BigNumber(25000000000),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(0.5)
            },
            {
                "id": 2,
                "identifier": "small-mining-ship_2",
                "minLevel": BigNumber(25),
                "price": BigNumber(200000000000),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(0.75)
            },
            {
                "id": 3,
                "identifier": "small-mining-ship_3",
                "minLevel": BigNumber(50),
                "price": BigNumber(500000000000000),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(1)
            },
            {
                "id": 4,
                "identifier": "small-mining-ship_4",
                "minLevel": BigNumber(100),
                "price": BigNumber("5e14"),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(1)
            },
            {
                "id": 5,
                "identifier": "small-mining-ship_5",
                "minLevel": BigNumber(1000),
                "price": BigNumber("1e60"),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(2)
            }
        ]
    },
    {
        "id": 9,
        "identifier": "medium-mining-ship",
        "basePrice": BigNumber(1000000000),
        "bonusType": EBonus.AUTO_DAMAGE_ADDITION,
        "bonusValue": BigNumber(1000000),
        "factors": {
            "price": BigNumber(1.125),
            "bonusValue": BigNumber(1.15)
        },
        "upgrades": [
            {
                "id": 1,
                "identifier": "medium-mining-ship_1",
                "minLevel": BigNumber(10),
                "price": BigNumber(250000000000),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(0.5)
            },
            {
                "id": 2,
                "identifier": "medium-mining-ship_2",
                "minLevel": BigNumber(25),
                "price": BigNumber(500000000000),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(0.75)
            },
            {
                "id": 3,
                "identifier": "medium-mining-ship_3",
                "minLevel": BigNumber(50),
                "price": BigNumber(5000000000000000),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(1)
            },
            {
                "id": 4,
                "identifier": "medium-mining-ship_4",
                "minLevel": BigNumber(100),
                "price": BigNumber("5e15"),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(1)
            },
            {
                "id": 5,
                "identifier": "medium-mining-ship_5",
                "minLevel": BigNumber(1000),
                "price": BigNumber("1e61"),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(2)
            }
        ]
    },
    {
        "id": 10,
        "identifier": "interstellar-space-station",
        "basePrice": BigNumber("1e11"),
        "bonusType": EBonus.MINE_REWARD_MULTIPLIER,
        "bonusValue": BigNumber(0.01),

        "factors": {
            "price": BigNumber(1.6),
            "bonusValue": BigNumber(1.1)
        },

        "upgrades": [
            {
                "id": 1,
                "identifier": "interstellar-space-station_1",
                "minLevel": BigNumber(10),
                "price": BigNumber("2.5e12"),
                "bonusType": EBonus.MINE_REWARD_MULTIPLIER,
                "bonusValue": BigNumber(0.5)
            },
            {
                "id": 2,
                "identifier": "interstellar-space-station_2",
                "minLevel": BigNumber(25),
                "price": BigNumber("2e13"),
                "bonusType": EBonus.REDUCE_ORE_HP,
                "bonusValue": BigNumber(0.15)
            },
            {
                "id": 3,
                "identifier": "interstellar-space-station_3",
                "minLevel": BigNumber(50),
                "price": BigNumber("1e15"),
                "bonusType": EBonus.REFINERY_SPEED,
                "bonusValue": BigNumber(1)
            },
            {
                "id": 4,
                "identifier": "interstellar-space-station_4",
                "minLevel": BigNumber(100),
                "price": BigNumber("5e17"),
                "bonusType": EBonus.CRITICAL_HIT_CHANCE,
                "bonusValue": BigNumber(0.25)
            },
            {
                "id": 5,
                "identifier": "interstellar-space-station_5",
                "minLevel": BigNumber(1000),
                "price": BigNumber("1e63"),
                "bonusType": EBonus.AUTO_CLICKS_PER_SECOND,
                "bonusValue": BigNumber(5)
            }
        ]
    },
    {
        "id": 11,
        "identifier": "large-mining-ship",
        "basePrice": BigNumber("2.5e15"),

        "bonusType": EBonus.AUTO_DAMAGE_ADDITION,
        "bonusValue": BigNumber("5e13"),

        "factors": {
            "price": BigNumber(1.129),
            "bonusValue": BigNumber(1.1)
        },

        "upgrades": [
            {
                "id": 1,
                "identifier": "large-mining-ship_1",
                "minLevel": BigNumber(10),
                "price": BigNumber("2.5e16"),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(0.5)
            },
            {
                "id": 2,
                "identifier": "large-mining-ship_2",
                "minLevel": BigNumber(25),
                "price": BigNumber("2e17"),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(0.75)
            },
            {
                "id": 3,
                "identifier": "large-mining-ship_3",
                "minLevel": BigNumber(50),
                "price": BigNumber("1e19"),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(1)
            },
            {
                "id": 4,
                "identifier": "large-mining-ship_4",
                "minLevel": BigNumber(100),
                "price": BigNumber("5e21"),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(1)
            },
            {
                "id": 5,
                "identifier": "large-mining-ship_5",
                "minLevel": BigNumber(1000),
                "price": BigNumber("1e70"),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(2)
            }
        ]
    },
    {
        "id": 12,
        "identifier": "galactic-mining-ship",
        "basePrice": BigNumber("2.5e17"),

        "bonusType": EBonus.AUTO_DAMAGE_ADDITION,
        "bonusValue": BigNumber("5e15"),

        "factors": {
            "price": BigNumber(1.128),
            "bonusValue": BigNumber(1.11)
        },

        "upgrades": [
            {
                "id": 1,
                "identifier": "galactic-mining-ship_1",
                "minLevel": BigNumber(10),
                "price": BigNumber("8e18"),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(0.5)
            },
            {
                "id": 2,
                "identifier": "galactic-mining-ship_2",
                "minLevel": BigNumber(25),
                "price": BigNumber("2e20"),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(0.75)
            },
            {
                "id": 3,
                "identifier": "galactic-mining-ship_3",
                "minLevel": BigNumber(50),
                "price": BigNumber("1e22"),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(1)
            },
            {
                "id": 4,
                "identifier": "galactic-mining-ship_4",
                "minLevel": BigNumber(100),
                "price": BigNumber("5e24"),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(1)
            },
            {
                "id": 5,
                "identifier": "galactic-mining-ship_5",
                "minLevel": BigNumber(1000),
                "price": BigNumber("1e72"),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(2)
            }
        ]
    },
    {
        "id": 13,
        "identifier": "titan-mining-ship",
        "basePrice": BigNumber("5e22"),

        "bonusType": EBonus.AUTO_DAMAGE_ADDITION,
        "bonusValue": BigNumber("1e21"),

        "factors": {
            "price": BigNumber(1.127),
            "bonusValue": BigNumber(1.11)
        },

        "upgrades": [
            {
                "id": 1,
                "identifier": "titan-mining-ship_1",
                "minLevel": BigNumber(10),
                "price": BigNumber("8e23"),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(0.5)
            },
            {
                "id": 2,
                "identifier": "titan-mining-ship_2",
                "minLevel": BigNumber(25),
                "price": BigNumber("2e25"),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(0.75)
            },
            {
                "id": 3,
                "identifier": "titan-mining-ship_3",
                "minLevel": BigNumber(50),
                "price": BigNumber("1e27"),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(1)
            },
            {
                "id": 4,
                "identifier": "titan-mining-ship_4",
                "minLevel": BigNumber(100),
                "price": BigNumber("5e29"),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(1)
            },
            {
                "id": 5,
                "identifier": "titan-mining-ship_5",
                "minLevel": BigNumber(1000),
                "price": BigNumber("1e76"),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(2)
            }
        ]
    },
    {
        "id": 14,
        "identifier": "small-mining-station",
        "basePrice": BigNumber("1e28"),

        "bonusType": EBonus.AUTO_DAMAGE_ADDITION, 
        "bonusValue": BigNumber("5e26"),
        
        "factors": {
            "price": BigNumber(1.126),
            "bonusValue": BigNumber(1.13)
        },

        "upgrades": [
            {
                "id": 1,
                "identifier": "small-mining-station_1",
                "minLevel": BigNumber(10),
                "price": BigNumber("8e29"),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(0.5)
            },
            {
                "id": 2,
                "identifier": "small-mining-station_2",
                "minLevel": BigNumber(25),
                "price": BigNumber("2e31"),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(0.75)
            },
            {
                "id": 3,
                "identifier": "small-mining-station_3",
                "minLevel": BigNumber(50),
                "price": BigNumber("1e33"),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(1)
            },
            {
                "id": 4,
                "identifier": "small-mining-station_4",
                "minLevel": BigNumber(100),
                "price": BigNumber("5e35"),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(1.5)
            },
            {
                "id": 5,
                "identifier": "small-mining-station_5",
                "minLevel": BigNumber(1000),
                "price": BigNumber("1e82"),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(2.5)
            }
        ]
    },
    {
        "id": 15,
        "identifier": "medium-mining-station",
        "basePrice": BigNumber("1e34"),

        "bonusType": EBonus.AUTO_DAMAGE_ADDITION,
        "bonusValue": BigNumber("5e32"),

        "factors": {
            "price": BigNumber(1.123),
            "bonusValue": BigNumber(1.135)
        },

        "upgrades": [
            {
                "id": 1,
                "identifier": "medium-mining-station_1",
                "minLevel": BigNumber(10),
                "price": BigNumber("8e35"),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(0.5)
            },
            {
                "id": 2,
                "identifier": "medium-mining-station_2",
                "minLevel": BigNumber(25),
                "price": BigNumber("2e37"),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(0.75)
            },
            {
                "id": 3,
                "identifier": "medium-mining-station_3",
                "minLevel": BigNumber(50),
                "price": BigNumber("1e39"),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(1)
            },
            {
                "id": 4,
                "identifier": "medium-mining-station_4",
                "minLevel": BigNumber(100),
                "price": BigNumber("5e41"),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(1.5)
            },
            {
                "id": 5,
                "identifier": "medium-mining-station_5",
                "minLevel": BigNumber(1000),
                "price": BigNumber("1e86"),
                "bonusType": EBonus.AUTO_DAMAGE_SELF_MULTIPLIER,
                "bonusValue": BigNumber(2.5)
            }
        ]
    }
];
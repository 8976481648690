import BigNumber from "bignumber.js";
import { toast } from "react-toastify";
import i18n from "../../i18n/i18n";
import { addItems, addResources } from "../../redux/slices/gameSlice";
import { store } from "../../redux/store";
import { ICrate } from "../../types/ICrate";
import EBonuses from "../../types/EBonuses";
import { ECrateContentType } from "../../types/ECrateContentType";
import { EOreTypes } from "../../types/EOreTypes";
import { formatNumber } from "../gameUtils";
import { applyBoost } from "./BoostActions";
import Ore from "../Ore";

export const handleActionOpenCrate = (crate: ICrate) => {
    openCrate(crate);
}

export const openCrate = (crate: ICrate) => {
    const content = getContents(crate);
    switch (content.type) {
        case ECrateContentType.Resource:
            if(!content.id || !content.amount) throw new Error("Invalid resource content in crate");

            const referenceOre = new Ore(content.id as EOreTypes);

            const resourceGain = referenceOre.getResouceGainOverTime(content.amount)
            const oreType: EOreTypes = content.id as EOreTypes;
            store.dispatch(addResources({oreType: oreType, amount: resourceGain.toString() }));
            toast.info(i18n.t("toasts.resource.receive", { value: formatNumber(resourceGain), name: i18n.t(`ore.${EOreTypes[oreType].toLowerCase()}.name`) }));
            break;
        case ECrateContentType.Boost:
            applyBoost(content.id as EBonuses, content.amount as BigNumber, content.duration as BigNumber);
            break;
        case ECrateContentType.Item:
            toast.info(i18n.t("toasts.item.receive", { value: content.amount, name: i18n.t(`items.${content.id}.name`) }));
            store.dispatch(addItems({itemId: content.id as number, amount: content.amount?.toString() || "1"}));
            break;
        default:
            throw new Error(`Unknown crate content type: ${content.type}`);
    }
}

export const getContents = (crate: ICrate) => {
    const totalRate = crate.contents.reduce((acc, content) => acc.plus(content.rate), new BigNumber(0));
    const random = new BigNumber(Math.random()).multipliedBy(totalRate);
    let currentRate = new BigNumber(0);
    for (const content of crate.contents) {
        currentRate = currentRate.plus(content.rate);
        if (currentRate.isGreaterThan(random)) {
            return content;
        }
    }
    return crate.contents[crate.contents.length - 1];
}
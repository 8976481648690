import { toast } from "react-toastify";
import { updateLastPlayed } from "../redux/slices/userSlice";
import { store } from "../redux/store";
import GameEvent from "../types/GameEvent";
import GameEventManager from "./GameEventManager";
import GameMigrationManager from "./GameMigrationManager";
import GameMiningManager from "./GameMiningManager";
import { performGameActions } from "./gameActions";
import { performAFKRewards, updateBonuses, updateGameState } from "./gameState";
import { calculateMissionRefreshSecondsRemaining, refreshMissions } from "./gameUtils/MissionUtils";
import Planet from "./Planet";

const gameEventManager = GameEventManager.getInstance();
const gameMiningManager = GameMiningManager.getInstance();

export const gameEngine = {
	start() {
		// Migrate game state if new version
		const gameMigrationManager = new GameMigrationManager((text: string) => toast.info(text));
		gameMigrationManager.migrate();

		// Calculate AFK rewards
		performAFKRewards();

		// Register event to update last played time every 30 seconds
		gameEventManager.registerEvent(
			new GameEvent(
				() => true,
				() => {
					store.dispatch(updateLastPlayed());
				},
				30 * 1000,

				true
			)
		);

		// update missions of expired
		gameEventManager.registerEvent(
			new GameEvent(
				() => { return ( calculateMissionRefreshSecondsRemaining() <= 0 ) },
				() => {
					refreshMissions();
				},
				30 * 1000,

				true
			)
		);

		// check for expired boss missions
		gameEventManager.registerEvent(
			new GameEvent(
				() => true,
				() => {
					Planet.checkBossTimer();
				},
				250,

				true
			)
		);

		updateBonuses();
	},
	tick() {
		gameEventManager.processEvents();
		gameMiningManager.processEvents();
		performGameActions();
		updateGameState();
	},
};

import { faClock, faList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import config from "../../../config";
import { formatTimeSpan } from "../../../engine/gameUtils";
import { calculateMissionRefreshSecondsRemaining, getAvailableMissions } from "../../../engine/gameUtils/MissionUtils";
import { RootState } from "../../../redux/store";
import { AvailableMissionDetails } from "../../AvailableMissionDetails";

import { InProgressMissionDetails } from "../../InProgressMissionDetails";
import "./index.css";

export const MissionTab = () => {
	const { t } = useTranslation();

	const { inProgress } = useSelector((state: RootState) => state.game.missions);
    
	const availableMissionIds: number[] = getAvailableMissions();

	return (
		<Card className="full-y full-x mission-tab">
			<CardHeader>
				<Row>
					<Col md={12} sm={12}>
						<CardTitle>{t("tabs.mission")}</CardTitle>
					</Col>
				</Row>
			</CardHeader>
			<CardBody>
                <Card>
                    <CardHeader>
                    <Row>
                        <Col md={9} sm={8}>
                            <CardTitle>{t("mission.missionsInProgress")}</CardTitle>
                        </Col>
                        <Col className="align-right" md={3} sm={4}>
                            <FontAwesomeIcon icon={faList} /> {Object.keys(inProgress).length} / {config.mission.maxMissionsActive}
                        </Col>
                    </Row>
                    </CardHeader>
                    <div className="missionsInPrograssContainer">
                        {Object.values(inProgress).map((mission) => {
                            return <InProgressMissionDetails key={mission.id} missionId={mission.id} />
                        })}
                    </div>
                </Card>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col md={9} sm={8}>
                                <CardTitle>{t("mission.availableMissions")}</CardTitle>
                            </Col>
                            <Col className="align-right" md={3} sm={4}>
                                <FontAwesomeIcon icon={faClock} /> {formatTimeSpan(calculateMissionRefreshSecondsRemaining())}
                            </Col>
                        </Row>
                    </CardHeader>
                    <div className="availableMissionsContainer">
                        {availableMissionIds.map((missionId) => {
                            return <AvailableMissionDetails key={missionId} missionId={missionId} />;
                        })}
                    </div>
                    </Card>
            </CardBody>
		</Card>
	);
};

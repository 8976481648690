import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faBox, faHammer, faLineChart, faPerson, faRocket, faScrewdriver, faScroll, faShield, faShop, faSun } from "@fortawesome/free-solid-svg-icons";
import "./UpgradeScreen.css"; // Stelle sicher, dass du die entsprechende CSS-Datei erstellst
import MinersTab from "./MinersTab";
import ResearchTab from "./ResearchTab";
import ResourcesTab from "./ResourcesTab";
import { useTranslation } from "react-i18next";
import BigNumber from "bignumber.js";
import AscensionTab from "./AscendingTab";
import BonusesOverviewTab from "./BonusOverviewTab";
import InventoryTab from "./InventoryTab";
import PlanetTravelTab from "./PlanetTravelTab";
import CraftingTab from "./CraftingTab";
import ShopTab from "./ShopTab";
import AccountTab from "./AccountTab";
import { MissionTab } from "./MissionTab";

enum Tab {
	Miners = "miners",
	Research = "research",
	Resources = "resources",
	Inventory = "inventory",
	Ascension = "ascension",
	Status = "status",
	PlanetTravel = "PlanetTravel",
	Crafting = "crafting",
	Shop = "Shop",
	Account = "Account",
	Mission = "Mission"
}

const UpgradeScreen = () => {
	const [activeTab, setActiveTab] = useState(Tab.Miners);
	const { t } = useTranslation();

	const amountOptions = [BigNumber(1), BigNumber(5), BigNumber(10), BigNumber(25), BigNumber(100), BigNumber(1000)];
	const [amountIndex, setAmountIndex] = useState(0); // Speichere den Index der ausgewählten Menge
	const amount = amountOptions[amountIndex];

	const handleAmountChange = () => {
		setAmountIndex((prevIndex) => (prevIndex + 1) % amountOptions.length); // Gehe zum nächsten Index, oder zurück zum Anfang
	};

	return (
		<div className="upgrade-screen">
			<div className="tabs">
				<button className={`tab ${activeTab === Tab.Miners ? "active" : ""}`} onClick={() => setActiveTab(Tab.Miners)}>
					<FontAwesomeIcon icon={faHammer} /> {t("tabs.miners")}
				</button>
				<button
					className={`tab ${activeTab === Tab.Research ? "active" : ""}`}
					onClick={() => setActiveTab(Tab.Research)}
				>
					<FontAwesomeIcon icon={faBook} /> {t("tabs.research")}
				</button>
				<button
					className={`tab ${activeTab === Tab.Resources ? "active" : ""}`}
					onClick={() => setActiveTab(Tab.Resources)}
				>
					<FontAwesomeIcon icon={faBox} /> {t("tabs.resources")}
				</button>
				<button 
					className={`tab ${activeTab === Tab.Mission ? "active" : ""}`}
					onClick={() => setActiveTab(Tab.Mission)}
				>
					<FontAwesomeIcon icon={faScroll} /> {t("tabs.mission")}
				</button>
				<button
					className={`tab ${activeTab === Tab.Inventory ? "active" : ""}`}
					onClick={() => setActiveTab(Tab.Inventory)}
				>
					<FontAwesomeIcon icon={faShield} /> {t("tabs.inventory")}
				</button>
				<button
					className={`tab ${activeTab === Tab.Crafting ? "active" : ""}`}
					onClick={() => setActiveTab(Tab.Crafting)}
				>
					<FontAwesomeIcon icon={faScrewdriver} /> {t("tabs.crafting")}
				</button>
				<button
					className={`tab ${activeTab === Tab.Ascension ? "active" : ""}`}
					onClick={() => setActiveTab(Tab.Ascension)}
				>
					<FontAwesomeIcon icon={faSun} /> {t("tabs.ascension")}
				</button>
				<button
					className={`tab ${activeTab === Tab.PlanetTravel ? "active" : ""}`}
					onClick={() => setActiveTab(Tab.PlanetTravel)}
				>
					<FontAwesomeIcon icon={faRocket} /> {t("tabs.planetTravel")}
				</button>
				<button
					className={`tab ${activeTab === Tab.Shop ? "active" : ""}`}
					onClick={() => setActiveTab(Tab.Shop)}
				>
					<FontAwesomeIcon icon={faShop} /> {t("tabs.shop")}
				</button>
				<button
					className={`tab ${activeTab === Tab.Status ? "active" : ""}`}
					onClick={() => setActiveTab(Tab.Status)}
				>
					<FontAwesomeIcon icon={faLineChart} /> {t("tabs.status")}
				</button>
				<button
					className={`tab ${activeTab === Tab.Account ? "active" : ""}`}
					onClick={() => setActiveTab(Tab.Account)}
				>
					<FontAwesomeIcon icon={faPerson} /> {t("tabs.account")}
				</button>
			</div>

			<div className="tab-content">
				{activeTab === Tab.Miners && <MinersTab amount={amount} handleAmountChange={handleAmountChange} />}
				{activeTab === Tab.Research && <ResearchTab amount={amount} handleAmountChange={handleAmountChange} />}
				{activeTab === Tab.Ascension && <AscensionTab amount={amount} handleAmountChange={handleAmountChange} />}
				{activeTab === Tab.Inventory && <InventoryTab />}
				{activeTab === Tab.Crafting && <CraftingTab />}
				{activeTab === Tab.Resources && <ResourcesTab />}
				{activeTab === Tab.Mission && <MissionTab />}
				{activeTab === Tab.PlanetTravel && <PlanetTravelTab />}
				{activeTab === Tab.Shop && <ShopTab/>}
				{activeTab === Tab.Status && <BonusesOverviewTab />}
				{activeTab === Tab.Account && <AccountTab />}
			</div>
		</div>
	);
};

export default UpgradeScreen;

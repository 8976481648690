import { gameInitialState } from "../../../redux/slices/gameSlice";
import { RootState } from "../../../redux/store";

export default function migrate_3_to_4(state: RootState) {
    const newState: RootState = { 
        ...state,
        user: { ...state.user },
        game: { ...state.game, enabledCodes: gameInitialState.enabledCodes }
    };
    
    return newState;
}
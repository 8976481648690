import BigNumber from "bignumber.js";
import React from "react";
import IOre from "../../types/IOre";
import { ImageSize } from "../../utils/assetsUtils";
import OreImage from "../OreImage";
import "./Ore.css";

interface OreProps {
	ore: IOre;
	onClick: () => void;
	onMouseEnter: () => void;
}

export const OreComponent: React.FC<OreProps> = ({ ore, onClick, onMouseEnter }) => {
	return <OreImage onClick={onClick} ore={ore.oreType} size={ImageSize.Medium} currentHp={BigNumber(ore.currentHp)} />;
};

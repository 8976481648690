import BigNumber from "bignumber.js";
import { motion } from "framer-motion";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import IMiner from "../../types/IMiner";
import IMinerType from "../../types/IMinerType";
import IMinerUpgrade from "../../types/IMinerUpgrade";

import { Button, Col, Container, OverlayTrigger, Popover, Row } from "react-bootstrap";
import GameEventManager from "../../engine/GameEventManager";
import { handleBuyMinerUpgradeAction } from "../../engine/gameActions";
import { formatNumber, isBonusTypePercentage } from "../../engine/gameUtils";
import { getOwnedMinerById } from "../../engine/gameUtils/MinerUtils";
import GameEvent from "../../types/GameEvent";
import "./index.css";

interface MinerUpgradeBarProps {
	miner: IMinerType;
}

export const MinerUpgradeBar: React.FC<MinerUpgradeBarProps> = ({ miner }) => {
	const { t } = useTranslation();
	const creditsState = useSelector((state: RootState) => state.game.credits);
	var credits = new BigNumber(creditsState);

	const minerEntry: IMiner | undefined = getOwnedMinerById(miner.id);
	const [attemptedPurchase, setAttemptedPurchase] = useState<number | null>(null);

	const handleBuy = (minerId: number, upgradeId: number) => {
		const upgrade = miner.upgrades.find((u) => u.id === upgradeId);
		if (!upgrade || !credits.gte(upgrade.price)) {
			setAttemptedPurchase(upgradeId);
			setTimeout(() => setAttemptedPurchase(null), 1000);
			return;
		}

		GameEventManager.getInstance().registerEvent(
			new GameEvent(
				() => {
					return credits.gte(upgrade.price);
				},
				() => handleBuyMinerUpgradeAction(minerId, upgradeId),
				0,
				false
			)
		);
	};

	const renderSkillBonus = (upgrade: IMinerUpgrade) => {
		return (
			<div key={upgrade.id}>
				{t(`bonuses.${upgrade.bonusType}.name`)}:{" "}
				{formatNumber(upgrade.bonusValue, 2, isBonusTypePercentage(upgrade.bonusType))}
			</div>
		);
	};

	const renderUpgradeButton = (upgrade: IMinerUpgrade) => {
		const isOwned = minerEntry?.upgradeIDs?.includes(upgrade.id) || false;
		const isAvailable = new BigNumber(minerEntry?.level || 0).gte(upgrade.minLevel);
		const canAfford = credits.gte(upgrade.price);

		const buttonVariant = isOwned ? "success" : isAvailable ? (canAfford ? "primary" : "danger") : "secondary";
		const isAttemptingPurchase = attemptedPurchase === upgrade.id;

		const upgradePopover = (
			<Popover id={`popover-upgrade-${upgrade.id}`}>
				<Popover.Header as="h3">{t(`upgrades.${upgrade.identifier}.title`)}</Popover.Header>
				<Popover.Body>
					<div>{upgrade.bonusType && renderSkillBonus(upgrade)}</div>
					{t(`upgrades.${upgrade.identifier}.description`)}
					<br />
				</Popover.Body>
			</Popover>
		);

		return (
			<OverlayTrigger trigger={["hover", "focus"]} placement="auto" overlay={upgradePopover}>
				<motion.div
					whileHover={{ scale: 1.1 }}
					whileTap={{ scale: 0.9 }}
					animate={{
						scale: isAttemptingPurchase ? [1, 1.25, 1] : 1,
						backgroundColor: isAttemptingPurchase ? ["#FFFFFF", "#FFAAAA", "#FFFFFF"] : "#FFFFFF",
					}}
					transition={{ duration: 0.2 }}
				>
					<Button
						variant={buttonVariant}
						disabled={(!isAvailable || !canAfford ) && !isOwned}
						onClick={() => handleBuy(miner.id, upgrade.id)}
						style={{ backgroundImage: `url(./assets/miners/${miner.id}/skill_${upgrade.id}.webp)` }}
						className="upgrade-button"
					>
						{isAvailable && !isOwned && formatNumber(upgrade.price)}
					</Button>
				</motion.div>
			</OverlayTrigger>
		);
	};

	return (
		<Container className="upgrade-button-container">
			<Row className="upgrade-button-row">
				{miner.upgrades.map((upgrade, index) => (
					<Col className="upgrade-button-col" key={index}>{renderUpgradeButton(upgrade)}</Col>
				))}
			</Row>
		</Container>
	);
};

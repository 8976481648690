import React, { useState, useEffect } from 'react';
import "./index.css"

import { RootState } from '../../redux/store';
import { useSelector } from 'react-redux';
import config from '../../config';
import { Card } from 'react-bootstrap';
import Planet from '../../engine/Planet';

const TimerBox = () => {
  const { currentDepth, lastGridUpdate, maxDepth } = useSelector((state: RootState) => state.game);
  const [currentTime, setCurrentTime] = useState(Date.now());

  const isTimeout = new Planet().isBossWave() && currentDepth === maxDepth;
  
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(Date.now());
    }, 99);

    return () => clearInterval(interval);
  }, []);

  const calculateTimeLeft = () => {
    if (isTimeout) {
        const diff = currentTime - lastGridUpdate;
        return Math.max(config.game.bossTimer * 1000 - diff,0);
    } else {
        const timeSince = currentTime - lastGridUpdate;
        return timeSince;
    }
  };

  const formatTime = (milliseconds: number) => {
    const ms = milliseconds % 1000;
    const seconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(seconds / 60);

    const displaySeconds = seconds % 60;
    const displayMinutes = minutes % 60;

    if (displayMinutes > 0) {
      return `${pad(displayMinutes)}:${pad(displaySeconds)}.${pad(ms)}`;
    }

    return `${pad(displaySeconds)}.${pad(ms)}`;
  };

  const pad = (number: number) => number.toString().padStart(2, '0');

  return (
    <Card className={`timer-box ${isTimeout ? 'timeout' : ''}`} >
        {formatTime(calculateTimeLeft())}
    </Card>
  );
};

export default TimerBox;

import { Button, Col, Modal, ModalDialog, Row } from "react-bootstrap";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import IShopEntry from "../../../types/IShopEntry";
import { confirmBuyShopItemAction } from "../../../engine/gameActions/ShopActions";
import config from "../../../config";
import EPaymentProvider from "../../../engine/Payment/EPaymentProvider";
import { isNum } from "react-toastify/dist/utils";
import { useState } from "react";
import { EPowerPlantType } from "../../../types/EPowerPlantType";
import PaymentProcess from "./PaymentProcess";

export const PurchaseModal = ({ show, onHide, item }: { show: boolean; onHide: () => void; item: IShopEntry }) => {
	const { t } = useTranslation();
	const [selectedPaymentProvider, setSelectedPaymentProvider] = useState<EPaymentProvider>(EPaymentProvider.PAYPAL);

	const onApprove = (shopItemId: number) => {
		console.log("Approve", shopItemId);
	};

	const onCancel = (shopItemId: number) => {
		toast.info(t("shop.payment.cancel"));
	};

	const onError = (shopItemId: number) => {
		toast.error(t("shop.payment.error"));
	};

	const onConfirm = (shopItemId: number) => {
		confirmBuyShopItemAction(shopItemId);
		onHide();
	};

	return (
		<Modal show={show} onHide={onHide} centered>
			<Modal.Header closeButton>
				<Modal.Title>{t(`shop.items.${item.identifier}.name`)}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{t(`shop.description`)}
				<p>{t(`shop.items.${item.identifier}.description`)}</p>

				<Row>
					{Object.keys(EPaymentProvider)
						.filter((x) => x.length > 2)
						.map((provider) => (
							<Col key={provider}>
								<Button
									onClick={() => {
										setSelectedPaymentProvider(EPaymentProvider[provider as unknown as keyof typeof EPaymentProvider]);
									}}
									disabled={
										selectedPaymentProvider === EPaymentProvider[provider as unknown as keyof typeof EPaymentProvider]
									}
								>
									{t("paymentProvider." + provider.toLowerCase() + ".name")}
								</Button>
							</Col>
						))}
				</Row>

				<ModalDialog>{t("paymentProvider.feeDescription")}</ModalDialog>

				<PaymentProcess
					item={item}
					provider={selectedPaymentProvider as unknown as EPaymentProvider}
					onConfirm={onConfirm}
					onError={onError}
					onCancel={onCancel}
					onApprove={onApprove}
				/>
			</Modal.Body>
		</Modal>
	);
};

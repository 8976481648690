import BigNumber from "bignumber.js";
import { EPowerPlantType } from "../types/EPowerPlantType";
import { assetsBasePath } from "../utils/assetsUtils";
import config from "../config";
import { store } from "../redux/store";
import { addCredits, addResources, incPowerPlantLevel, setPowerPlantStatus } from "../redux/slices/gameSlice";
import { EOreTypes } from "../types/EOreTypes";
import { toast } from "react-toastify";
import i18n from "../i18n/i18n";
import Ore from "./Ore";

export interface IPowerPlantProps {
    type: EPowerPlantType;
    isActive?: boolean;
    level?: number;
}

export default class PowerPlant {
    type: EPowerPlantType;
    isActive: boolean;
    level: number;

    constructor(plant: IPowerPlantProps) {
        this.type = plant.type;

        if(plant.isActive === undefined || plant.level === undefined) {
            const data = store.getState().game.powerPlants[plant.type]
            this.isActive = data.isActive;
            this.level = data.level;
        } else {
            this.isActive = plant.isActive;
            this.level = plant.level;
        }
    }

    calculateResourceCostPerCycle(): BigNumber {
        return BigNumber(config.powerPlant.factors[this.type].baseResourceCosts).times(BigNumber(2).pow(this.level));
    }

    calculateProductionMultiplier(): BigNumber {
        return BigNumber(config.powerPlant.factors[this.type].baseProductionFactor).plus(BigNumber(2).times(this.level));
    }

    calculateUpgradeCost(): BigNumber {
        return new BigNumber(config.powerPlant.factors[this.type].basePrice).times(Math.pow(config.powerPlant.factors[this.type].priceFactor, this.level));
    }

    getBackgroundImage = () => {
        return `${assetsBasePath}/background/power_plant_${EPowerPlantType[this.type].toLowerCase()}.webp`;
    }

    getUpgradeBackgroundImage = () => {
        return `${assetsBasePath}/background/power_plant_${EPowerPlantType[this.type].toLowerCase()}_upgrade.webp`;
    }

    hasEnoughResources(): boolean {
        switch(this.type) {
            case EPowerPlantType.COAL:
                return (new Ore(EOreTypes.Coal)).getCount().gte(this.calculateResourceCostPerCycle());
            case EPowerPlantType.NUCLEAR:
                return (new Ore(EOreTypes.Uranium)).getCount().gte(this.calculateResourceCostPerCycle());
        }
    }

    activate() {
        if(this.hasEnoughResources()) {
            store.dispatch(setPowerPlantStatus({ type: this.type, isActive: true }))
            this.isActive = true;
        }
    }

    deactivate() {
        store.dispatch(setPowerPlantStatus({ type: this.type, isActive: false }))
        this.isActive = false;
    }

    consumeResources() {
        switch(this.type) {
            case EPowerPlantType.COAL:
                store.dispatch(addResources({oreType: EOreTypes.Coal, amount: this.calculateResourceCostPerCycle().negated().toString()} ));
                break;
            case EPowerPlantType.NUCLEAR:
                store.dispatch(addResources({oreType: EOreTypes.Uranium, amount: this.calculateResourceCostPerCycle().negated().toString()} ));
                break;
        }
    }

    upgrade() {
        const price = this.calculateUpgradeCost();

        if(BigNumber(store.getState().game.credits).lt(price)) {
            toast.error(i18n.t("powerPlant.notEnoughCredits"));
            return
        }

        store.dispatch(addCredits(price.negated().toString()));
        store.dispatch(incPowerPlantLevel(this.type));

        this.level++;
        toast.success(i18n.t("powerPlant.upgraded", {level: this.level}));
    }
}
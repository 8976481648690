import BigNumber from "bignumber.js"
import EBonuses from "../types/EBonuses"
import { EItemRarity } from "../types/EItemRarity"
import { EItemType } from "../types/EItemType"
import IItem from "../types/IItem"

export const itemData: Record<number, IItem> = {
    1: {
        id: 1,
        type: EItemType.PICKAXE,
        rarity: EItemRarity.Common,
        randomBonuses: 1,
        bonusList: [
            {
                type: EBonuses.CLICK_BASE_DMG,
                value: BigNumber(2)
            }
        ]
    },
    2: {
        id: 2,
        type: EItemType.PICKAXE,
        rarity: EItemRarity.Uncommon,
        randomBonuses: 1,
        bonusList: [
            {
                type: EBonuses.CLICK_BASE_DMG,
                value: BigNumber(5)
            },
            {
                type: EBonuses.CLICK_DAMAGE_USER_MULTIPLIER,
                value: BigNumber(0.05)
            }
        ]
    },
    3: {
        id: 3,
        type: EItemType.PICKAXE,
        rarity: EItemRarity.Rare,
        randomBonuses: 2,
        bonusList: [
            {
                type: EBonuses.CLICK_BASE_DMG,
                value: BigNumber(10)
            },
            {
                type: EBonuses.CLICK_DAMAGE_USER_MULTIPLIER,
                value: BigNumber(0.1)
            },
            {
                type: EBonuses.ALL_DAMAGE_USER_MULTIPLIER,
                value: BigNumber(0.05)
            }
        ]
    },
    4: {
        id: 4,
        type: EItemType.PICKAXE,
        rarity: EItemRarity.Epic,
        randomBonuses: 2,
        bonusList: [
            {
                type: EBonuses.CLICK_BASE_DMG,
                value: BigNumber(20)
            },
            {
                type: EBonuses.CLICK_DAMAGE_USER_MULTIPLIER,
                value: BigNumber(0.15)
            },
            {
                type: EBonuses.ALL_DAMAGE_USER_MULTIPLIER,
                value: BigNumber(0.1)
            }
        ]
    },
    5: {
        id: 5,
        type: EItemType.PICKAXE,
        rarity: EItemRarity.Legendary,
        randomBonuses: 3,
        bonusList: [
            {
                type: EBonuses.CLICK_BASE_DMG,
                value: BigNumber(50)
            },
            {
                type: EBonuses.CLICK_DAMAGE_USER_MULTIPLIER,
                value: BigNumber(0.2)
            },
            {
                type: EBonuses.ALL_DAMAGE_USER_MULTIPLIER,
                value: BigNumber(0.15)
            },
            {
                type: EBonuses.CRITICAL_HIT_CHANCE,
                value: BigNumber(0.01)
            }
        ]
    },

    100: {
        id: 100,
        type: EItemType.HELMET,
        rarity: EItemRarity.Common,
        randomBonuses: 1,
        bonusList: [
            {
                type: EBonuses.MINE_REWARD_MULTIPLIER,
                value: BigNumber(0.02)
            }
        ]
    },
    101: {
        id: 101,
        type: EItemType.HELMET,
        rarity: EItemRarity.Uncommon,
        randomBonuses: 1,
        bonusList: [
            {
                type: EBonuses.MINE_REWARD_MULTIPLIER,
                value: BigNumber(0.05)
            },
            {
                type: EBonuses.AUTO_CLICKS_PER_SECOND,
                value: BigNumber(0.05)
            }
        ]
    },
    102: {
        id: 102,
        type: EItemType.HELMET,
        rarity: EItemRarity.Rare,
        randomBonuses: 2,
        bonusList: [
            {
                type: EBonuses.MINE_REWARD_MULTIPLIER,
                value: BigNumber(0.1)
            },
            {
                type: EBonuses.AUTO_CLICKS_PER_SECOND,
                value: BigNumber(0.1)
            }
        ]
    },
    103: {
        id: 103,
        type: EItemType.HELMET,
        rarity: EItemRarity.Epic,
        randomBonuses: 2,
        bonusList: [
            {
                type: EBonuses.MINE_REWARD_MULTIPLIER,
                value: BigNumber(0.15)
            },
            {
                type: EBonuses.AUTO_CLICKS_PER_SECOND,
                value: BigNumber(0.15)
            }
        ]
    },
    104: {
        id: 104,
        type: EItemType.HELMET,
        rarity: EItemRarity.Legendary,
        randomBonuses: 3,
        bonusList: [
            {
                type: EBonuses.MINE_REWARD_MULTIPLIER,
                value: BigNumber(0.2)
            },
            {
                type: EBonuses.AUTO_CLICKS_PER_SECOND,
                value: BigNumber(0.2)
            },
            {
                type: EBonuses.RARITY_BONUS,
                value: BigNumber(0.5)
            }
        ]
    },

    200: {
        id: 200,
        type: EItemType.ARMOR,
        rarity: EItemRarity.Common,
        randomBonuses: 1,
        bonusList: [
            {
                type: EBonuses.AFK_MINE_REWARD_MULTIPLIER,
                value: BigNumber(0.05)
            }
        ]
    },
    201: {
        id: 201,
        type: EItemType.ARMOR,
        rarity: EItemRarity.Uncommon,
        randomBonuses: 1,
        bonusList: [
            {
                type: EBonuses.AFK_MINE_REWARD_MULTIPLIER,
                value: BigNumber(0.1)
            },
            {
                type: EBonuses.CRITICAL_HIT_CHANCE,
                value: BigNumber(0.01)
            }
        ]
    }, 
    202: {
        id: 202,
        type: EItemType.ARMOR,
        rarity: EItemRarity.Rare,
        randomBonuses: 2,
        bonusList: [
            {
                type: EBonuses.AFK_MINE_REWARD_MULTIPLIER,
                value: BigNumber(0.15)
            },
            {
                type: EBonuses.CRITICAL_HIT_CHANCE,
                value: BigNumber(0.02)
            }
        ]
    },
    203: {
        id: 203,
        type: EItemType.ARMOR,
        rarity: EItemRarity.Epic,
        randomBonuses: 2,
        bonusList: [
            {
                type: EBonuses.AFK_MINE_REWARD_MULTIPLIER,
                value: BigNumber(0.2)
            },
            {
                type: EBonuses.CRITICAL_HIT_CHANCE,
                value: BigNumber(0.03)
            }
        ]
    },
    204: {
        id: 204,
        type: EItemType.ARMOR,
        rarity: EItemRarity.Legendary,
        randomBonuses: 3,
        bonusList: [
            {
                type: EBonuses.AFK_MINE_REWARD_MULTIPLIER,
                value: BigNumber(0.25)
            },
            {
                type: EBonuses.CRITICAL_HIT_CHANCE,
                value: BigNumber(0.05)
            },
            {
                type: EBonuses.RARITY_BONUS,
                value: BigNumber(0.5)
            }
        ]
    },

    300: {
        id: 300,
        type: EItemType.GLOVES,
        rarity: EItemRarity.Common,
        randomBonuses: 1,
        bonusList: [
            {
                type: EBonuses.CLICK_DAMAGE_USER_MULTIPLIER,
                value: BigNumber(0.05)
            }
        ]
    },
    301: {
        id: 301,
        type: EItemType.GLOVES,
        rarity: EItemRarity.Uncommon,
        randomBonuses: 1,
        bonusList: [
            {
                type: EBonuses.CLICK_DAMAGE_USER_MULTIPLIER,
                value: BigNumber(0.1)
            },
            {
                type: EBonuses.CRITICAL_HIT_CHANCE,
                value: BigNumber(0.01)
            }
        ]
    },
    302: {
        id: 302,
        type: EItemType.GLOVES,
        rarity: EItemRarity.Rare,
        randomBonuses: 2,
        bonusList: [
            {
                type: EBonuses.CLICK_DAMAGE_USER_MULTIPLIER,
                value: BigNumber(0.15)
            },
            {
                type: EBonuses.CRITICAL_HIT_CHANCE,
                value: BigNumber(0.02)
            }
        ]
    },
    303: {
        id: 303,
        type: EItemType.GLOVES,
        rarity: EItemRarity.Epic,
        randomBonuses: 2,
        bonusList: [
            {
                type: EBonuses.CLICK_DAMAGE_USER_MULTIPLIER,
                value: BigNumber(0.2)
            },
            {
                type: EBonuses.CRITICAL_HIT_CHANCE,
                value: BigNumber(0.03)
            }
        ]
    },
    304: {
        id: 304,
        type: EItemType.GLOVES,
        rarity: EItemRarity.Legendary,
        randomBonuses: 3,
        bonusList: [
            {
                type: EBonuses.CLICK_DAMAGE_USER_MULTIPLIER,
                value: BigNumber(0.25)
            },
            {
                type: EBonuses.CRITICAL_HIT_CHANCE,
                value: BigNumber(0.05)
            },
            {
                type: EBonuses.ALL_DAMAGE_USER_MULTIPLIER,
                value: BigNumber(0.05)
            }
        ]
    },

    400: {
        id: 400,
        type: EItemType.BOOTS,
        rarity: EItemRarity.Common,
        randomBonuses: 1,
        bonusList: [
            {
                type: EBonuses.AUTO_CLICKS_PER_SECOND,
                value: BigNumber(0.05)
            }
        ]
    },
    401: {
        id: 401,
        type: EItemType.BOOTS,
        rarity: EItemRarity.Uncommon,
        randomBonuses: 1,
        bonusList: [
            {
                type: EBonuses.AUTO_CLICKS_PER_SECOND,
                value: BigNumber(0.1)
            },
            {
                type: EBonuses.CRITICAL_HIT_CHANCE,
                value: BigNumber(0.01)
            }
        ]
    },
    402: {
        id: 402,
        type: EItemType.BOOTS,
        rarity: EItemRarity.Rare,
        randomBonuses: 2,
        bonusList: [
            {
                type: EBonuses.AUTO_CLICKS_PER_SECOND,
                value: BigNumber(0.15)
            },
            {
                type: EBonuses.CRITICAL_HIT_CHANCE,
                value: BigNumber(0.02)
            }
        ]
    },
    403: {
        id: 403,
        type: EItemType.BOOTS,
        rarity: EItemRarity.Epic,
        randomBonuses: 2,
        bonusList: [
            {
                type: EBonuses.AUTO_CLICKS_PER_SECOND,
                value: BigNumber(0.2)
            },
            {
                type: EBonuses.CRITICAL_HIT_CHANCE,
                value: BigNumber(0.03)
            }
        ]
    },
    404: {
        id: 404,
        type: EItemType.BOOTS,
        rarity: EItemRarity.Legendary,
        randomBonuses: 3,
        bonusList: [
            {
                type: EBonuses.AUTO_CLICKS_PER_SECOND,
                value: BigNumber(0.25)
            },
            {
                type: EBonuses.CRITICAL_HIT_CHANCE,
                value: BigNumber(0.05)
            },
            {
                type: EBonuses.RARITY_BONUS,
                value: BigNumber(0.5)
            }
        ]
    },

    500: {
        id: 500,
        type: EItemType.RELIC,
        rarity: EItemRarity.Common,

        bonusList: [
            {
                type: EBonuses.RARITY_BONUS,
                value: BigNumber(0.05)
            }
        ]
    },
    501: {
        id: 501,
        type: EItemType.RELIC,
        rarity: EItemRarity.Uncommon,

        bonusList: [
            {
                type: EBonuses.RARITY_BONUS,
                value: BigNumber(0.1)
            },
            {
                type: EBonuses.MINE_REWARD_MULTIPLIER,
                value: BigNumber(0.1)
            }
        ]
    },
    502: {
        id: 502,
        type: EItemType.RELIC,
        rarity: EItemRarity.Rare,

        bonusList: [
            {
                type: EBonuses.RARITY_BONUS,
                value: BigNumber(0.15)
            },
            {
                type: EBonuses.MINE_REWARD_MULTIPLIER,
                value: BigNumber(0.2)
            }
        ]
    },
    503: {
        id: 503,
        type: EItemType.RELIC,
        rarity: EItemRarity.Epic,

        bonusList: [
            {
                type: EBonuses.RARITY_BONUS,
                value: BigNumber(0.2)
            },
            {
                type: EBonuses.MINE_REWARD_MULTIPLIER,
                value: BigNumber(0.3)
            }
        ]
    },
    504: {
        id: 504,
        type: EItemType.RELIC,
        rarity: EItemRarity.Legendary,

        bonusList: [
            {
                type: EBonuses.RARITY_BONUS,
                value: BigNumber(0.25)
            },
            {
                type: EBonuses.MINE_REWARD_MULTIPLIER,
                value: BigNumber(0.5)
            }
        ]
    },




    1000: {
        id: 1000,
        type: EItemType.PRODUCT,
        rarity: EItemRarity.Common
    },
    1001: {
        id: 1001,
        type: EItemType.PRODUCT,
        rarity: EItemRarity.Common
    },
    1002: {
        id: 1002,
        type: EItemType.PRODUCT,
        rarity: EItemRarity.Common
    },
    1003: {
        id: 1003,
        type: EItemType.PRODUCT,
        rarity: EItemRarity.Common
    },
    1004: {
        id: 1004,
        type: EItemType.PRODUCT,
        rarity: EItemRarity.Common
    },
    1005: {
        id: 1005,
        type: EItemType.PRODUCT,
        rarity: EItemRarity.Common
    },
    1006: {
        id: 1006,
        type: EItemType.PRODUCT,
        rarity: EItemRarity.Common
    },
    1007: {
        id: 1007,
        type: EItemType.PRODUCT,
        rarity: EItemRarity.Common
    },
    1008: {
        id: 1008,
        type: EItemType.PRODUCT,
        rarity: EItemRarity.Common
    },
    1009: {
        id: 1009,
        type: EItemType.PRODUCT,
        rarity: EItemRarity.Common
    },
    1010: {
        id: 1010,
        type: EItemType.PRODUCT,
        rarity: EItemRarity.Common
    },
    1100: {
        id: 1010,
        type: EItemType.PRODUCT,
        rarity: EItemRarity.Common
    },
    2000: {
        id: 2000,
        type: EItemType.PRODUCT,
        rarity: EItemRarity.Epic
    }
}
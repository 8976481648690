import BigNumber from "bignumber.js";
import { Card, Col, ListGroup, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

import { EOreTypes } from "../../types/EOreTypes";

import { ImageSize } from "../../utils/assetsUtils";
import "../OreComponent/Ore.css";
import OreImage from "../OreImage";
import RefineryOverviewComponent from "../Refinery/RefineryOverviewComponent";
import PowerPlantContainer from "../PowerPlantContainer";

const ResourcesTab = () => {
	const { t } = useTranslation();
	const { resources } = useSelector((state: RootState) => state.game);

	return (
		<div className="resources-tab">
			<Card className="mb-3">
				<Card.Header>
					<Card.Title>{t("resources.title")}</Card.Title>
				</Card.Header>
				<Card.Body>
					<Row className="align-center">
						{Object.entries(resources).map(([oreType, quantity]) => (
							<Col key={oreType}>
								<OreImage ore={parseInt(oreType) as EOreTypes} size={ImageSize.Medium} count={BigNumber(quantity)} />
							</Col>
						))}
					</Row>
					<hr></hr>
					<ListGroup variant="flush">
						<RefineryOverviewComponent />
					</ListGroup>
					<hr></hr>
					<ListGroup variant="flush">
						<PowerPlantContainer />
					</ListGroup>
				</Card.Body>
			</Card>
		</div>
	);
};

export default ResourcesTab;

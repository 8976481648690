import BigNumber from "bignumber.js";
import { ICrate } from "../types/ICrate";
import EBonuses from "../types/EBonuses";
import { ECrateContentType } from "../types/ECrateContentType";
import { ECrateLevel } from "../types/ECrateLevel";
import { EOreTypes } from "../types/EOreTypes";

export const cratesData: ICrate[] = [
	{
		id: "crate_1",
		level: ECrateLevel.Bronze,
		contents: [
			{ type: ECrateContentType.Resource, id: EOreTypes.Iron, amount: new BigNumber(120), rate: new BigNumber(10) },
			{
				type: ECrateContentType.Boost,
				id: EBonuses.ALL_DAMAGE_USER_MULTIPLIER,
				amount: new BigNumber(0.3),
				rate: new BigNumber(10),
				duration: new BigNumber(60),
			},
			{ type: ECrateContentType.Item, id: 1, amount: new BigNumber(1), rate: new BigNumber(1) },
		],
	},
];

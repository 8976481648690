import config from "../../config";
import missionData from "../../data/missionData";
import { setAvailableMissions, setMissionsLastRefresh } from "../../redux/slices/gameSlice";
import { store } from "../../redux/store";
import IMission from "../../types/IMission";

export const getAvailableMissions = (): number[] => {
	const missions = store.getState().game.missions;

	if (missions.lastRefresh === 0) refreshMissions();

    return missions.available;
	
};

export const refreshMissions = () => {
	const { game } = store.getState();

	var availableMissions: Record<number, IMission> = {};
    var offeredMissionIds: number[] = [];

	Object.values(missionData).forEach((mission) => {
		if (mission.isAvailable(game)) availableMissions[mission.id] = mission;
	});

    for(let i = 0; i < config.mission.maxMissionsOffered; i++){
        const keys = Object.keys(availableMissions);
        const randomKey = keys[Math.floor(Math.random() * keys.length)];
        if(offeredMissionIds.includes(Number(randomKey))) continue;
        
        offeredMissionIds.push(Number(randomKey));
    }

    store.dispatch(setMissionsLastRefresh(Date.now()));
    store.dispatch(setAvailableMissions(offeredMissionIds));
};

export const calculateMissionRefreshSecondsRemaining = (): number => {
    const { lastRefresh } = store.getState().game.missions;

    const timeSinceLastRefresh = Date.now() - lastRefresh;

    return (config.mission.missionOfferInterval * 1000 - timeSinceLastRefresh) / 1000;
}

export const getFreeMissionId = (): number => {
    const { inProgress } = store.getState().game.missions;

    Object.keys(inProgress)

    var newId : number;

    do {
        newId = Math.floor(Math.random() * 100000);
    } while (Object.keys(inProgress).includes(newId.toString()))

    return newId;
}
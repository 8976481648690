import ICraftingRecipt from "../types/ICraftingRecipt";

export const craftingRecipesData: Record<number, ICraftingRecipt> = {
    1: {
        id: 1,
        input: [
            {
                itemId: 1002,
                quantity: 2
            },
            {
                itemId: 1001,
                quantity: 1
            }
        ],
        output: 1
    },
    2: {
        id: 2,
        input: [
            {
                itemId: 1003,
                quantity: 2
            },
            {
                itemId: 1002,
                quantity: 4
            }
        ],
        output: 2
    },
    3: {
        id: 3,
        input: [
            {
                itemId: 1005,
                quantity: 2
            },
            {
                itemId: 1004,
                quantity: 2
            }
        ],
        output: 3
    },
    4: {
        id: 4,
        input: [
            {
                itemId: 1006,
                quantity: 2
            },
            {
                itemId: 1100,
                quantity: 20
            }
        ],
        output: 4
    },
    5: {
        id: 5,
        input: [
            {
                itemId: 1007,
                quantity: 2
            },
            {
                itemId: 1100,
                quantity: 50
            }
        ],
        output: 5
    },
    100: {
        id: 100,
        input: [
            {
                itemId: 1001,
                quantity: 2
            },
            {
                itemId: 1000,
                quantity: 2
            }
        ],
        output: 100
    },
    101: {
        id: 101,
        input: [
            {
                itemId: 1003,
                quantity: 2
            },
            {
                itemId: 1000,
                quantity: 2
            }
        ],
        output: 101
    },
    102: {
        id: 102,
        input: [
            {
                itemId: 1005,
                quantity: 2
            },
            {
                itemId: 1004,
                quantity: 2
            }
        ],
        output: 102
    },
    103: {
        id: 103,
        input: [
            {
                itemId: 1006,
                quantity: 2
            },
            {
                itemId: 1100,
                quantity: 20
            }
        ],
        output: 103
    },
    104: {
        id: 104,
        input: [
            {
                itemId: 1007,
                quantity: 2
            },
            {
                itemId: 1100,
                quantity: 50
            }
        ],
        output: 104
    },
    200: {
        id: 200,
        input: [
            {
                itemId: 1001,
                quantity: 10
            },
            {
                itemId: 1002,
                quantity: 4
            }
        ],
        output: 200
    },
    201: {
        id: 201,
        input: [
            {
                itemId: 1003,
                quantity: 10
            },
            {
                itemId: 1002,
                quantity: 4
            }
        ],
        output: 201
    },
    202: {
        id: 202,
        input: [
            {
                itemId: 1005,
                quantity: 10
            },
            {
                itemId: 1004,
                quantity: 4
            }
        ],
        output: 202
    },
    203: {
        id: 203,
        input: [
            {
                itemId: 1006,
                quantity: 10
            },
            {
                itemId: 1100,
                quantity: 40
            }
        ],
        output: 203
    },
    204: {
        id: 204,
        input: [
            {
                itemId: 1007,
                quantity: 10
            },
            {
                itemId: 1100,
                quantity: 100
            }
        ],
        output: 204
    },
    300: {
        id: 300,
        input: [
            {
                itemId: 1001,
                quantity: 5
            },
            {
                itemId: 1002,
                quantity: 2
            }
        ],
        output: 300
    },
    301: {
        id: 301,
        input: [
            {
                itemId: 1003,
                quantity: 5
            },
            {
                itemId: 1002,
                quantity: 2
            }
        ],
        output: 301
    },
    302: {
        id: 302,
        input: [
            {
                itemId: 1005,
                quantity: 5
            },
            {
                itemId: 1004,
                quantity: 2
            }
        ],
        output: 302
    },
    303: {
        id: 303,
        input: [
            {
                itemId: 1006,
                quantity: 5
            },
            {
                itemId: 1100,
                quantity: 20
            }
        ],
        output: 303
    },
    304: {
        id: 304,
        input: [
            {
                itemId: 1007,
                quantity: 5
            },
            {
                itemId: 1100,
                quantity: 50
            }
        ],
        output: 304
    },
    400: {
        id: 400,
        input: [
            {
                itemId: 1001,
                quantity: 5
            },
            {
                itemId: 1002,
                quantity: 2
            }
        ],
        output: 400
    },
    401: {
        id: 401,
        input: [
            {
                itemId: 1003,
                quantity: 5
            },
            {
                itemId: 1002,
                quantity: 2
            }
        ],
        output: 401
    },
    402: {
        id: 402,
        input: [
            {
                itemId: 1005,
                quantity: 5
            },
            {
                itemId: 1004,
                quantity: 2
            }
        ],
        output: 402
    },
    403: {
        id: 403,
        input: [
            {
                itemId: 1006,
                quantity: 5
            },
            {
                itemId: 1100,
                quantity: 20
            }
        ],
        output: 403
    },
    404: {
        id: 404,
        input: [
            {
                itemId: 1007,
                quantity: 5
            },
            {
                itemId: 1100,
                quantity: 50
            }
        ],
        output: 404
    },
    500: {
        id: 500,
        input: [
            {
                itemId: 1100,
                quantity: 5
            },
            {
                itemId: 1003,
                quantity: 2
            }
        ],
        output: 500
    },
    501: {
        id: 501,
        input: [
            {
                itemId: 1100,
                quantity: 10
            },
            {
                itemId: 1004,
                quantity: 5
            }
        ],
        output: 501
    },
    502: {
        id: 502,
        input: [
            {
                itemId: 1100,
                quantity: 20
            },
            {
                itemId: 1006,
                quantity: 5
            }
        ],
        output: 502
    },
    503: {
        id: 503,
        input: [
            {
                itemId: 1100,
                quantity: 40
            },
            {
                itemId: 1007,
                quantity: 5
            }
        ],
        output: 503
    }
};
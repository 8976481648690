import React, { useEffect } from 'react';
import { Howl } from 'howler';
import { assetsBasePath } from '../utils/assetsUtils';
import { calculateEffectivVolume } from '../Sound';
import { EConfig } from '../types/EConfig';
import { store } from '../redux/store';

const BackgroundMusic = () => {
  const config = store.getState().game.config;

  const sound = new Howl({
    src: [`./sounds/ambiente.mp3`],
    loop: true,
    volume: calculateEffectivVolume(EConfig.MUSIC_VOLUME),
  });

  sound.play();

  useEffect(() => {
    const interval = setInterval(() => {
      const vol = calculateEffectivVolume(EConfig.MUSIC_VOLUME)
      sound.volume(vol);
    }, 250);

    return () => clearInterval(interval);
  }, [config]);

  return null;
};

export default BackgroundMusic;

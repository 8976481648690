import { toast } from "react-toastify";
import config from "../../config";
import { store } from "../../redux/store";
import i18n from "../../i18n/i18n";
import IMissionInProgress from "../../types/IMissionInProgress";
import missionData from "../../data/missionData";
import { addCredits, addItems, addMissionInProgress, addMissionProgress, addRefinery, addResources, removeMissionFromAvailable, removeMissionInProgress, removeResource, setMissionProgress } from "../../redux/slices/gameSlice";
import { EMissingSubjectivTypes } from "../../types/IMissionSubjectiv";
import { EOreTypes } from "../../types/EOreTypes";
import BigNumber from "bignumber.js";
import { formatNumber } from "../gameUtils";
import { EMissionRewardTypes } from "../../types/IMissionReward";
import { getItemCountById } from "../gameUtils/ItemUtils";
import Ore from "../Ore";

export const handleStartMissionAction = (missionId: number) => {
    const { missions } = store.getState().game;
    const mission_obj  = missionData[missionId];

    if(Object.keys(missions.inProgress).length >= config.mission.maxMissionsActive){
        toast.error(i18n.t("mission.error.tooManyMissions"));
    }

    var mission: IMissionInProgress = {
        id: 0,
        missionId: missionId,
        acceptedTime: Date.now(),

        currentProgress: {}
    }

    Object.keys(mission_obj.subjective).forEach((key) => {
        mission.currentProgress[parseInt(key)] = {
            required: mission_obj.subjective[parseInt(key)].value().toString(),
            current: "0"
        }
    })

    store.dispatch(removeMissionFromAvailable(missionId));
    store.dispatch(addMissionInProgress(mission));

    toast.success(i18n.t("mission.started"));
}

export const depositMissionObjectiveAction = (missionId: number, objectiveId: number) => {
    const missionStats = store.getState().game.missions.inProgress[missionId];
    const missionObj = missionData[missionStats.missionId];

    switch(missionObj.subjective[objectiveId].type){
        case EMissingSubjectivTypes.DELIVER_ORES:
            const oreTypeId = missionObj.subjective[objectiveId].subjectiveItemId
            if(oreTypeId === undefined){
                throw new Error("Missing subjective item id");
            }

            const oreInstance = new Ore(oreTypeId as EOreTypes);

            if (oreInstance.getCount().lte(0) || oreInstance.getCount().isNaN()) {
                toast.error(i18n.t("mission.error.noOres"));
                return;
            }

            const diff = BigNumber(missionStats.currentProgress[objectiveId].required).minus(missionStats.currentProgress[objectiveId].current)

            if(oreInstance.getCount().gte(diff)){
                store.dispatch(addResources({ oreType: oreTypeId, amount: diff.negated().toString() }));
                store.dispatch(addMissionProgress({ missionId, objectiveId, value: diff.toString() }));
                toast.success(i18n.t("mission.deposited", {amount: formatNumber(diff)}));
            }
            else{
                store.dispatch(removeResource({ oreType: oreTypeId }));
                store.dispatch(addMissionProgress({ missionId, objectiveId, value: oreInstance.getCount().toString() }));
                toast.success(i18n.t("mission.deposited", {amount: formatNumber(oreInstance.getCount())}));
            }

            break;

        
        case EMissingSubjectivTypes.DELIVER_ITEMS:
            const itemId = missionObj.subjective[objectiveId].subjectiveItemId
            if(itemId === undefined){
                throw new Error("Missing subjective item id");
            }

            const itemAmount = getItemCountById(itemId);

            if (itemAmount.lte(0) || itemAmount.isNaN()) {
                toast.error(i18n.t("mission.error.noItems"));
                return;
            }

            const diffItem = BigNumber(missionStats.currentProgress[objectiveId].required).minus(missionStats.currentProgress[objectiveId].current)

            if(itemAmount.gte(diffItem)){
                store.dispatch(addItems({ itemId: itemId, amount: diffItem.negated().toString() }));
                store.dispatch(addMissionProgress({ missionId, objectiveId, value: diffItem.toString() }));
                toast.success(i18n.t("mission.deposited", {amount: formatNumber(diffItem)}));
            }
            else{
                store.dispatch(addItems({ itemId: itemId, amount: itemAmount.negated().toString() }));
                store.dispatch(addMissionProgress({ missionId, objectiveId, value: itemAmount.toString() }));
                toast.success(i18n.t("mission.deposited", {amount: formatNumber(itemAmount)}));
            }

            break;
        

        default:
            throw new Error("Unknown mission objective type");
    }
}

export const handleCancelMissionAction = (missionId: number) => {
    store.dispatch(removeMissionInProgress(missionId));
    toast.success(i18n.t("mission.cancelled"));
}

export const handleChangeMaxDepthAction = (newDepth: number) => {
    const { inProgress } = store.getState().game.missions;

    Object.keys(inProgress).forEach((key) => {
        const mission = inProgress[parseInt(key)];
        const missionObj = missionData[mission.missionId];

        Object.keys(missionObj.subjective).forEach((objectiveId) => {
            if(missionObj.subjective[parseInt(objectiveId)].type === EMissingSubjectivTypes.COMPLETE_DEPHS){
                store.dispatch(setMissionProgress({ missionId: mission.id, objectiveId: parseInt(objectiveId), value: newDepth.toString() }));

                handleCheckMissionCompletion(mission.id);
            }
        });
    });
}

export const handleDestoryOreAction = (oreType: EOreTypes) => {
    const { inProgress } = store.getState().game.missions;

    Object.keys(inProgress).forEach((key) => {
        const mission = inProgress[parseInt(key)];
        const missionObj = missionData[mission.missionId];

        Object.keys(missionObj.subjective).forEach((objectiveId) => {
            if(missionObj.subjective[parseInt(objectiveId)].type === EMissingSubjectivTypes.DESTROY_ORES && missionObj.subjective[parseInt(objectiveId)].subjectiveItemId === oreType){
                store.dispatch(setMissionProgress({ missionId: mission.id, objectiveId: parseInt(objectiveId), value: BigNumber(mission.currentProgress[parseInt(objectiveId)].current).plus(1).toString() }));
                handleCheckMissionCompletion(mission.id);
            }
        });
    });
}

export const handleCheckMissionCompletion = (missionId: number) => {
    const { inProgress } = store.getState().game.missions;
    const mission = inProgress[missionId];
    const missionObj = missionData[mission.missionId];

    var isCompleted = true;

    Object.keys(missionObj.subjective).forEach((key) => {
        if(mission.currentProgress[parseInt(key)].required !== mission.currentProgress[parseInt(key)].current){
            isCompleted = false;
        }
    });

    if(isCompleted){
        toast.success(i18n.t("mission.completedInBackground", { missionName: i18n.t("mission." + missionObj.id + ".name") }));
    }
}

export const handleCompleteMissionAction = (missionId: number) => {
    const { inProgress } = store.getState().game.missions;
    const mission = inProgress[missionId];
    const missionObj = missionData[mission.missionId];

    var isCompleted = true;

    Object.keys(missionObj.subjective).forEach((key) => {
        if(mission.currentProgress[parseInt(key)].required > mission.currentProgress[parseInt(key)].current){
            isCompleted = false;
        }
    });

    if(!isCompleted){
        toast.error(i18n.t("mission.notCompleted"));
        return;
    }

    if(missionObj.time * 1000 + mission.acceptedTime < Date.now()){
        toast.error(i18n.t("mission.timeExpired"));
        return;
    }

    Object.keys(missionObj.rewards).forEach((key) => {
        const reward = missionObj.rewards[parseInt(key)];

        switch(reward.type){
            case EMissionRewardTypes.CURRENCY:
                let value : BigNumber = reward.value()

                store.dispatch(addCredits(value.toString()));
                toast.success(i18n.t("mission.rewardedCredits", {amount: formatNumber(value)}));

                break;
            case EMissionRewardTypes.ITEM:
                let itemId = reward.identifier;
                let amount = reward.value();

                if(itemId === undefined){
                    throw new Error("Missing reward item id");
                }

                store.dispatch(addItems({ itemId: parseInt(itemId), amount: amount.toString() }));
                toast.success(i18n.t("mission.rewardedItems", {amount: formatNumber(amount), item: i18n.t("items." + itemId + ".name")}));

                break;
            case EMissionRewardTypes.REFINERY:
                store.dispatch(addRefinery());
                toast.success(i18n.t("mission.rewardedRefinery"));

                break;
            default:
                throw new Error("Unknown reward type");
        }
    });

    store.dispatch(removeMissionInProgress(missionId));
    toast.success(i18n.t("mission.completed"));
}
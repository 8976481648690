import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en.json';
import de from './de.json';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(initReactI18next)
  .use(I18nextBrowserLanguageDetector)
  .init({
    resources: {
      en: { translation: en },
      de: { translation: de },
    },
    detection: {
      order: ['localStorage', 'navigator'],
      lookupQuerystring: 'lng'
    },
    fallbackLng: 'en',
    interpolation: { escapeValue: false },
  });

export default i18n;

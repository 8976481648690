import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BigNumber from "bignumber.js";
import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { formatNumber, getFontAwesomeIconForType, isBonusTypePercentage } from "../../engine/gameUtils";
import { calculateMinerAutoDamage, calculateMinerClickDamage, getMinerById } from "../../engine/gameUtils/MinerUtils";
import EBonuses from "../../types/EBonuses";
import IMiner from "../../types/IMiner";
import IMinerType from "../../types/IMinerType";

interface MinerDamageInfoProps {
	miner: IMiner;
	levelups: BigNumber;
}

const MinerDamageInfo: React.FC<MinerDamageInfoProps> = ({ miner, levelups }) => {
	const { t } = useTranslation();

	if (!miner) return null;

	const minerUpgraded = { ...miner, level: BigNumber(miner.level).plus(levelups).toString() };

	const minerType: IMinerType = getMinerById(miner.id);
	const icon = getFontAwesomeIconForType(minerType.bonusType);
	let value: BigNumber = new BigNumber(0);
	let valueUpgraded: BigNumber = new BigNumber(0);

	switch (minerType.bonusType) {
		case EBonuses.CLICK_DAMAGE_ADDITION:
			value = calculateMinerClickDamage(miner);
			valueUpgraded = calculateMinerClickDamage(minerUpgraded);
			break;
		case EBonuses.AUTO_DAMAGE_ADDITION:
			value = calculateMinerAutoDamage(miner);
			valueUpgraded = calculateMinerAutoDamage(minerUpgraded);
			break;
		case EBonuses.MINE_REWARD_MULTIPLIER:
			value = minerType.bonusValue.times(minerType.factors.bonusValue.pow(BigNumber(miner.level)))
			valueUpgraded = minerType.bonusValue.times(minerType.factors.bonusValue.pow(BigNumber(miner.level).plus(levelups)))
			break;
		default:
			console.error(`Type ${minerType.bonusType} not implemented`);
			return null;
	}

	const popover = (
		<Popover id={`popover-${minerType.bonusType}`}>
			<Popover.Header as="h3">{t(`bonuses.${minerType.bonusType}.name`)}</Popover.Header>
			<Popover.Body>{t(`bonuses.${minerType.bonusType}.description`, { value: formatNumber(value) })}</Popover.Body>
		</Popover>
	);

	return (
		<OverlayTrigger trigger={["hover", "focus"]} placement="auto" overlay={popover}>
			<div>
				<FontAwesomeIcon icon={icon} /> {formatNumber(value, 1, isBonusTypePercentage(minerType.bonusType))} → {formatNumber(valueUpgraded, 1, isBonusTypePercentage(minerType.bonusType))}
			</div>
		</OverlayTrigger>
	);
};

export default MinerDamageInfo;

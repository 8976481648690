import { loadGameState } from "../../redux/slices/gameSlice";
import { loadUserState, userInitialState } from "../../redux/slices/userSlice";
import { store } from "../../redux/store";

export default class GameMigrationManager {
	setStatusLine: (text: string) => void;

	constructor(statusLineFunction: (text: string) => void) {
		this.setStatusLine = statusLineFunction;
	}

	needMigration(): boolean {
		const { config_version } = store.getState().user;
		return config_version !== userInitialState.config_version;
	}

	migrate() {
		if (!this.needMigration()) {
			return;
		}

		this.setStatusLine("Migration started");

		for (
			let i = store.getState().user.config_version;
			i < userInitialState.config_version;
			i++
		) {
			this.setStatusLine(`Migrating to version ${i + 1}`);
			this.migrateVersion(i, i + 1);
		}
	}

	migrateVersion(from: number, to: number) {
        const stateStart = store.getState();

        if(stateStart.user.config_version !== from) {
            this.setStatusLine(`Migration from version ${from} failed, current version is ${stateStart.user.config_version}`);
            throw new Error(`Migration from version ${from} failed, current version is ${stateStart.user.config_version}`)
        }

        const migrationName = `migrate_${from}_to_${to}`;
        const migrationModule = require(`./migrations/${migrationName}`);

        this.setStatusLine(`Running migration ${migrationName}`);
        const stateModified = migrationModule.default(stateStart);
        stateModified.user.config_version = to;

        store.dispatch(loadUserState(stateModified.user));
        store.dispatch(loadGameState(stateModified.game));

    }
}

import BigNumber from "bignumber.js";

export enum EMissionRewardTypes {
    ITEM,
    CURRENCY,
    REFINERY
}

export default interface IMissionReward {
    type: EMissionRewardTypes;
    identifier?: string;
    value: () => BigNumber;
}
import { IconDefinition, IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";

export const cuBoots: IconDefinition = {
	icon: [
		28,
		28,
		[],
		"",
		"M16,8v2c0,.55-.45,1-1,1H3c-.55,0-1-.45-1-1v-2h14Z M25,17h-8c-.55,0-1-.45-1-1,1.103,0,2-.8975,2-2v-2c0-1.1025-.897-2-2-2H2c-1.103,0-2,.8975-2,2v2c0,1.1025,.897,2,2,2v8.13c.32-.09,.65-.13,1-.13H29c.35,0,.68,.04,1,.13v-2.13c0-2.76-2.24-5-5-5Z",
	],
	iconName: "Boots" as IconName,
	prefix: "cu" as IconPrefix,
};

export const cuPickaxe: IconDefinition = {
  icon: [
    28,
    28,
    [],
    "",
    "M51.9,38.24H48.1v36a1.9,1.9,0,1,0,3.79,0Z M53.68,37.24V34a.5.5,0,0,1,.16-.36.49.49,0,0,1,.37-.14A71.72,71.72,0,0,1,72.27,36.7a28.86,28.86,0,0,0-18.15-9.19.5.5,0,0,1-.44-.5V25.59H46.32V27a.5.5,0,0,1-.44.5A28.86,28.86,0,0,0,27.73,36.7,71.72,71.72,0,0,1,45.79,33.5a.49.49,0,0,1,.37.14.5.5,0,0,1,.16.36v3.24h7.36ZM61.74,31a.5.5,0,0,1,.66-.26,27.75,27.75,0,0,1,2.73,1.38.5.5,0,1,1-.5.87A26.79,26.79,0,0,0,62,31.7.5.5,0,0,1,61.74,31ZM56,29.32a.5.5,0,0,1,.59-.39c1,.21,2,.47,2.95.78a.5.5,0,0,1-.15,1l-.15,0c-.93-.29-1.89-.55-2.85-.75A.5.5,0,0,1,56,29.32Zm-19.7,3.2A26,26,0,0,0,33.77,34a.5.5,0,1,1-.56-.83,26.88,26.88,0,0,1,2.62-1.57.5.5,0,1,1,.46.89Zm5.47-2.16a28.84,28.84,0,0,0-2.79.94.5.5,0,1,1-.37-.93,29.57,29.57,0,0,1,2.89-1,.5.5,0,1,1,.27,1Zm5.8-1c-1,.08-2,.2-2.93.37h-.09a.5.5,0,0,1-.09-1c1-.17,2-.3,3-.38a.5.5,0,1,1,.08,1Zm2.46-.6a.47.47,0,0,1,.51-.49c1,0,2,.08,3,.19a.5.5,0,0,1-.05,1h-.06c-1-.11-2-.17-2.95-.19A.5.5,0,0,1,50,28.75Z",
  ],
  iconName: "Pickaxe" as IconName,
  prefix: "cu" as IconPrefix,
};

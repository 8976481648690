import React from 'react';
import { Card, CardBody, CardHeader, CardText, CardTitle, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PowerPlantEntry from '../PowerPlantEntry';
import { EPowerPlantType } from '../../types/EPowerPlantType';

import "./index.css";

const PowerPlantContainer = () => {
    const { t } = useTranslation();

    return (
        <div className="power-plant-overview">
            <Card>
                <CardHeader>
                    <CardTitle>
                        {t("powerPlant.title")}
                    </CardTitle>
                </CardHeader>
                <CardBody>
                    <CardText className="align-center">
                        {t("powerPlant.description")}
                    </CardText>
                    <Row>
                        <Col md={6} sm={12}>
                            <PowerPlantEntry type={EPowerPlantType.COAL} />
                        </Col>
                        <Col md={6} sm={12} className="align-right">
                            <PowerPlantEntry type={EPowerPlantType.NUCLEAR} />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </div>
    );
}

export default PowerPlantContainer;
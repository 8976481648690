import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BigNumber from "bignumber.js";
import React from "react";
import { Card, ListGroup, OverlayTrigger, Popover } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { formatNumber, getFontAwesomeIconForType, isBonusTypePercentage } from "../../engine/gameUtils";
import { getTotalBonusValueOfBoosts } from "../../engine/gameUtils/BoostUtils";
import { RootState } from "../../redux/store";
import EBonuses from "../../types/EBonuses";
import AscensionSkill from "../../engine/AscensionSkill";
import Research from "../../engine/Research";

const BonusesOverviewTab: React.FC = () => {
	const { t } = useTranslation();
	const userBonuses = useSelector((state: RootState) => state.game.userBonuses);

	const disabledBonuses = [EBonuses.NONE, EBonuses.AUTO_DAMAGE_SELF_MULTIPLIER, EBonuses.CLICK_DAMAGE_SELF_MULTIPLIER];

	return (
		<Card className="full-y full-x bonus-overview-tab">
			<Card.Header>
				<Card.Title>{t("bonuses.title")}</Card.Title>
			</Card.Header>
			<Card.Body>
				<ListGroup variant="flush">
					{Object.entries(EBonuses)
						.filter((x) => !disabledBonuses.includes(x[1]))
						.map(([key, value]) => {
							const bonusValue = new BigNumber(userBonuses[value]);
							const isPercentage = isBonusTypePercentage(value);
							const formattedValue = formatNumber(bonusValue, 2, isPercentage);

							// Bonusquellen berechnen
							const researchBonus = Research.getTotalBonusValueOfResearches(value);
							const boostsBonus = getTotalBonusValueOfBoosts(value);
							const ascensionBonus = AscensionSkill.getTotalBonusValueOfAscension(value);

							const popoverContent = (
								<>
									{researchBonus.isGreaterThan(0) && (
										<div>{t("bonuses.researchBonus", { value: formatNumber(researchBonus, 2, isPercentage) })}</div>
									)}
									{boostsBonus.isGreaterThan(0) && (
										<div>{t("bonuses.boostsBonus", { value: formatNumber(boostsBonus, 2, isPercentage) })}</div>
									)}
									{ascensionBonus.isGreaterThan(0) && (
										<div>{t("bonuses.ascensionBonus", { value: formatNumber(ascensionBonus, 2, isPercentage) })}</div>
									)}
								</>
							);

							const popover = (
								<Popover id={`popover-${value}`}>
									<Popover.Header as="h3">{t(`bonuses.${value}.name`)}</Popover.Header>
									<Popover.Body>
										{t(`bonuses.${value}.description`, { amount: formattedValue })}
										{popoverContent}
									</Popover.Body>
								</Popover>
							);

							return (
								<ListGroup.Item key={value} className="d-flex justify-content-between align-items-center">
									<div>
										<FontAwesomeIcon icon={getFontAwesomeIconForType(value)} className="me-2" />
										{t(`bonuses.${value}.name`)}
									</div>
									<div>
										<OverlayTrigger trigger={["hover", "focus"]} placement="auto" overlay={popover}>
											<FontAwesomeIcon icon={faInfoCircle} className="me-2" />
										</OverlayTrigger>
										{formattedValue}
									</div>
								</ListGroup.Item>
							);
						})}
				</ListGroup>
			</Card.Body>
		</Card>
	);
};

export default BonusesOverviewTab;

export enum EItemType {
	PICKAXE,
	HELMET,
	ARMOR,
	BOOTS,
	GLOVES,
	RELIC,

	POTION,
	PRODUCT
}
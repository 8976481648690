// item={item} provider={selectedPaymentProvider} onHide={onHide}

import { Card, Col, Row } from "react-bootstrap";
import EPaymentProvider from "../../../engine/Payment/EPaymentProvider";
import PaymentProvider from "../../../engine/Payment/PaymentProvider";
import IShopEntry from "../../../types/IShopEntry";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface PaymentProcessProps {
	item: IShopEntry;
	provider: EPaymentProvider;
	onError: (shopItemId: number) => void;
    onCancel: (shopItemId: number) => void;
    onApprove: (shopItemId: number) => void;
    onConfirm: (shopItemId: number) => void;
}

const PaymentProcess: React.FC<PaymentProcessProps> = ({ item, provider, onApprove, onCancel, onConfirm, onError }) => {
	const { t } = useTranslation();
	const providerInstance = new PaymentProvider(provider);

	return (
		<Card>
			<Card.Header>
				<Card.Title>
					<FontAwesomeIcon icon={providerInstance.getFontAwesomeIcon()} />{" "}
					{t("paymentProvider." + EPaymentProvider[providerInstance.type].toString().toLowerCase() + ".name")}
				</Card.Title>
				<Card.Body>
					<Row>
						<Col md={8}>{t("paymentProvider.basePrice")}</Col>
						<Col md={4} className="align-right">
							{item.price} €
						</Col>
					</Row>
					<Row>
						<Col md={8}>{t("paymentProvider.paymentFee")}</Col>
						<Col md={4} className="align-right">
							{providerInstance.calculateFee(item.price)} €
						</Col>
					</Row>
					<hr />
					<Row>
						<Col md={8}>{t("paymentProvider.totalRemaining")}</Col>
						<Col md={4} className="align-right">
							{item.price - providerInstance.calculateFee(item.price)} €
						</Col>
					</Row>
					<Row>
						<Col md={8}>{t("paymentProvider.totalCost")}</Col>
						<Col md={4} className="align-right bold">
							{item.price} €
						</Col>
					</Row>

					{providerInstance.renderPaymentButton(item, item.price, onApprove, onCancel, onConfirm, onError)}
				</Card.Body>
			</Card.Header>
		</Card>
	);
};

export default PaymentProcess;

import React, { useEffect, useState } from 'react';
import './StatusEffects.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { getFontAwesomeIconForType, isBonusTypePercentage, formatNumber } from '../../engine/gameUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import BigNumber from 'bignumber.js';
import IBoost from '../../types/IBoost';
import config from '../../config';

const StatusEffects = () => {
  const { t } = useTranslation();
  const boosts = useSelector((state: RootState) => state.game.boosts);
  const [currentTime, setCurrentTime] = useState(Date.now());

  useEffect(() => {
    // Erstelle einen Timer, der die Komponente jede Sekunde aktualisiert
    const intervalId = setInterval(() => {
      setCurrentTime(Date.now());
    }, config.game.updateInterfaceInterval);

    // Bereinigungsfunktion, um den Timer zu löschen, wenn die Komponente demontiert wird
    return () => clearInterval(intervalId);
  }, []);

  const renderBoostInfo = (boost : IBoost) => {
    const icon = getFontAwesomeIconForType(boost.type);
    const endTime = new Date(boost.endTime).toLocaleTimeString();
    const timeRemaining = new BigNumber(boost.endTime).minus(currentTime).dividedBy(1000).toNumber();
    const amountDisplay = isBonusTypePercentage(boost.type) ? `${formatNumber(new BigNumber(boost.amount), 2)}` : formatNumber(new BigNumber(boost.amount), 2);

    const popover = (
      <Popover id={`popover-boost-${boost.type}`}>
        <Popover.Header as="h3">{t(`bonuses.${boost.type}.name`)}</Popover.Header>
        <Popover.Body>
          <p>{t(`bonuses.${boost.type}.description`, { amount: amountDisplay })}</p>
          <p>{t('general.endTime', { time: endTime, timeRemaining: Math.max(0, timeRemaining).toFixed(2) })}</p>
        </Popover.Body>
      </Popover>
    );

    return (
      <OverlayTrigger trigger={["hover", "focus"]} placement="top" overlay={popover} key={boost.type + boost.endTime}>
        <div className="boost-icon">
          <FontAwesomeIcon icon={icon} />
        </div>
      </OverlayTrigger>
    );
  };

  return (
    <div className="statusEffects">
      {boosts.map(renderBoostInfo)}
    </div>
  );
};

export default StatusEffects;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBahtSign } from "@fortawesome/free-solid-svg-icons";
import { AnimatePresence, motion } from "framer-motion";
import BigNumber from "bignumber.js";
import { formatNumber } from "../../engine/gameUtils";
import { RootState } from "../../redux/store";
import "./CreditsDisplay.css";

interface CreditsChangeProps {
	id: number;
	change: BigNumber;
}


const CreditsDisplay = () => {
    const credits = useSelector((state: RootState) => state.game.credits);
    const [creditChanges, setCreditChanges] = useState<CreditsChangeProps[]>([]);
	const [lastCredit, setLastCredit] = useState<BigNumber | null>(null);

    useEffect(() => {
		if(lastCredit === null){
			setLastCredit(BigNumber(credits));
			return;
		}

        const creditDiff = new BigNumber(credits).minus(lastCredit as BigNumber);

        if (!creditDiff.isZero()) {
            setCreditChanges((prevChanges) => [...prevChanges, { change: creditDiff, id: Math.random()}]);
			setLastCredit(BigNumber(credits));
        }
    }, [credits, creditChanges]);

    return (
        <div className="creditsDisplay">
            <p>
                <FontAwesomeIcon icon={faBahtSign} /> {formatNumber(new BigNumber(credits), 2)}
            </p>
            <AnimatePresence>
                {creditChanges.map((change) => (
                    <motion.div
                        initial={{ opacity: 0, y: -20, scale: 0.8 }}
                        animate={{ opacity: 1, y: 0, scale: 1 }}
                        exit={{ opacity: 0, y: 20, scale: 1.2 }}
                        transition={{ duration: 0.8 }}
                        className={`creditChange ${change.change.gt(0) ? "" : "negative"}`}
                        key={change.id}
                        onAnimationComplete={() => setCreditChanges((currentChanges) =>
							currentChanges.filter((item) => item.id !== change.id)
						)}
                    >
                        {change.change.gt(0) ? `+${formatNumber(change.change, 2)}` : "-" + formatNumber(change.change.negated(), 2)}
                    </motion.div>
                ))}
            </AnimatePresence>
        </div>
    );
};

export default CreditsDisplay;

import React, { useRef } from "react";
import { Button, Card, Col, NavLink, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { exportSaveGame, importSaveGame } from "../../../engine/gameState"; // Stelle sicher, dass importSaveGame implementiert ist
import config from "../../../config";
import i18n from "../../../i18n/i18n";
import CodeInputComponent from "../../CodeInputComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord, faGooglePlay, faSteam } from "@fortawesome/free-brands-svg-icons";
import SoundSettings from "../../Settings/SoundSettings";

const AccountTab = () => {
	const { t } = useTranslation();
	const fileInputRef = useRef<HTMLInputElement>(null);

	const handleExportGame = () => {
		exportSaveGame();
		toast.info(t("account.gameExported"));
	};

	const handleImportGameClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event.target.files) {
			return;
		}
		const file = event.target.files[0];

		if (file) {
			const reader = new FileReader();
			reader.onload = async () => {
				if (reader.result) {
					const arrayBuffer = reader.result as ArrayBuffer;
					const uint8Array = new Uint8Array(arrayBuffer);
					importSaveGame(uint8Array);
				}
			};

			reader.readAsArrayBuffer(file);
		}
	};

	const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		event.stopPropagation();

		const file = event.dataTransfer.files[0];
		if (file) {
			const reader = new FileReader();
			reader.onload = async () => {
				if (reader.result) {
					const arrayBuffer = reader.result as ArrayBuffer;
					const uint8Array = new Uint8Array(arrayBuffer);
					importSaveGame(uint8Array);
				}
			};

			reader.readAsArrayBuffer(file);
		}
	};

	const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		event.stopPropagation();
	};

	const changeLanguage = (lng: string) => {
		i18n.changeLanguage(lng);
	};

	return (
		<Card className="full-y full-x account-tab">
			<Card.Header>
				<Row>
					<Col md={8}>
						<Card.Title>{t("tabs.account")}</Card.Title>
					</Col>
					<Col md={4} className="align-right">
						{i18n.language === "en" ? (
							<Button variant="info" onClick={() => changeLanguage("de")}>
								Deutsch
							</Button>
						) : (
							<Button variant="info" onClick={() => changeLanguage("en")}>
								English
							</Button>
						)}
					</Col>
				</Row>
			</Card.Header>
			<Card.Body onDrop={handleDrop} onDragOver={handleDragOver}>
				<Card.Subtitle>
					<p>{t("account.importExport")}</p>
				</Card.Subtitle>
				<Row>
					<Col>
						<Button variant="primary" onClick={handleExportGame}>
							{t("account.exportGame")}
						</Button>
					</Col>
					<Col>
						<Button variant="secondary" onClick={handleImportGameClick}>
							{t("account.importGame")}
						</Button>
						<input type="file" style={{ display: "none" }} ref={fileInputRef} onChange={handleFileChange} />
					</Col>
				</Row>
				<hr></hr>
				<Card.Subtitle>
					<p>{t("codes.label")}</p>
				</Card.Subtitle>
				<Row>
					<Card.Text>{t("codes.description")}</Card.Text>
					<CodeInputComponent />
				</Row>
				<hr></hr>
				<Card.Subtitle>
					<p>{t("settings.label")}</p>
				</Card.Subtitle>
				<SoundSettings />
				<hr></hr>
				<Card.Subtitle>
					<p>{t("social.label")}</p>
				</Card.Subtitle>
				<Row>
					<Col>
						<NavLink href={config.social.discord} target="_blank" rel="noreferrer">
							<FontAwesomeIcon icon={faDiscord}/> Discord
						</NavLink>
					</Col>
					<Col>
						<NavLink href={"#"} target="_blank" rel="noreferrer">
							<FontAwesomeIcon icon={faSteam}/> Coming soon
						</NavLink>
					</Col>
					<Col>
						<NavLink href={"#"} target="_blank" rel="noreferrer">
							<FontAwesomeIcon icon={faGooglePlay}/> Coming soon
						</NavLink>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	);
};

export default AccountTab;

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import BigNumber from "bignumber.js";
import config from "../../config";
import { itemData } from "../../data/itemData";
import { planetData } from "../../data/planetData";
import { GameState } from "../../engine/gameState";
import IBoost from "../../types/IBoost";
import { EAscensionSkill } from "../../types/EAscensionSkill";
import EBonuses from "../../types/EBonuses";
import { EConfig } from "../../types/EConfig";
import { EItemType } from "../../types/EItemType";
import { EOreTypes } from "../../types/EOreTypes";
import { EResearchTypes } from "../../types/EResearchTypes";
import { ItemEntry } from "../../types/ItemEntry";
import IOre from "../../types/IOre";
import { PlanetType } from "../../types/PlanetType";
import IMissionInProgress from "../../types/IMissionInProgress";
import { EPowerPlantType } from "../../types/EPowerPlantType";

const initialState: GameState = {
	credits: "0",
	planet: planetData[0],
	completedPlanets: [],
	upgrades: {},
	research: [],
	miner: [],
	resources: {} as Record<EOreTypes, string>,
	currentOrePosition: { x: 0, y: 0 },
	userBonuses: {} as Record<EBonuses, string>,
	grid: [] as IOre[][],
	boosts: [] as IBoost[],
	maxDepth: 1,
	currentDepth: 1,
	ascension: 0,
	ascensionPoints: "0",
	ascensionSkills: {
		[EAscensionSkill.MINERS_CASH]: "0",
		[EAscensionSkill.MINERS_LUCK]: "0",
		[EAscensionSkill.MINERS_PICKAXE]: "0",
		[EAscensionSkill.MINERS_TECHNOLOGY]: "0",
		[EAscensionSkill.CRITICAL_ENGINEERING]: "0",
		[EAscensionSkill.DEEP_CORE_MINING]: "0",
		[EAscensionSkill.QUANTUM_SCANNERS]: "0",
		[EAscensionSkill.STELLAR_NAVIGATION]: "0",
		[EAscensionSkill.MINERS_EFFICIENCY]: "0",
	},

	config: {
		[EConfig.AUTO_PROCEED_LEVEL]: false,
		[EConfig.DRAGGED_ITEM]: null,
		[EConfig.SOUND_ENABLED]: true,
		[EConfig.MUSIC_ENABLED]: true,
		[EConfig.SOUND_VOLUME]: 0.7,
		[EConfig.SOUND_VOLUME_OVERRIDE]: false,
		[EConfig.EFFECTS_VOLUME]: 1,
		[EConfig.EFFECTS_VOLUME_OVERRIDE]: false,
		[EConfig.CLICK_VOLUME]: 1.0,
		[EConfig.CLICK_VOLUME_OVERRIDE]: false,
		[EConfig.ORE_DESTROY_VOLUME]: 1.0,
		[EConfig.ORE_DESTROY_VOLUME_OVERRIDE]: false,
		[EConfig.MUSIC_VOLUME]: 1,
		[EConfig.MUSIC_VOLUME_OVERRIDE]: false,
		[EConfig.UPGRADE_VOLUME]: 1,
		[EConfig.UPGRADE_VOLUME_OVERRIDE]: false,
	},

	refineries: {
		0: {
			id: 0,
			level: "0",
			items: [],
			lastRefined: Date.now(),
			selectedBlueprintId: 0,
		},
	},

	equipment: {
		[EItemType.ARMOR]: null,
		[EItemType.BOOTS]: null,
		[EItemType.GLOVES]: null,
		[EItemType.HELMET]: null,
		[EItemType.PICKAXE]: null,
		[EItemType.RELIC]: [null, null],
	},

	items: {},

	shopPurchases: {},

	missions: {
		lastRefresh: 0,
		inProgress: [],
		available: [],
	},

	powerPlants: {
		[EPowerPlantType.COAL]: {
			level: 0,
			type: EPowerPlantType.COAL,
			isActive: false
		},
		[EPowerPlantType.NUCLEAR]: {
			level: 0,
			type: EPowerPlantType.NUCLEAR,
			isActive: false
		}
	},

	enabledCodes: [],

	lastGridUpdate: Date.now(),
};

export const gameSlice = createSlice({
	name: "game",
	initialState,
	reducers: {
		loadGameState: (state, action: PayloadAction<GameState>) => {
			Object.assign(state, action.payload);
		},
		setCurrentDepth: (state, action: PayloadAction<number>) => {
			state.currentDepth = action.payload;
		},
		setMaxDepth: (state, action: PayloadAction<number>) => {
			state.maxDepth = action.payload;
		},
		setCurrentOrePosition: (state, action: PayloadAction<{ x: number; y: number }>) => {
			state.currentOrePosition = action.payload;
		},
		setCredits: (state, action: PayloadAction<string>) => {
			state.credits = action.payload.toString();
		},
		addCredits: (state, action: PayloadAction<string>) => {
			state.credits = new BigNumber(state.credits).plus(action.payload).toString();
		},
		setPlanet: (state, action: PayloadAction<PlanetType>) => {
			state.planet = action.payload;
		},
		setGrid: (state, action: PayloadAction<IOre[][]>) => {
			state.grid = action.payload;
		},
		addMiner: (state, action: PayloadAction<{ id: number; amount: string }>) => {
			const miner = state.miner.find((miner) => miner.id === action.payload.id);
			if (miner === undefined) {
				state.miner.push({ id: action.payload.id, level: action.payload.amount.toString(), upgradeIDs: [] });
			} else {
				miner.level = BigNumber(miner.level).plus(action.payload.amount).toString();
			}
		},
		addMinerUpgrade: (state, action: PayloadAction<{ id: number; upgradeId: number }>) => {
			const miner = state.miner.find((miner) => miner.id === action.payload.id);
			if (miner !== undefined) {
				miner.upgradeIDs.push(action.payload.upgradeId);
			}
		},
		setOreHp: (state, action: PayloadAction<{ x: number; y: number; hp: string }>) => {
			state.grid[action.payload.y][action.payload.x].currentHp = action.payload.hp;
		},
		setOreType: (state, action: PayloadAction<{ x: number; y: number; ore: EOreTypes }>) => {
			state.grid[action.payload.y][action.payload.x].oreType = action.payload.ore;

			if (action.payload.ore === EOreTypes.Empty) {
				state.grid[action.payload.y][action.payload.x].currentHp = "0";
			}
		},
		updateLastGridUpdate: (state) => {
			state.lastGridUpdate = Date.now();
		},
		setUserBonus: (state, action: PayloadAction<{ bonus: EBonuses; value: string }>) => {
			state.userBonuses[action.payload.bonus] = action.payload.value.toString();
		},
		addResources: (state, action: PayloadAction<{ oreType: EOreTypes; amount: string }>) => {
			state.resources[action.payload.oreType] = new BigNumber(state.resources[action.payload.oreType] ?? "0")
				.plus(action.payload.amount)
				.toString();
		},
		addResearchLevel: (state, action: PayloadAction<{ researchType: EResearchTypes; amount: string }>) => {
			const research = state.research.find((x) => x.type === action.payload.researchType);

			if (research === undefined) {
				state.research.push({ type: action.payload.researchType, level: action.payload.amount });
				return;
			}

			research.level = new BigNumber(research.level).plus(action.payload.amount).toString();
		},
		addBoost: (state, action: PayloadAction<IBoost>) => {
			state.boosts.push(action.payload);
		},
		removeBoost: (state, action: PayloadAction<IBoost>) => {
			state.boosts = state.boosts.filter((boost) => boost.endTime !== action.payload.endTime);
		},
		setConfig: (state, action: PayloadAction<{ key: EConfig; value: any }>) => {
			state.config[action.payload.key] = action.payload.value;
		},
		performAscension: (state, action: PayloadAction<{ resetPoints: string; newDepth: number }>) => {
			state.credits = "0";
			state.resources = {} as Record<EOreTypes, string>;
			state.miner = [];
			state.upgrades = {};
			state.research = [];
			state.userBonuses = {} as Record<EBonuses, string>;
			state.grid = [];
			state.boosts = [];
			state.currentDepth = action.payload.newDepth;
			state.maxDepth = 1;
			state.ascensionPoints = BigNumber(state.ascensionPoints).plus(action.payload.resetPoints).toString();
			state.ascension++;

			var minerSouldCount = BigNumber(0)

			Object.values(state.items).forEach((item) => {
				if (item.itemID === 2000) {
					minerSouldCount = minerSouldCount.plus(item.count);
				}
			});

			state.items = {};

			if (minerSouldCount.isGreaterThan(0)) {
				state.items[20001] = {
					itemID: 2000,
					count: minerSouldCount.toString(),
					level: "0",
					id: 20001,
				};
			}

			state.boosts = [];

			if (!state.completedPlanets.includes(state.planet.id)) {
				state.completedPlanets.push(state.planet.id);
			}
		},
		increaseAscensionSkill: (state, action: PayloadAction<{ skill: EAscensionSkill; amount: string }>) => {
			state.ascensionSkills[action.payload.skill] = BigNumber(state.ascensionSkills[action.payload.skill])
				.plus(action.payload.amount)
				.toString();
		},
		addAscensionPoints: (state, action: PayloadAction<string>) => {
			state.ascensionPoints = new BigNumber(state.ascensionPoints).plus(BigNumber(action.payload)).toString();
		},
		startProduction: (state, action: PayloadAction<{ refineryId: number; blueprintId: number }>) => {
			state.refineries[action.payload.refineryId].selectedBlueprintId = action.payload.blueprintId;
			state.refineries[action.payload.refineryId].lastRefined = Date.now();
		},
		setProductionLastRefined: (state, action: PayloadAction<{ refineryId: number; startTime: number }>) => {
			state.refineries[action.payload.refineryId].lastRefined = action.payload.startTime;
		},
		addRefinery: (state) => {
			state.refineries[Object.keys(state.refineries).length] = {
				id: Object.keys(state.refineries).length,
				level: "0",
				items: [],
				lastRefined: Date.now(),
				selectedBlueprintId: 0,
			};
		},
		travelToPlanet: (state, action: PayloadAction<PlanetType>) => {
			state.planet = action.payload;
			state.miner = initialState.miner;
			state.resources = initialState.resources;
			state.maxDepth = 1;
			state.currentDepth = 1;
		},
		addItems: (state, action: PayloadAction<{ itemId: number; amount: string; level?: number }>) => {
			let exists = false;

			if (
				itemData[action.payload.itemId].type === EItemType.PRODUCT ||
				itemData[action.payload.itemId].type === EItemType.POTION
			) {
				Object.values(state.items).forEach((item) => {
					if (item.itemID === action.payload.itemId) {
						exists = true;
						state.items[item.id].count = BigNumber(item.count).plus(action.payload.amount).toString();
						return;
					}
				});

				if (exists) return;
			}

			const generateNewItemId = (): number => {
				const existingIds = new Set([
					...Object.values(state.items).map((item) => item.id),
					...Object.values(state.equipment)
						.flatMap((equip) => (Array.isArray(equip) ? equip.map((e) => e?.id) : equip?.id))
						.filter((id) => id !== null),
				]);

				let id;
				do {
					id = Math.floor(Math.random() * config.game.maxItemId);
				} while (existingIds.has(id));

				return id;
			};

			var item: ItemEntry = {
				itemID: action.payload.itemId,
				count: action.payload.amount,
				level: "1",
				id: generateNewItemId(),
			};

			if (action.payload.level) {
				item.level = action.payload.level.toString();
			}

			state.items[item.id] = item;
		},
		addShopPurchaseEntry: (state, action: PayloadAction<number>) => {
			state.shopPurchases[action.payload] = (state.shopPurchases[action.payload] ?? 0) + 1;
		},
		addItemEntry: (state, action: PayloadAction<ItemEntry>) => {
			state.items[action.payload.id] = action.payload;
		},
		removeItemEntry: (state, action: PayloadAction<ItemEntry>) => {
			delete state.items[action.payload.id];
		},
		updateItemEntry: (state, action: PayloadAction<ItemEntry>) => {
			state.items[action.payload.id] = action.payload;
		},
		equipItem: (state, action: PayloadAction<{ item: ItemEntry | null; type: EItemType; index?: number }>) => {
			switch (action.payload.type) {
				case EItemType.RELIC:
					state.equipment[EItemType.RELIC][action.payload.index as number] = action.payload.item;
					break;
				case EItemType.ARMOR:
				case EItemType.BOOTS:
				case EItemType.GLOVES:
				case EItemType.HELMET:
				case EItemType.PICKAXE:
					state.equipment[action.payload.type] = action.payload.item;
					break;
				default:
					throw new Error("Invalid item type");
			}
		},
		setAvailableMissions: (state, action: PayloadAction<number[]>) => {
			state.missions.available = action.payload;
		},
		setMissionsLastRefresh: (state, action: PayloadAction<number>) => {
			state.missions.lastRefresh = action.payload;
		},
		addMissionInProgress: (state, action: PayloadAction<IMissionInProgress>) => {
			const generateNewMissionID = (): number => {
				const existingIds = new Set(Object.values(state.missions.inProgress).map((mission) => mission.id));

				let id;

				do {
					id = Math.floor(Math.random() * config.game.maxMissionId);
				} while (existingIds.has(id));

				return id;
			};

			const id = generateNewMissionID();

			state.missions.inProgress = { ...state.missions.inProgress, [id]: { ...action.payload, id: id } };
		},
		removeMissionFromAvailable: (state, action: PayloadAction<number>) => {
			var hasFiltered = false;
			state.missions.available = state.missions.available.filter((id) => {
				if (id === action.payload && !hasFiltered) {
					hasFiltered = true;
					return false;
				}

				return true;
			});
		},
		addMissionProgress: (state, action: PayloadAction<{ missionId: number; objectiveId: number; value: string }>) => {
			const mission = state.missions.inProgress[action.payload.missionId];
			mission.currentProgress[action.payload.objectiveId].current = BigNumber(
				mission.currentProgress[action.payload.objectiveId].current
			)
				.plus(BigNumber(action.payload.value))
				.toString();
		},
		setMissionProgress: (state, action: PayloadAction<{ missionId: number; objectiveId: number; value: string }>) => {
			const mission = state.missions.inProgress[action.payload.missionId];
			mission.currentProgress[action.payload.objectiveId].current = action.payload.value;
		},
		removeMissionInProgress: (state, action: PayloadAction<number>) => {
			delete state.missions.inProgress[action.payload];
		},
		removeResource: (state, action: PayloadAction<{ oreType: EOreTypes }>) => {
			delete state.resources[action.payload.oreType];
		},
		incRefineryLevel: (state, action: PayloadAction<{ refineryId: number; amount: string }>) => {
			state.refineries[action.payload.refineryId].level = BigNumber(state.refineries[action.payload.refineryId].level)
				.plus(action.payload.amount)
				.toString();
		},
		setPowerPlantStatus: (state, action: PayloadAction<{ type: EPowerPlantType; isActive: boolean }>) => {
			state.powerPlants[action.payload.type].isActive = action.payload.isActive;
		},
		incPowerPlantLevel: (state, action: PayloadAction<EPowerPlantType>) => {
			state.powerPlants[action.payload].level = state.powerPlants[action.payload].level + 1;
		},
		addEnabledCode: (state, action: PayloadAction<string>) => {
			state.enabledCodes.push(action.payload);
		}
	},
});

export const {
	loadGameState,
	setCurrentOrePosition,
	setCredits,
	addCredits,
	setPlanet,
	setGrid,
	setOreType,
	setUserBonus,
	setOreHp,
	addMiner,
	addMinerUpgrade,
	addResources,
	addResearchLevel,
	addBoost,
	removeBoost,
	setCurrentDepth,
	setMaxDepth,
	setConfig,
	performAscension,
	increaseAscensionSkill,
	addAscensionPoints,
	startProduction,
	setProductionLastRefined,
	addItems,
	addItemEntry,
	removeItemEntry,
	updateItemEntry,
	equipItem,
	travelToPlanet,
	addShopPurchaseEntry,
	addRefinery,
	setAvailableMissions,
	setMissionsLastRefresh,
	addMissionInProgress,
	removeMissionFromAvailable,
	addMissionProgress,
	setMissionProgress,
	removeMissionInProgress,
	removeResource,
	incRefineryLevel,
	updateLastGridUpdate,
	setPowerPlantStatus,
	incPowerPlantLevel,
	addEnabledCode
} = gameSlice.actions;

export const gameInitialState = initialState;
export default gameSlice.reducer;

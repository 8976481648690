import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentOrePosition, setGrid } from "../../redux/slices/gameSlice";
import { RootState, store } from "../../redux/store";
import { OreComponent } from "../OreComponent";
import "./MiningScreen.css";
import Ore from "../../engine/Ore";
import Planet from "../../engine/Planet";

const MiningScreen = () => {
	const dispatch = useDispatch();
	const grid = useSelector((state: RootState) => state.game.grid);

	useEffect(() => {
		if(grid !==  undefined && grid.length > 0) return;
		const initialGrid = new Planet().createMiningMap();
		dispatch(setGrid(initialGrid));
	}, [dispatch, grid]);

	return (
		<div className="mining-screen" onContextMenu={(event) => {event.preventDefault()}}>
			{grid.map((row, rowIndex) => (
				<div key={rowIndex} className="grid-row">
					{row.map((ore, oreIndex) => (
						<OreComponent
							key={oreIndex}
							ore={ore}
							onClick={() => handleOreClick(rowIndex, oreIndex)}
							onMouseEnter={() => handleOreMouseEnter(rowIndex, oreIndex)}
						/>
					))}
				</div>
			))}
		</div>
	);

	
	function handleOreMouseEnter(y: number, x: number) {
		store.dispatch(setCurrentOrePosition({ x, y }));
	}

	function handleOreClick(y: number, x: number) {
		const ore = new Ore(grid[y][x])
		ore.clickDamageAction();
	}
};


export default MiningScreen;

import BigNumber from "bignumber.js";
import { shopData } from "../../data/shopData";
import { store } from "../../redux/store";
import EBonuses from "../../types/EBonuses";
import { EShopEntryType } from "../../types/EShopEntryType";

export const getShopItemPurchases = (shopItemId: number) => {
    const { shopPurchases } = store.getState().game;

    if (shopPurchases[shopItemId] === undefined) return 0;

    return shopPurchases[shopItemId];
}

export const getTotalBonusValueOfShop = (bonus: EBonuses): BigNumber => {
    const { shopPurchases } = store.getState().game;

    var bonusValue = BigNumber(0);

    Object.keys(shopPurchases).forEach(shopItemId => {
        const shopItem = shopData[parseInt(shopItemId)];
        if (shopItem.type === EShopEntryType.PERM_BOOST && shopItem.data.type === bonus) {
            bonusValue = bonusValue.plus(shopItem.data.value);
        }
    });

    return bonusValue;
}
import React from "react";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./index.css"; // CSS für die Gestaltung
import { ImageSize } from "../../utils/assetsUtils";
import { EAscensionSkill } from "../../types/EAscensionSkill";
import AscensionSkill from "../../engine/AscensionSkill";

interface AscensionSkillImageProps {
	skill: EAscensionSkill;
	popup?: boolean;
	size?: ImageSize;
	onClick?: () => void;
}

const AscensionSkillImage: React.FC<AscensionSkillImageProps> = ({
	skill,
	popup = false,
	size = ImageSize.Original,
	onClick = () => {},
}) => {
	const { t } = useTranslation();

	const skillInstance = new AscensionSkill(skill);

	const skillSrc = skillInstance.getImage(size);
	const skillName = t(`skill.${skill}.title`);
	const skillDescription = t(`skill.${skill}.description`);

	const renderPopover = (
		<Popover id={`popover-${skill}`}>
			<Popover.Header as="h3">{skillName}</Popover.Header>
			<Popover.Body>
				<div className="italic">{skillDescription}</div>
			</Popover.Body>
		</Popover>
	);

	return (
		<div className="skill-image-container" onClick={onClick}>
			{popup ? (
				<OverlayTrigger trigger={["hover", "focus"]} placement="auto" overlay={renderPopover}>
					<img src={skillSrc} alt={skillName} className="skill-container" draggable={false} />
				</OverlayTrigger>
			) : (
				<img src={skillSrc} alt={skillName} className="skill-container" draggable={false} />
			)}
		</div>
	);
};

export default AscensionSkillImage;

import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useGameEngine } from "./hooks/useGameEngine";
import GameView from "./views/GameView";
import BackgroundMusic from "./components/BackgroundMusic";

const App: React.FC = () => {
	useGameEngine();

	return (
		<>
			<ToastContainer />
			<BackgroundMusic />
			<GameView />
		</>
	);
};

export default App;

import { faCoins } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { shopData } from "../../../data/shopData";
import { getShopItemPurchases } from "../../../engine/gameUtils/ShopUtils";
import IShopEntry from "../../../types/IShopEntry";
import { PurchaseModal } from "./PurchaseModal";
import "./index.css";

const ShopTab = () => {
	const { t } = useTranslation();

	const [isModalVisible, setIsModalVisible] = useState(false);
	const [selectedItem, setSelectedItem] = useState<IShopEntry | null>(null);

	const renderPurchaseTracker = (maxPurchases: number, currentPurchases: number) => {
		return Array.from({ length: maxPurchases }, (_, index) => (
			<div key={index} className={`tracker-square ${index < currentPurchases ? "purchased" : ""}`} />
		));
	};

	const handleOpenModal = (item: IShopEntry) => {
		setSelectedItem(item);
		setIsModalVisible(true);
	};

	return (
		<>
			<Card className="shop-tab">
				<CardHeader>
					<CardTitle>{t("tabs.shop")}</CardTitle>
				</CardHeader>
				<CardBody>
					<Row>
						{Object.values(shopData).map((item) => (
							<Col key={item.id} sm={12} md={6} lg={4}>
								<Card className="mb-4">
									<CardHeader>
										{t(`shop.items.${item.identifier}.name`)}</CardHeader>
									<CardBody>
										<p>{t(`shop.items.${item.identifier}.description`)}</p>
										{item.maxPurchases >= 1 && (
											<div className="purchase-tracker">
												{renderPurchaseTracker(item.maxPurchases, getShopItemPurchases(item.id))}
											</div>
										)}
										<Button variant="primary" onClick={() => handleOpenModal(item)}>
											<FontAwesomeIcon icon={faCoins} /> {t("shop.buy")} for {item.price} €
										</Button>
									</CardBody>
								</Card>
							</Col>
						))}
					</Row>
				</CardBody>
			</Card>
			{selectedItem && (
				<PurchaseModal
					show={isModalVisible}
					onHide={() => setIsModalVisible(false)}
					item={selectedItem as IShopEntry}
				/>
			)}
		</>
	);
};

export default ShopTab;

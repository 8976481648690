import BigNumber from "bignumber.js";
import IMissionReward, { EMissionRewardTypes } from "../types/IMissionReward";
import { store } from "../redux/store";
import { addCredits, addItems, addRefinery } from "../redux/slices/gameSlice";
import { toast } from "react-toastify";
import i18n from "../i18n/i18n";
import { formatNumber } from "./gameUtils";

export default class MissionReward {
    public reward: IMissionReward;

    constructor(missonReward: IMissionReward) {
        this.reward = missonReward;
    }

    activate(): void {
        switch(this.reward.type) {
            case EMissionRewardTypes.CURRENCY:
                let value : BigNumber = this.reward.value()

                store.dispatch(addCredits(value.toString()));
                toast.success(i18n.t("mission.rewardedCredits", {amount: formatNumber(value)}));

                break;
            case EMissionRewardTypes.ITEM:
                let itemId = this.reward.identifier;
                let amount = this.reward.value();

                if(itemId === undefined){
                    throw new Error("Missing reward item id");
                }

                store.dispatch(addItems({ itemId: parseInt(itemId), amount: amount.toString() }));
                toast.success(i18n.t("mission.rewardedItems", {amount: formatNumber(amount), item: i18n.t("items." + itemId + ".name")}));

                break;
            case EMissionRewardTypes.REFINERY:
                store.dispatch(addRefinery());
                toast.success(i18n.t("mission.rewardedRefinery"));

                break;
            default:
                throw new Error("Unknown reward type");
        }
    }
}
import { store } from "../../redux/store";
import EBonuses from "../../types/EBonuses";
import IMiner from "../../types/IMiner";
import IOre from "../../types/IOre";
import Ore from "../Ore";
import Planet from "../Planet";
import { getMinerById } from "../gameUtils/MinerUtils";

class GameMiningManager {
    private static instance: GameMiningManager;

    private constructor() {}
  
    public static getInstance(): GameMiningManager {
      if (!GameMiningManager.instance) {
        GameMiningManager.instance = new GameMiningManager();
      }
      return GameMiningManager.instance;
    }

    public processEvents() {
        this.processMiningTick(); 
    }

    private getMiners() : IMiner[] {
        const  { miner } = store.getState().game;

        let miners : IMiner[] = [];

        for(const m of miner) {
            if(getMinerById(m.id).bonusType === EBonuses.AUTO_DAMAGE_ADDITION){
                miners.push(m);
            }
        }

        return miners
    }

    private processMiningTick() {
        const miners = this.getMiners();

        for(const miner of miners) {
            const ore : IOre | null = new Planet().getFirstOreAvailable();

            if(ore === null){
                continue;
            }

            const oreInstance = new Ore(ore);
            oreInstance.autoDamageAction(miner);
        }
    }
}

export default GameMiningManager;
import { toast } from "react-toastify";
import config from "../config";
import { planetData } from "../data/planetData";
import i18n from "../i18n/i18n";
import { setCurrentDepth, setGrid, travelToPlanet, updateLastGridUpdate } from "../redux/slices/gameSlice";
import { store } from "../redux/store";
import { EOreTypes } from "../types/EOreTypes";
import IOre from "../types/IOre";
import { PlanetType } from "../types/PlanetType";
import { calculateEffectivVolume } from "../Sound";
import { EConfig } from "../types/EConfig";
import Ore from "./Ore";
import GameEventManager from "./GameEventManager";
import GameEvent from "../types/GameEvent";
import { handleGenerateNewGridAction } from "./gameActions";
import { assetsBasePath } from "../utils/assetsUtils";

export default class Planet{
    id: number;

    constructor(id: number | undefined = undefined){
        if(id === undefined){
            this.id = store.getState().game.planet.id;
            return
        }

        this.id = id;
    }

    travel(){
        if(!this.canTravel()){
            toast.error(i18n.t("travel.requirements.notMet"));
            return;
        }

        store.dispatch(travelToPlanet(this.getInterface()));
        
        toast.success(i18n.t("travel.success", {planetName: i18n.t(`planet.${this.getInterface().identifier}.name`)}));
        GameEventManager.getInstance().registerEvent(new GameEvent(() => true , () => {handleGenerateNewGridAction()}, 50, false ));
    }

    createMiningMap(){
        store.dispatch(updateLastGridUpdate())

        const { size_x, size_y, ores } = this.getInterface();

        if(this.isBossWave()){
            return this.createBossMiningMap();
        }

        return this.createNormalMiningMap();
    }
    
    createNormalMiningMap(){
        const { size_y, size_x, ores } = this.getInterface();
        const map: IOre[][] = [];

        for (let y = 0; y < size_y; y++) {
            const row: IOre[] = [];
            for (let x = 0; x < size_x; x++) {
                const ore = Ore.selectOreBasedOnRarity(ores);
                row.push({ oreType: ore.getOreType(), x, y, currentHp: ore.getMaxHealth().toString() });
            }
            map.push(row);
        }

        return map;
    }

    createBossMiningMap(){
        const { ores } = this.getInterface();
        const oreDisplayed = new Ore(ores[0]);
        const oreHp = new Ore(ores[ores.length - 1]);

        const maxHp = oreHp.getMaxHealth().toString();

        return [[{ oreType: oreDisplayed.getOreType(), x: 0, y: 0, currentHp: maxHp }]];
    }

    getFirstOreAvailable(): IOre | null{
        const { size_y, size_x } = store.getState().game.planet;
        const { grid }  = store.getState().game;

        try {
            for (let y = 0; y < size_y; y++) {
                for (let x = 0; x < size_x; x++) {
                    if (grid[y][x] && grid[y][x].oreType !== EOreTypes.Empty) {
                        return grid[y][x];
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
        
        return null;
    }

    getCurrentDepth(){
        return store.getState().game.currentDepth;
    }

    getMaxDepth(){
        return store.getState().game.maxDepth;
    }

    getImage(){
        return `${assetsBasePath}/planet/${this.getInterface().identifier}.webp`;
    }

    getInterface(): PlanetType{
        return planetData.find(planet => planet.id === this.id) as PlanetType;
    }

    canTravel(){
        const currentPlanet = new Planet();

        if (this.id === currentPlanet.id) return false;
        return this.getInterface().requiredPlanets.every((rp) => Planet.getUnlockedPlanets().includes(rp));
    }

    isBossWave(){
        return this.getCurrentDepth() % 10 === 0;
    }

    static getBossTimeoutSound(){
        return new Howl({
            src: ["/sounds/bossWaveTimeout.wav"],
            volume: calculateEffectivVolume(EConfig.EFFECTS_VOLUME)
        })
    }
    
    static getGrid(){
        return store.getState().game.grid;
    
    }

    static countRemainingOres(): number{
        var val = 0;

        for(const row of Planet.getGrid()){
            for(const ore of row){
                if(ore.oreType !== EOreTypes.Empty){
                    val++;
                }
            }
        }

        return val;
    }
    
    static getUnlockedPlanets(){
        return store.getState().game.completedPlanets;
    }

    static checkBossTimer(){
        if (!new Planet().isBossWave() || new Planet().getCurrentDepth() !== new Planet().getMaxDepth()) return;
        const { lastGridUpdate } = store.getState().game;
        const currentTime = Date.now();
        const diff = currentTime - lastGridUpdate;

        if (diff > config.game.bossTimer * 1000) {
            store.dispatch(setCurrentDepth(store.getState().game.currentDepth - 1))
            store.dispatch(setGrid(new Planet().createMiningMap()))
            this.getBossTimeoutSound().play();
            toast.error(i18n.t("bossWave.timeout"));
        }
    }

    static count(){
        return planetData.length;
    }
}
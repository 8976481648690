import BigNumber from "bignumber.js";
import IAscensionSkill from "../types/IAscensionSkill";
import { EAscensionSkill } from "../types/EAscensionSkill";
import EBonuses from "../types/EBonuses";

export const ascensionSkillData: Record<EAscensionSkill, IAscensionSkill> = {
	[EAscensionSkill.MINERS_CASH]: {
		cost: 1,
		costIncrease: (level: BigNumber) => level,
		type: EAscensionSkill.MINERS_CASH,
		bonusType: EBonuses.MINE_REWARD_MULTIPLIER,
		bonusAmount: 0.25,
		bonusIncrease: (level: BigNumber) => level,
		identifier: "miners_cash",
	},
	[EAscensionSkill.MINERS_PICKAXE]: {
		cost: 1,
		costIncrease: (level: BigNumber) => level,
		type: EAscensionSkill.MINERS_PICKAXE,
		bonusType: EBonuses.CLICK_DAMAGE_ADDITION,
		bonusAmount: 25,
		bonusIncrease: (level: BigNumber) => level,
		identifier: "miners_pickaxe",
	},
	[EAscensionSkill.MINERS_TECHNOLOGY]: {
		cost: 1,
		costIncrease: (level: BigNumber) => BigNumber(2).pow(level),
		type: EAscensionSkill.MINERS_TECHNOLOGY,
		bonusType: EBonuses.AUTO_DAMAGE_USER_MULTIPLIER,
		bonusAmount: 0.5,
		bonusIncrease: (level: BigNumber) => level,
		identifier: "miners_technology",
	},
	[EAscensionSkill.MINERS_LUCK]: {
		cost: 1,
		costIncrease: (level: BigNumber) => BigNumber(2).pow(level),
		type: EAscensionSkill.MINERS_LUCK,
		bonusType: EBonuses.RARITY_BONUS,
		bonusAmount: 10,
		bonusIncrease: (level: BigNumber) => level,
		identifier: "miners_luck",
	},
	[EAscensionSkill.MINERS_EFFICIENCY]: {
		cost: 5,
		costIncrease: (level: BigNumber) => BigNumber(1.5).pow(level),
		type: EAscensionSkill.MINERS_EFFICIENCY,
		bonusType: EBonuses.UPGRADE_COST_REDUCTION,
		bonusAmount: 0.05, // 5% Kostensenkung initial
		bonusIncrease: (level: BigNumber) => new BigNumber(0.01).times(level),
		identifier: "miners_efficiency",
	},
	[EAscensionSkill.DEEP_CORE_MINING]: {
		cost: 3,
		costIncrease: (level: BigNumber) => level.plus(2),
		type: EAscensionSkill.DEEP_CORE_MINING,
		bonusType: EBonuses.AUTO_DAMAGE_ADDITION,
		bonusAmount: 10,
		bonusIncrease: (level: BigNumber) => new BigNumber(5).times(level),
		identifier: "deep_core_mining",
	},
	[EAscensionSkill.CRITICAL_ENGINEERING]: {
		cost: 2,
		costIncrease: (level: BigNumber) => BigNumber(2).pow(level),
		type: EAscensionSkill.CRITICAL_ENGINEERING,
		bonusType: EBonuses.CRITICAL_HIT_DAMAGE,
		bonusAmount: 0.1,
		bonusIncrease: (level: BigNumber) => new BigNumber(0.05).times(level), // +5% zusätzlicher kritischer Schaden pro Level
		identifier: "critical_engineering",
	},
	[EAscensionSkill.STELLAR_NAVIGATION]: {
		cost: 3,
		costIncrease: (level: BigNumber) => BigNumber(2).pow(level),
		type: EAscensionSkill.STELLAR_NAVIGATION,
		bonusType: EBonuses.ASCENDING_TO_DEPTH,
		bonusAmount: 1,
		bonusIncrease: (level: BigNumber) => new BigNumber(1).times(level),
		identifier: "stellar_navigation",
	},
	[EAscensionSkill.QUANTUM_SCANNERS]: {
		cost: 1,
		costIncrease: (level: BigNumber) => BigNumber(1.25).pow(level),
		type: EAscensionSkill.QUANTUM_SCANNERS,
		bonusType: EBonuses.CRATE_SPAWN_RATE_MULTIPLIER,
		bonusAmount: 0.1, // 10% höhere Chance auf Kisten initial
		bonusIncrease: (level: BigNumber) => new BigNumber(0.02).times(level), // 2% zusätzliche Chance pro Level
		identifier: "quantum_scanners",
	},
};

export enum EConfig {
    AUTO_PROCEED_LEVEL,
    DRAGGED_ITEM,
    SOUND_ENABLED,
    SOUND_VOLUME,
    MUSIC_ENABLED,
    EFFECTS_VOLUME,
    CLICK_VOLUME,
    CLICK_VOLUME_OVERRIDE,
    ORE_DESTROY_VOLUME,
    ORE_DESTROY_VOLUME_OVERRIDE,
    SOUND_VOLUME_OVERRIDE,
    EFFECTS_VOLUME_OVERRIDE,
    MUSIC_VOLUME,
    MUSIC_VOLUME_OVERRIDE,
    UPGRADE_VOLUME,
    UPGRADE_VOLUME_OVERRIDE
}
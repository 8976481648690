import BigNumber from "bignumber.js";
import { Button, Card, CardHeader, Col, ProgressBar, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { researchData } from "../../data/researchData";
import { formatNumber, getResourceCount } from "../../engine/gameUtils";
import { EResearchTypes } from "../../types/EResearchTypes";
import BonusInfo from "../BonusInfo";

import { oresData } from "../../data/oresData";
import "./ResearchTab.css";
import Research from "../../engine/Research";
import ResearchType from "../../types/ResearchType";

interface ResearchTabProps {
	amount: BigNumber;
	handleAmountChange: () => void;
}

const ResearchTab: React.FC<ResearchTabProps> = ({ amount, handleAmountChange }) => {
	const { t } = useTranslation();

	const handleStartResearch = (researchType: EResearchTypes) => {
		const researchInstance = new Research(researchType);
		researchInstance.buy(amount);
	};

	const renderResearchItem = (researchItem: ResearchType) => {
		const research = new Research(researchItem.type);

		return (
			<Card key={researchItem.id} className="mb-3">
				<Card.Body>
					<Card.Title>
						<Row>
							<Col md={10} sm={8}>
								{t(`research.${researchItem.type}.title`)}
							</Col>
							<Col md={2} sm={4} className="float-right">
								{formatNumber(research.getLevel()) + "x"}
							</Col>
						</Row>
					</Card.Title>
					<BonusInfo
						type={researchItem.bonusType}
						baseValue={researchItem.bonusValue}
						level={research.getLevel()}
						upgradeAmount={amount}
					/>
					{researchItem.costs.map((cost) => (
						<div key={cost.oreType}>
							<strong>{t(`ore.${oresData[cost.oreType].identifier}.name`)}</strong>
							<ProgressBar
								className={`ore-` + cost.oreType}
								now={BigNumber(getResourceCount(cost.oreType))
									.dividedBy(research.calculateCost(cost, amount))
									.multipliedBy(100)
									.toNumber()}
							/>
							<small>{`${formatNumber(getResourceCount(cost.oreType))} / ${formatNumber(
								research.calculateCost(cost, amount)
							)}`}</small>
						</div>
					))}
					<Button
						variant="primary"
						onClick={() => handleStartResearch(researchItem.type)}
						disabled={!research.isAffordable(amount)}
					>
						{t("research.start")}
					</Button>
				</Card.Body>
			</Card>
		);
	};

	return (
		<Card className="research-tab">
			<CardHeader>
				<Card.Title className="tab-title">
					<Row>
						<Col md={10} sm={8}>
							{t("tabs.research")}
						</Col>
						<Col md={2} sm={4}>
							<Button variant="primary" onClick={handleAmountChange} className="float-right">
								{amount.toString()}x
							</Button>
						</Col>
					</Row>
				</Card.Title>
			</CardHeader>
			<Card.Body className="research-container">
				{Object.values(researchData).map((researchItem) => renderResearchItem(researchItem))}
			</Card.Body>
		</Card>
	);
};

export default ResearchTab;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BigNumber from "bignumber.js";
import React from "react";
import { OverlayTrigger, Popover, PopoverProps } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { formatNumber, getFontAwesomeIconForType, isBonusTypePercentage, toRoman } from "../../engine/gameUtils";

import { JSX } from "react/jsx-runtime";
import { itemData } from "../../data/itemData";
import { calculateItemBonusValue } from "../../engine/gameUtils/ItemUtils";
import IBonus from "../../types/IBonus";
import { EItemRarity } from "../../types/EItemRarity";
import { EItemType } from "../../types/EItemType";
import { ItemEntry } from "../../types/ItemEntry";
import "./index.css";

interface ItemIconComponentProps {
	itemId: number;
	count?: BigNumber;
	item?: ItemEntry;
	showTooltip?: boolean;
	setOnDragEnd?: React.Dispatch<React.SetStateAction<boolean>>;
}

const rarityColors = {
	[EItemRarity.Common]: "#9d9d9d", // Grau
	[EItemRarity.Uncommon]: "#1eff00", // Grün
	[EItemRarity.Rare]: "#0070ff", // Blau
	[EItemRarity.Epic]: "#a335ee", // Lila
	[EItemRarity.Legendary]: "#ff8000", // Orange
};

const ItemIconComponent: React.FC<ItemIconComponentProps> = ({
	itemId,
	count: quantity = BigNumber(1),
	item = undefined,
	showTooltip = true,
	setOnDragEnd = null,
}) => {
	const { t } = useTranslation();

	const renderItemBonus = (bonus: IBonus) => {
		return (
			<div key={bonus.type}>
				<FontAwesomeIcon icon={getFontAwesomeIconForType(bonus.type)}></FontAwesomeIcon>{" "}
				{t(`bonuses.${bonus.type}.name`)}:{" "}
				{formatNumber(
					calculateItemBonusValue(bonus.value, BigNumber(item?.level || 1)),
					2,
					isBonusTypePercentage(bonus.type)
				)}
			</div>
		);
	};

	const displayValue = () => {
		if (item && item.level && BigNumber(item.level).gt(1)) {
			return <span className="item-count">{toRoman(parseInt(item.level))}</span>;
		} else if (quantity && quantity.gt(1)) {
			return <span className="item-count">{formatNumber(quantity, 0)}</span>;
		}
		return null;
	};

	const renderTooltip = (props: JSX.IntrinsicAttributes & PopoverProps & React.RefAttributes<HTMLDivElement>) => {
		if (item?.itemID === undefined) return <div></div>;

		return (
			<Popover id={`popover-item-${itemId}`} {...props}>
				<Popover.Header as="h3" style={{ backgroundColor: rarityColors[itemData[itemId].rarity] }}>
					{t(`items.${itemId}.name`)}
				</Popover.Header>
				<Popover.Body>
					{/*<div>{quantity.gt(1) && t('general.quantity', { quantity: formatNumber(quantity) })}</div>*/}
					{itemData[item.itemID].bonusList && (itemData[item.itemID].bonusList ?? []).map(renderItemBonus)}
					{item?.bonusList && item.bonusList.map(renderItemBonus)}
					{/*item?.level && BigNumber(item.level).gt(1) && <div>{t('general.upgrades', { level: item.level })}</div>*/}
					{item.bonusList &&
						itemData[itemId] &&
						itemData[itemId].type !== EItemType.POTION &&
						itemData[itemId].type !== EItemType.PRODUCT &&
						item.bonusList.length < (itemData[itemId].randomBonuses ?? 0) && (
							<div>{t("items.moreBonusesAvailable")}</div>
						)}
					<div className="item_description">{t(`items.${itemId}.description`)}</div>
				</Popover.Body>
			</Popover>
		);
	};

	if (!showTooltip) {
		return (
			<div className="item-icon-container">
				<img className="icon-img" src={`/assets/item/${itemId}.webp`} alt={`Item ${itemId}`} />
				{displayValue()}
			</div>
		);
	}

	return (
		<OverlayTrigger trigger={["hover", "focus"]} placement="auto" overlay={renderTooltip}>
			<div className="item-icon-container" onDragEndCapture={() => {setOnDragEnd && setOnDragEnd(true)}} onDragEnd={() => {setOnDragEnd && setOnDragEnd(true)}}>
				<img className="icon-img" src={`/assets/item/${itemId}.webp`} alt={`Item ${itemId}`} />
				{displayValue()}
			</div>
		</OverlayTrigger>
	);
};

export default ItemIconComponent;

import BigNumber from "bignumber.js";
import React from "react";
import { ProgressBar } from "react-bootstrap";

interface LevelProgressBarProps {
	currentLevel: BigNumber;
	nextUpgradeLevel: BigNumber;
}

const LevelProgressBar: React.FC<LevelProgressBarProps> = ({ currentLevel, nextUpgradeLevel }) => {
	let progress = 100;
	let label = `${currentLevel}`;

	if (nextUpgradeLevel) {
		progress = Math.min(100, currentLevel.dividedBy(nextUpgradeLevel).multipliedBy(100).toNumber());
		label += ` / ${nextUpgradeLevel}`;
	}

	if (currentLevel.isEqualTo(0)) {
		progress = 0;
	}

	return (
		<>
			<ProgressBar now={progress} label={label} />
			<br />
		</>
	);
};

export default LevelProgressBar;

export enum EResearchTypes {
    Efficiency,
    Tradespost,
    CriticalResourceDetection,
    EnergyEfficiencyOptimization,
    StarlightRefining,
    SpaceTimeDilation,
    DeepCoreTech,
    AsteroidMining,
    QuantumAnalysis
}

import { ascensionSkillData } from "../data/ascensionSkillData";
import { oresData } from "../data/oresData";
import { EAscensionSkill } from "../types/EAscensionSkill";
import { EOreTypes } from "../types/EOreTypes";
import { EPowerPlantType } from "../types/EPowerPlantType";
import { PlanetType } from "../types/PlanetType";

export const assetsBasePath = "./assets";

export enum ImageSize {
    Small = "small",
    Medium = "medium",
    Large = "large",
    Original = ""
}

export const getImageByItem = ( itemId: number, size: ImageSize = ImageSize.Original ) => {
    return `${assetsBasePath}/item/${itemId}.webp`;
    //return `${assetsBasePath}/item/${itemId}${size !== ImageSize.Original ? "_" + size : ""}.webp`;
}


import { faShoppingCart } from "@fortawesome/free-solid-svg-icons"; // Beispiel-Icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BigNumber from "bignumber.js";
import { useEffect } from "react";
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { minerData } from "../../data/minerData";
import { handleBuyMinerAction } from "../../engine/gameActions";
import { formatNumber, getFontAwesomeIconForType, isBonusTypePercentage } from "../../engine/gameUtils";
import { calculateUpgradeCost, getOwnedMinerById, userHasMiner } from "../../engine/gameUtils/MinerUtils";
import { RootState } from "../../redux/store";
import IMinerUpgrade from "../../types/IMinerUpgrade";
import LevelProgressBar from "../LevelProgressBar";
import MinerDamageInfo from "../MinerDamageInfo";
import { MinerUpgradeBar } from "../MinerUpgradeBar";

import "./MinersTab.css";

interface MinersTabProps {
	amount: BigNumber;
	handleAmountChange: () => void;
}

const MinersTab: React.FC<MinersTabProps> = ({ amount, handleAmountChange }) => {
	const { miner, credits } = useSelector((state: RootState) => state.game);
	const { t } = useTranslation();

	useEffect(() => {}, [miner]);

	const handleBuy = (minerId: number, amount: BigNumber) => {
		handleBuyMinerAction(minerId, amount);
	};

	const renderSkillBonus = (upgrade: IMinerUpgrade) => {
		return (
			<Row key={upgrade.id}>
				<Col sm={1} md={1} xl={1}>
					<FontAwesomeIcon icon={getFontAwesomeIconForType(upgrade.bonusType)} />
				</Col>
				<Col sm={11} md={8} xl={8}>
					{t(`bonuses.${upgrade.bonusType}.name`)}
				</Col>
				<Col sm={12} md={3} xl={3}>
					{formatNumber(upgrade.bonusValue, 2, isBonusTypePercentage(upgrade.bonusType))}
				</Col>
			</Row>
		);
	};

	return (
		<Card className="miners-tab full-y full-x">
			<CardHeader>
				<div className="tab-title">
					<Row>
						<Col md={10} sm={8}>
							<CardTitle>
								{t("tabs.miners")}
							</CardTitle>
						</Col>
						<Col md={2} sm={4}>
							<Button variant="primary" onClick={handleAmountChange} className="float-right">
								{amount.toString()}x
							</Button>
						</Col>
					</Row>
				</div>
			</CardHeader>
			<CardBody className="miner-container">
				{minerData.map((current_miner, index) => (
					<Card key={index} className="my-3">
						<CardHeader>
							<Card.Title>{t("miner.entry." + current_miner.id + ".name")}</Card.Title>
						</CardHeader>
						<Card.Body>
							<Row>
								<Col xl={4} md={3} sm={2}>
									<Card.Img variant="top" src={`./assets/miners/${current_miner.id}/main.webp`} />
								</Col>
								<Col xl={8} md={9} sm={10}>
									<LevelProgressBar
										currentLevel={BigNumber(getOwnedMinerById(current_miner.id)?.level || 0)}
										nextUpgradeLevel={
											current_miner.upgrades[getOwnedMinerById(current_miner.id)?.upgradeIDs?.length]?.minLevel
										}
									/>
									<MinerUpgradeBar miner={current_miner} />
									<br />
									{miner.find(x => x.id === current_miner.id)?.upgradeIDs.map((id) => (
										<div key={current_miner.id + "_" + id}>{renderSkillBonus(current_miner.upgrades.find(x => x.id === id) as IMinerUpgrade)}</div>
									))}
									<hr />
									<Row>
										<Col xl={9} md={9} sm={8}>
											<MinerDamageInfo miner={getOwnedMinerById(current_miner.id)} levelups={amount} />
										</Col>
										<Col xl={3} md={3} sm={4}>
											<Button
												variant="primary"
												disabled={BigNumber(credits).isLessThan(
													calculateUpgradeCost(
														current_miner.basePrice,
														userHasMiner(current_miner.id)
															? BigNumber(getOwnedMinerById(current_miner.id)?.level as string)
															: BigNumber(0),
														amount,
														current_miner.factors.price
													)
												)}
												className={"float-right"}
												onClick={() => handleBuy(current_miner.id, amount)}
											>
												<FontAwesomeIcon icon={faShoppingCart} />{" "}
												{formatNumber(
													calculateUpgradeCost(
														current_miner.basePrice,
														userHasMiner(current_miner.id)
															? BigNumber(getOwnedMinerById(current_miner.id)?.level as string)
															: BigNumber(0),
														amount,
														current_miner.factors.price
													)
												)}
											</Button>
										</Col>
									</Row>
								</Col>
							</Row>
						</Card.Body>
					</Card>
				))}
			</CardBody>
		</Card>
	);
};

export default MinersTab;

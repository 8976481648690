import BigNumber from "bignumber.js";
import { researchData } from "../data/researchData";
import { EResearchTypes } from "../types/EResearchTypes";
import IResearch from "../types/IResearch";
import ResearchType from "../types/ResearchType";
import { store } from "../redux/store";
import { t } from "i18next";
import IOreCost from "../types/IOreCost";
import Ore from "./Ore";
import EBonuses from "../types/EBonuses";
import { addResearchLevel, addResources } from "../redux/slices/gameSlice";
import GameEventManager from "./GameEventManager";
import GameEvent from "../types/GameEvent";
import { toast } from "react-toastify";
import { calculateEffectivVolume } from "../Sound";
import { EConfig } from "../types/EConfig";
import { updateBonus } from "./gameState";

export default class Research{
    type: EResearchTypes

    constructor(type: EResearchTypes){
        this.type = type;
    }

    buy(amount: BigNumber){
        const research = researchData[this.type];
        const currentResearchLevel = this.getLevel();

        if(!this.isAffordable(amount)){
            toast.error(t("research.notAffordable"))
            return;
        }

        for(const cost of research.costs){
            store.dispatch(addResources({ oreType: cost.oreType, amount: this.calculateCost(cost, amount).negated().toString()}));
        }

        store.dispatch(addResearchLevel({ researchType: this.type, amount: amount.toString() }));
        GameEventManager.getInstance().registerEvent(new GameEvent(() => true, () => updateBonus(research.bonusType), 0, false));
        this.getUpgradeHowl().play();
    }

    getUpgradeHowl(){
        return new Howl({
            src: ["/sounds/researchUpgrade.ogg"],
            volume: calculateEffectivVolume(EConfig.UPGRADE_VOLUME)
        
        })
    }

    public getType(): EResearchTypes{
        return this.type;
    }

    public getInterface(): ResearchType {
        return researchData[this.type];
    }

    public getLevel(): BigNumber {
        const val = store.getState().game.research.find((research: IResearch) => research.type === this.type)?.level;

        if(val === undefined){
            return new BigNumber(0);
        }

        return new BigNumber(val);
    }

    public getCosts(): IOreCost[] {
        return this.getInterface().costs;
    }

    public getBonusValue(level: BigNumber|null = null): BigNumber {
        if(level === null){
            level = this.getLevel();
        }
    
        return level.times(this.getInterface().bonusValue);
    }

    public isAffordable(amount: BigNumber): boolean {
        return this.getCosts().every(cost => {
            const availableAmount = (new Ore(cost.oreType)).getCount();
            return availableAmount !== undefined && availableAmount.gte(this.calculateCost(cost, amount));
        });
    }

    public calculateCost(cost: IOreCost, amount: BigNumber): BigNumber {    
        var price = new BigNumber(0);

        for(let i = 0; i < amount.toNumber(); i++){
            price = price.plus(cost.value.times(cost.logarithmicFactor.pow(this.getLevel().plus(i).toNumber())));
        }

        return price;
    }


    static getBonusValue(baseValue: BigNumber, level: BigNumber): BigNumber {
        return baseValue.times(level);
    }

    static getTotalBonusValueOfResearches(type: EBonuses): BigNumber {
        const researchStat = store.getState().game.research;
        var totalBonus = new BigNumber(0);

        Object.keys(researchStat).forEach(researchType => {
            const research = new Research(parseInt(researchType));

            if(research.getInterface().bonusType !== type){
                return;
            }

            totalBonus = totalBonus.plus(research.getBonusValue());
        });

        return totalBonus;
    }
}


import { Howl } from 'howler';
import { EConfig } from './types/EConfig';
import { t } from 'i18next';
import { store } from './redux/store';

export const craftSounds = [
    './sounds/craft1.wav',
    './sounds/craft2.wav',
    './sounds/craft3.wav',
];

export const ClickSound = new Howl({
    src: ['./sounds/dig1.mp3']
});

export const MineSound = new Howl({
    src: ['./sounds/mine.mp3']
});

export const playRandomCraftSound = () => {
    const soundIndex = Math.floor(Math.random() * craftSounds.length);
    const soundSrc = craftSounds[soundIndex];

    const sound = new Howl({
        src: [soundSrc]
    });

    sound.play();
};

export const calculateEffectivVolume = (type: EConfig) => {
    const config = store.getState().game.config;
    
    switch(type){
        case EConfig.ORE_DESTROY_VOLUME:
            if(config[EConfig.ORE_DESTROY_VOLUME_OVERRIDE])
                return config[EConfig.ORE_DESTROY_VOLUME];
            if(config[EConfig.EFFECTS_VOLUME_OVERRIDE])
                return config[EConfig.EFFECTS_VOLUME];
            if(config[EConfig.SOUND_ENABLED])
                return config[EConfig.SOUND_VOLUME];
            return 0;

        case EConfig.CLICK_VOLUME:
            if(config[EConfig.CLICK_VOLUME_OVERRIDE])
                return config[EConfig.CLICK_VOLUME];
            if(config[EConfig.EFFECTS_VOLUME_OVERRIDE])
                return config[EConfig.EFFECTS_VOLUME];
            if(config[EConfig.SOUND_ENABLED])
                return config[EConfig.SOUND_VOLUME];
            return 0;

        case EConfig.UPGRADE_VOLUME:
            if(config[EConfig.UPGRADE_VOLUME_OVERRIDE])
                return config[EConfig.UPGRADE_VOLUME];
            if(config[EConfig.EFFECTS_VOLUME_OVERRIDE])
                return config[EConfig.EFFECTS_VOLUME];
            if(config[EConfig.SOUND_ENABLED])
                return config[EConfig.SOUND_VOLUME];
            return 0;

        case EConfig.MUSIC_VOLUME:
            if(config[EConfig.MUSIC_VOLUME_OVERRIDE])
                return config[EConfig.MUSIC_VOLUME];
            if(config[EConfig.MUSIC_ENABLED])
                return config[EConfig.SOUND_VOLUME];
            return 0;

        case EConfig.EFFECTS_VOLUME:
            if(config[EConfig.EFFECTS_VOLUME_OVERRIDE])
                return config[EConfig.EFFECTS_VOLUME];
            if(config[EConfig.SOUND_ENABLED])
                return config[EConfig.SOUND_VOLUME];
            return 0;

        default:
            throw new Error(t("sound.error.unknownType"));
    }
}
import IBlueprint from "../types/IBlueprint";
import { EOreTypes } from "../types/EOreTypes";
/*
    Empty = 0,
    Sand,
    Stone,
    Iron,
    Copper,
    Gold,
    Diamond,
    MysticQuartz,
    GalacticStone,
*/
export const blueprintData: Record<number, IBlueprint> = {
    1: {
        id: 1,
        identifier: "Glass",
        input: [
            {
                oreType: EOreTypes.Sand,
                quantity: 2
            }
        ],
        output: [
            {
                itemId: 1000,
                quantity: 1
            }
        ],
        productionTime: 2
    },
    2: {
        id: 2,
        identifier: "Stone",
        input: [
            {
                oreType: EOreTypes.Rock,
                quantity: 1
            }
        ],
        output: [
            {
                itemId: 1001,
                quantity: 2
            }
        ],
        productionTime: 2
    },
    3: {
        id: 3,
        identifier: "Iron_Bar",
        input: [
            {
                oreType: EOreTypes.Iron,
                quantity: 2
            }
        ],
        output: [
            {
                itemId: 1002,
                quantity: 1
            }
        ],
        productionTime: 4
    },
    4: {
        id: 4,
        identifier: "Copper_Bar",
        input: [
            {
                oreType: EOreTypes.Copper,
                quantity: 2
            }
        ],
        output: [
            {
                itemId: 1003,
                quantity: 1
            }
        ],
        productionTime: 4
    },
    5: {
        id: 5,
        identifier: "Gold_Bar",
        input: [
            {
                oreType: EOreTypes.Gold,
                quantity: 2
            }
        ],
        output: [
            {
                itemId: 1004,
                quantity: 1
            }
        ],
        productionTime: 6
    },
    6: {
        id: 6,
        identifier: "Diamond",
        input: [
            {
                oreType: EOreTypes.Graphite,
                quantity: 2
            }
        ],
        output: [
            {
                itemId: 1005,
                quantity: 1
            }
        ],
        productionTime: 8
    },
    7: {
        id: 7,
        identifier: "Mystic_Quartz",
        input: [
            {
                oreType: EOreTypes.MysticQuartz,
                quantity: 2
            }
        ],
        output: [
            {
                itemId: 1006,
                quantity: 1
            }
        ],
        productionTime: 15
    },
    8: {
        id: 8,
        identifier: "Galactic_Stone",
        input: [
            {
                oreType: EOreTypes.GalacticStone,
                quantity: 2
            }
        ],
        output: [
            {
                itemId: 1007,
                quantity: 1
            }
        ],
        productionTime: 20
    },

    100: {
        id: 100,
        identifier: "Steel_Bar",
        input: [
            {
                oreType: EOreTypes.Coal,
                quantity: 2
            },
            {
                itemId: 1002,
                quantity: 1
            }
        ],
        output: [
            {
                itemId: 1100,
                quantity: 1
            }
        ],
        productionTime: 2
    },
}
import BigNumber from "bignumber.js";
import { store } from "../../redux/store";
import EBonuses from "../../types/EBonuses";

export const getTotalBonusValueOfBoosts = (bonus: EBonuses): BigNumber => {
    const state = store.getState().game;

    const activeBoosts = state.boosts.filter(boost => boost.endTime > Date.now());

    return activeBoosts
        .filter(boost => boost.type === bonus)
        .reduce((acc, boost) => acc.plus(boost.amount), new BigNumber(0));
}
import BigNumber from "bignumber.js";
import { toast } from "react-toastify";
import i18n from "../i18n/i18n";
import {
	addCredits,
	loadGameState,
	removeBoost,
	setUserBonus,
} from "../redux/slices/gameSlice";
import { store } from "../redux/store";
import IBoost from "../types/IBoost";
import { EAscensionSkill } from "../types/EAscensionSkill";
import EBonuses from "../types/EBonuses";
import { EConfig } from "../types/EConfig";
import { EItemType } from "../types/EItemType";
import { EOreTypes } from "../types/EOreTypes";
import GameEvent from "../types/GameEvent";
import { ItemEntry } from "../types/ItemEntry";
import IMiner from "../types/IMiner";
import IOre from "../types/IOre";
import { PlanetType } from "../types/PlanetType";
import RefineryState from "../types/RefineryState";
import IResearch from "../types/IResearch";
import GameEventManager from "./GameEventManager";
import { calculateTotalPlayerClickDamage, formatNumber } from "./gameUtils";
import { getTotalBonusValueOfBoosts } from "./gameUtils/BoostUtils";
import { getTotalBonusValueOfEquipment } from "./gameUtils/ItemUtils";
import { getTotalBonusValueOfShop } from "./gameUtils/ShopUtils";
import { decrypt, encrypt } from "../utils/cryptUtils";
import config from "../config";
import { loadUserState, updateLastPlayed } from "../redux/slices/userSlice";
import { oresData } from "../data/oresData";
import IMissionStats from "../types/IMissionStats";
import { EPowerPlantType } from "../types/EPowerPlantType";
import IPowerPlant from "../types/IPowerPlant";
import Refinery from "./Refinery";
import AscensionSkill from "./AscensionSkill";
import Ore from "./Ore";
import Research from "./Research";

const stateDefaults: Record<EBonuses, string> = {
	[EBonuses.NONE]: "0",
	[EBonuses.RARITY_BONUS]: "1",
	[EBonuses.MINE_REWARD_MULTIPLIER]: "0",
	[EBonuses.CLICK_BASE_DMG]: "1",
	[EBonuses.AUTO_DAMAGE_USER_MULTIPLIER]: "1",
	[EBonuses.ALL_DAMAGE_USER_MULTIPLIER]: "1",
	[EBonuses.CLICK_DAMAGE_USER_MULTIPLIER]: "1",
	[EBonuses.CLICK_DAMAGE_CALC]: "0",
	[EBonuses.AUTO_DAMAGE_CALC]: "0",
	[EBonuses.CLICK_DAMAGE_ADDITION]: "0",
	[EBonuses.CLICK_DAMAGE_SELF_MULTIPLIER]: "0",
	[EBonuses.AUTO_DAMAGE_SELF_MULTIPLIER]: "0",
	[EBonuses.AUTO_DAMAGE_ADDITION]: "0",
	[EBonuses.CRATE_SPAWN_RATE_MULTIPLIER]: "1",
	[EBonuses.AUTO_CLICKS_PER_SECOND]: "1",
	[EBonuses.AFK_MINE_REWARD_MULTIPLIER]: "0.25",
	[EBonuses.CRITICAL_HIT_CHANCE]: "0",
	[EBonuses.CRITICAL_HIT_DAMAGE]: "1",
	[EBonuses.REDUCE_ORE_HP]: "1",
	[EBonuses.ASCENDING_TO_DEPTH]: "1",
	[EBonuses.REFINERY_SPEED]: "1",
	[EBonuses.UPGRADE_COST_REDUCTION]: "1",
	[EBonuses.CRAFTING_COST_REDUCTION]: "1",
};

export interface GameState {
	currentOrePosition: { x: number; y: number };
	credits: string;
	upgrades: Record<string, boolean>;
	miner: IMiner[];
	resources: Record<EOreTypes, string>;
	userBonuses: Record<EBonuses, string>;
	research: IResearch[];
	planet: PlanetType;
	completedPlanets: number[];
	grid: IOre[][];
	boosts: IBoost[];
	maxDepth: number;
	currentDepth: number;
	config: Record<EConfig, any>;
	ascension: number;
	ascensionPoints: string;
	ascensionSkills: Record<EAscensionSkill, string>;

	refineries: Record<number, RefineryState>;
	items: Record<number, ItemEntry>;

	equipment: {
		[EItemType.ARMOR]: ItemEntry | null;
		[EItemType.BOOTS]: ItemEntry | null;
		[EItemType.GLOVES]: ItemEntry | null;
		[EItemType.HELMET]: ItemEntry | null;
		[EItemType.PICKAXE]: ItemEntry | null;
		[EItemType.RELIC]: [ItemEntry | null, ItemEntry | null];
	};

	shopPurchases: Record<number, number>;

	missions: IMissionStats;
	powerPlants: Record<EPowerPlantType, IPowerPlant>;
	lastGridUpdate: number;

	enabledCodes: string[];
}

export function getUserBonus(bonus: EBonuses): BigNumber {
	const currentState = store.getState();

	if (currentState.game === undefined)
		throw new Error("Game state is not defined");

	if (currentState.game.userBonuses[bonus] === undefined) updateBonuses();

	return BigNumber(currentState.game.userBonuses[bonus]);
}

export function calculateTotalBonus(bonus: EBonuses): BigNumber {
	var bonusValue = BigNumber(stateDefaults[bonus]);

	bonusValue = bonusValue.plus(Research.getTotalBonusValueOfResearches(bonus));
	bonusValue = bonusValue.plus(getTotalBonusValueOfBoosts(bonus));
	bonusValue = bonusValue.plus(AscensionSkill.getTotalBonusValueOfAscension(bonus));
	bonusValue = bonusValue.plus(getTotalBonusValueOfEquipment(bonus));
	bonusValue = bonusValue.plus(getTotalBonusValueOfShop(bonus));

	return bonusValue;
}

export function updateBonus(bonus: EBonuses): void {
	switch (bonus) {
		case EBonuses.ALL_DAMAGE_USER_MULTIPLIER:
			store.dispatch(
				setUserBonus({ bonus, value: calculateTotalBonus(bonus).toString() })
			);
			updateBonus(EBonuses.CLICK_DAMAGE_CALC);
			//updateBonus(EBonuses.AUTO_DAMAGE_CALC);
			break;

		case EBonuses.CLICK_DAMAGE_CALC:
			store.dispatch(
				setUserBonus({
					bonus,
					value: calculateTotalPlayerClickDamage().toString(),
				})
			);
			break;
		case EBonuses.CLICK_DAMAGE_ADDITION:
		case EBonuses.CLICK_DAMAGE_USER_MULTIPLIER:
			store.dispatch(
				setUserBonus({ bonus, value: calculateTotalBonus(bonus).toString() })
			);
			updateBonus(EBonuses.CLICK_DAMAGE_CALC);
			break;
		case EBonuses.CLICK_DAMAGE_SELF_MULTIPLIER:
			updateBonus(EBonuses.CLICK_DAMAGE_CALC);
			break;
		case EBonuses.CLICK_BASE_DMG:
			store.dispatch(
				setUserBonus({ bonus, value: calculateTotalBonus(bonus).toString() })
			);
			updateBonus(EBonuses.CLICK_DAMAGE_CALC);
			break;
		case EBonuses.RARITY_BONUS:
		case EBonuses.AUTO_CLICKS_PER_SECOND:
		case EBonuses.CRATE_SPAWN_RATE_MULTIPLIER:
		case EBonuses.AUTO_DAMAGE_USER_MULTIPLIER:
		case EBonuses.AFK_MINE_REWARD_MULTIPLIER:
		case EBonuses.MINE_REWARD_MULTIPLIER:
		case EBonuses.CRITICAL_HIT_CHANCE:
		case EBonuses.CRITICAL_HIT_DAMAGE:
		case EBonuses.REDUCE_ORE_HP:
		case EBonuses.UPGRADE_COST_REDUCTION:
		case EBonuses.CRAFTING_COST_REDUCTION:
		case EBonuses.REFINERY_SPEED:
			store.dispatch(
				setUserBonus({ bonus, value: calculateTotalBonus(bonus).toString() })
			);
			break;

		case EBonuses.AUTO_DAMAGE_SELF_MULTIPLIER:
			break;

		default:
			throw new Error(`Bonus ${bonus} not implemented`);
	}
}

export function updateBonuses() {
	for (const key in stateDefaults) {
		if (Object.prototype.hasOwnProperty.call(stateDefaults, key)) {
			const element = stateDefaults[key as EBonuses];
			store.dispatch(setUserBonus({ bonus: key as EBonuses, value: element }));
		}
	}

	GameEventManager.getInstance().registerEvent(
		new GameEvent(
			() => true,
			() => updateBonus(EBonuses.ALL_DAMAGE_USER_MULTIPLIER),
			100,
			false
		)
	);
	GameEventManager.getInstance().registerEvent(
		new GameEvent(
			() => true,
			() => updateBonus(EBonuses.CLICK_DAMAGE_ADDITION),
			100,
			false
		)
	);
	GameEventManager.getInstance().registerEvent(
		new GameEvent(
			() => true,
			() => updateBonus(EBonuses.CLICK_DAMAGE_USER_MULTIPLIER),
			100,
			false
		)
	);
	GameEventManager.getInstance().registerEvent(
		new GameEvent(
			() => true,
			() => updateBonus(EBonuses.AUTO_CLICKS_PER_SECOND),
			100,
			false
		)
	);
	GameEventManager.getInstance().registerEvent(
		new GameEvent(
			() => true,
			() => updateBonus(EBonuses.AUTO_DAMAGE_USER_MULTIPLIER),
			100,
			false
		)
	);
	GameEventManager.getInstance().registerEvent(
		new GameEvent(
			() => true,
			() => updateBonus(EBonuses.CRAFTING_COST_REDUCTION),
			100,
			false
		)
	);
	GameEventManager.getInstance().registerEvent(
		new GameEvent(
			() => true,
			() => updateBonus(EBonuses.REFINERY_SPEED),
			100,
			false
		)
	);
	GameEventManager.getInstance().registerEvent(
		new GameEvent(
			() => true,
			() => updateBonus(EBonuses.UPGRADE_COST_REDUCTION),
			100,
			false
		)
	);
	GameEventManager.getInstance().registerEvent(
		new GameEvent(
			() => true,
			() => updateBonus(EBonuses.MINE_REWARD_MULTIPLIER),
			100,
			false
		)
	);
	GameEventManager.getInstance().registerEvent(
		new GameEvent(
			() => true,
			() => updateBonus(EBonuses.RARITY_BONUS),
			100,
			false
		)
	);
	GameEventManager.getInstance().registerEvent(
		new GameEvent(
			() => true,
			() => updateBonus(EBonuses.CRITICAL_HIT_CHANCE),
			100,
			false
		)
	);
	GameEventManager.getInstance().registerEvent(
		new GameEvent(
			() => true,
			() => updateBonus(EBonuses.CRITICAL_HIT_DAMAGE),
			100,
			false
		)
	);
	GameEventManager.getInstance().registerEvent(
		new GameEvent(
			() => true,
			() => updateBonus(EBonuses.REDUCE_ORE_HP),
			100,
			false
		)
	);
	GameEventManager.getInstance().registerEvent(
		new GameEvent(
			() => true,
			() => updateBonus(EBonuses.AFK_MINE_REWARD_MULTIPLIER),
			100,
			false
		)
	);
}

export function updateGameState() {
	const state = store.getState().game;

	for (const boost of state.boosts) {
		if (boost.endTime < Date.now()) {
			const toastText = i18n.t("toasts.bonus.ended", {
				name: i18n.t(`bonuses.${boost.type}.name`),
				value: boost.amount,
			});
			toast.warn(toastText);
			store.dispatch(removeBoost(boost));
			updateBonus(boost.type);
		}
	}

	Object.values(state.refineries).forEach((refinery) => {
		const refineryObject = new Refinery(refinery);

		refineryObject.refine();
	});
}

export async function exportSaveGame() {
	const state = store.getState();
	const state_json = JSON.stringify(state);

	const encrypted_state = await encrypt(
		state_json,
		config.game.saveEncryptionKey
	);

	const blob = new Blob([encrypted_state], { type: "text/plain" });
	const url = window.URL.createObjectURL(blob);

	const link = document.createElement("a");
	link.href = url;
	link.download = "galactic_miner.save";

	document.body.appendChild(link);
	link.click();

	document.body.removeChild(link);
	window.URL.revokeObjectURL(url);
}

export async function importSaveGame(encrypted_state: Uint8Array) {
	const state_json = await decrypt(
		encrypted_state,
		config.game.saveEncryptionKey
	);

	const state = JSON.parse(state_json);

	if (state.game === undefined) throw new Error("Invalid save file");
	if (state.user === undefined) throw new Error("Invalid save file");

	store.dispatch(loadGameState(state.game));
	store.dispatch(loadUserState(state.user));

	setInterval(() => window.location.reload(), 10);
}

export function performAFKRewards() {
	const { lastPlayed } = store.getState().user;
	const { planet } = store.getState().game;

	const timeDifference = Date.now() - lastPlayed;

	if(timeDifference < 1000 * 30)
		return;

	const creditsGain = (new Ore(planet.ores[0])).getCreditGainOverTime(timeDifference / 1000);

	store.dispatch(updateLastPlayed());
	store.dispatch(addCredits(creditsGain.toString()));
	toast.success(i18n.t("afk.addCredits", { value: formatNumber(creditsGain) }));
}

import { GameState } from "../../engine/gameState";
import { EItemType } from "../../types/EItemType";
import { ItemEntry } from "../../types/ItemEntry";
import { EquipmentSlot } from "../EquipmentSlot";

import "./index.css";

interface EquipmentSlotsProps {
	equipment: GameState["equipment"];
	onEquip: (item: ItemEntry, type: EItemType, index?: number) => void;
	onUnequip: (type: EItemType, index?: number) => void;
}

export const EquipmentSlots: React.FC<EquipmentSlotsProps> = ({ equipment, onEquip, onUnequip }) => {
	return (
		<div className="equipment-layout">
			<div className="equipment-row helmet-row">
				<EquipmentSlot
					type={EItemType.HELMET}
					item={equipment[EItemType.HELMET]}
					onEquip={onEquip}
					onUnequip={onUnequip}
				/>
			</div>
			<div className="equipment-row main-row">
				<EquipmentSlot
					type={EItemType.PICKAXE}
					item={equipment[EItemType.PICKAXE]}
					onEquip={onEquip}
					onUnequip={onUnequip}
				/>
				<EquipmentSlot
					type={EItemType.ARMOR}
					item={equipment[EItemType.ARMOR]}
					onEquip={onEquip}
					onUnequip={onUnequip}
				/>
				<EquipmentSlot
					type={EItemType.GLOVES}
					item={equipment[EItemType.GLOVES]}
					onEquip={onEquip}
					onUnequip={onUnequip}
				/>
			</div>
			<div className="equipment-row relic-row">
				{equipment[EItemType.RELIC].map((relic, index) => (
					<EquipmentSlot type={EItemType.RELIC} key={index} index={index} item={relic} onEquip={onEquip} onUnequip={onUnequip} />
				))}
			</div>
			<div className="equipment-row boots-row">
				<EquipmentSlot
					type={EItemType.BOOTS}
					item={equipment[EItemType.BOOTS]}
					onEquip={onEquip}
					onUnequip={onUnequip}
				/>
			</div>
		</div>
	);
};

import BigNumber from "bignumber.js";
import { toast } from "react-toastify";
import i18n from "../../i18n/i18n";
import { addBoost } from "../../redux/slices/gameSlice";
import { store } from "../../redux/store";
import EBonuses from "../../types/EBonuses";
import { updateBonus } from "../gameState";

export const applyBoost = (type: EBonuses, amount: BigNumber, seconds: BigNumber) => {
    store.dispatch(addBoost({
        type: type,
        amount: amount.toString(),
        endTime: Date.now() + seconds.toNumber() * 1000
    }));

    const toastText = i18n.t("toasts.bonus.applied", { name: i18n.t(`bonuses.${type}.name`), value: amount, duration: seconds})
    toast.info(toastText);

    updateBonus(type);
}
import BigNumber from "bignumber.js";
import { toast } from "react-toastify";
import { craftingRecipesData } from "../../data/craftingRecipesData";
import i18n from "../../i18n/i18n";
import { addItems } from "../../redux/slices/gameSlice";
import { store } from "../../redux/store";
import { calculateCraftingMultiplier } from "../gameUtils/CraftingUtils";
import { getItemCountById } from "../gameUtils/ItemUtils";

import { playRandomCraftSound } from "../../Sound";

export const PerformCraftAction = (recipeId: number, level: number) => {
    var requirementsMeet : boolean = true;

    craftingRecipesData[recipeId].input.forEach((inputItem) => {
        if(getItemCountById(inputItem.itemId).lt(BigNumber(inputItem.quantity).times(calculateCraftingMultiplier(level)))) {
            toast.error(i18n.t("crafting.notEnoughItems", { itemName: i18n.t(`items.${inputItem.itemId}.name`) }));
            requirementsMeet = false;
            return false;
        }
    });

    if(!requirementsMeet) return;
    
    craftingRecipesData[recipeId].input.forEach((inputItem) => {
        store.dispatch(addItems({ itemId: inputItem.itemId, amount: BigNumber(inputItem.quantity).times(calculateCraftingMultiplier(level)).negated().toString() }));
    })

    store.dispatch(addItems({ itemId: craftingRecipesData[recipeId].output, amount: "1", level: level }));
    toast.success(i18n.t("crafting.success", { itemName: i18n.t(`items.${craftingRecipesData[recipeId].output}.name`) }));
    
    playRandomCraftSound();
}   
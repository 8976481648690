import BigNumber from "bignumber.js";
import { store } from "../redux/store";
import { addItems, addResources, incRefineryLevel, setProductionLastRefined } from "../redux/slices/gameSlice";
import RefineryState from "../types/RefineryState";
import { blueprintData } from "../data/blueprintData";
import { toast } from "react-toastify";
import i18n from "../i18n/i18n";
import { ItemEntry } from "../types/ItemEntry";
import { getItemCountById } from "./gameUtils/ItemUtils";
import config from "../config";
import { EPowerPlantType } from "../types/EPowerPlantType";
import PowerPlant from "./PowerPlant";
import Ore from "./Ore";

export const getCollectedRefineryCount = () : number => {
    const totalRefineries = Object.keys(store.getState().game.refineries).length;

    var boughtRefineries = 0;

    if(Object.keys(store.getState().game.shopPurchases).includes("3"))
        boughtRefineries = store.getState().game.shopPurchases["3"];

    return totalRefineries - boughtRefineries;
}

export default class Refinery {
    id: number;
    level: BigNumber;
    lastRefined: number;
    selectedBlueprintId: number;
    items: Array<[ItemEntry]>;

    constructor(refinery: RefineryState) {
        this.id = refinery.id;

        const refStore = store.getState().game.refineries[this.id];

        if(refStore === undefined) {
            this.lastRefined = refinery.lastRefined;
            this.selectedBlueprintId = refinery.selectedBlueprintId;
            this.level = BigNumber(refinery.level);
            this.items = refinery.items;
        }
        else {
            if(refStore.items !== refinery.items){
                throw new Error("Refinery items mismatch");
            }
            if(refStore.level !== refinery.level){
                throw new Error("Refinery level mismatch");
            }
            if(refStore.lastRefined !== refinery.lastRefined){
                throw new Error("Refinery last refined mismatch");
            }
            if(refStore.selectedBlueprintId !== refinery.selectedBlueprintId){
                throw new Error("Refinery selected blueprint mismatch");
            }

            this.items = store.getState().game.refineries[this.id].items;
            this.level = BigNumber(store.getState().game.refineries[this.id].level);
            this.lastRefined = store.getState().game.refineries[this.id].lastRefined;
            this.selectedBlueprintId = store.getState().game.refineries[this.id].selectedBlueprintId;
        }
    }

    getBlueprint() {
        if (this.selectedBlueprintId === undefined) return null;
        
        return blueprintData[this.selectedBlueprintId];
    }

    calculateUpgradeCost(): BigNumber {
        var price = BigNumber(1);

        for(let i = 1; i <= this.level.toNumber(); i++) {
            price = price.plus(this.level);
        }
    
        return price;
    }

    calculateProductionMultiplier(): BigNumber {
        const coalPowerPlant = new PowerPlant({type: EPowerPlantType.COAL});
        const nuclearPowerPlant = new PowerPlant({type: EPowerPlantType.NUCLEAR});

        var productionMultiplier = new BigNumber(1);

        if(coalPowerPlant.isActive) {
            productionMultiplier = productionMultiplier.plus(coalPowerPlant.calculateProductionMultiplier());
        }
    
        if(nuclearPowerPlant.isActive) {
            productionMultiplier = productionMultiplier.plus(nuclearPowerPlant.calculateProductionMultiplier());
        }

        return productionMultiplier;    
    }

    calculateSpeedMultiplier(): BigNumber {
        return BigNumber(2).pow(this.level);
    }

    calculateProductionTime(): number {
        const blueprint = this.getBlueprint();

        if(blueprint === undefined || blueprint === null) return 0;

        const productionSpeed = this.calculateSpeedMultiplier();

        return blueprint.productionTime / productionSpeed.toNumber();
    }

    calculateNextFinishTime(): number {
        return this.lastRefined + this.calculateProductionTime();
    }

    upgrade() {
        const price = this.calculateUpgradeCost();
       
        if (!this.canUpgrade()) {
            toast.error(i18n.t("refinery.upgradeNotEnoughResources"));
            return;
        }

        store.dispatch(addItems({ itemId: 2000, amount: (-price).toString() }));
        store.dispatch(incRefineryLevel({ refineryId: this.id, amount: "1" }));
        store.dispatch(setProductionLastRefined({ refineryId: this.id, startTime: Date.now() }));

        toast.success(i18n.t("refinery.upgradeSuccess", { level: this.level, cost: price.toString() }));
    }

    canUpgrade(): boolean {
        const price = this.calculateUpgradeCost();
        const soulOfMinerCount = getItemCountById(config.itemIds.soulOfMiner);

        return price.lte(soulOfMinerCount);
    }

    canRefine(): boolean {
        const blueprint = this.getBlueprint();
        const productionMultiplier = this.calculateProductionMultiplier();

        if (!blueprint) return false;

        let canRefine = true;

        Object.values(blueprint.input).forEach((input) => {
            if(input.oreType !== undefined) {
                const oreInstance = new Ore(input.oreType);

                if(oreInstance.getCount().lt(productionMultiplier.times(input.quantity))) {
                    canRefine = false;
                    return;
                }
            } else if (input.itemId !== undefined) {
                const count = getItemCountById(input.itemId);
                if(count.lt(productionMultiplier.times(input.quantity))) {
                    canRefine = false;
                    return;
                }
            } else {
                throw new Error("Invalid input type");
            }
        });

        return true;
    }

    refine() {
        const blueprint = this.getBlueprint();
        
        if(blueprint === null || blueprint === undefined) {
            return;
        }        

        const coalPowerPlant = new PowerPlant({type: EPowerPlantType.COAL})
        const nuclearPowerPlant = new PowerPlant({type: EPowerPlantType.NUCLEAR})

        if(coalPowerPlant.isActive) {
            if(!coalPowerPlant.hasEnoughResources()) {
                coalPowerPlant.deactivate();
                toast.error(i18n.t("powerPlant.notEnoughResources"));
            }
        }

        if(nuclearPowerPlant.isActive) {
            if(!nuclearPowerPlant.hasEnoughResources()) {
                nuclearPowerPlant.deactivate();
                toast.error(i18n.t("powerPlant.notEnoughResources"));
            }
        }
        
        const finishTime = this.calculateNextFinishTime();
        const productionMultiplier = this.calculateProductionMultiplier();

        if(finishTime > Date.now()) {
            return;
        }

        if (!this.canRefine()) {
            toast.error(i18n.t("refinery.cannotRefine"));
            return;
        }

        store.dispatch(setProductionLastRefined({ refineryId: this.id, startTime: finishTime }));

        Object.values(blueprint.input).forEach((input) => {
            if(input.oreType !== undefined) {
                store.dispatch(addResources({ oreType: input.oreType, amount: (productionMultiplier.times(input.quantity).negated()).toString() }));
            } else if (input.itemId !== undefined) {
                store.dispatch(addItems({ itemId: input.itemId, amount: (productionMultiplier.times(input.quantity).negated()).toString() }));
            } else {
                throw new Error("Invalid input type");
            }
        });

        Object.values(blueprint.output).forEach((output) => {
            store.dispatch(addItems({ itemId: output.itemId, amount: productionMultiplier.times(output.quantity).toString() }));
        });

        if(coalPowerPlant.isActive) {
            coalPowerPlant.consumeResources();
        }

        if(nuclearPowerPlant.isActive) {
            nuclearPowerPlant.consumeResources();
        }
    }
}
import BigNumber from "bignumber.js";
import { minerData } from "../../data/minerData";
import { store } from "../../redux/store";
import EBonuses from "../../types/EBonuses";
import IMiner from "../../types/IMiner";
import IMinerType from "../../types/IMinerType";
import { getUserBonus } from "../gameState";

export function calculateUpgradeCost(basePrice: BigNumber, startLevel: BigNumber, amount: BigNumber, priceExponentialFactor: BigNumber): BigNumber {
    // Berechne den Preis für das erste Upgrade
    const firstUpgradeCost = basePrice.times(priceExponentialFactor.pow(startLevel.minus(1)));
    const costReduction = getUserBonus(EBonuses.UPGRADE_COST_REDUCTION);

    if (amount.isEqualTo(1)) {
      // Wenn nur ein Upgrade gekauft wird, ist dies der Preis
      return firstUpgradeCost.dividedBy(costReduction);
    }
    
    // Verwende die Formel für die Summe einer geometrischen Reihe, wenn r != 1
    if (!priceExponentialFactor.eq(1)) {
      const totalCost = firstUpgradeCost
        .times(
          new BigNumber(1)
            .minus(priceExponentialFactor.pow(amount))
        )
        .div(
          new BigNumber(1).minus(priceExponentialFactor)
        );
      return totalCost.dividedBy(costReduction);
    } else {
      // Wenn r = 1, degeneriert die Formel zu einer einfachen Multiplikation
      return basePrice.times(amount).dividedBy(costReduction);
    }
}

export const calculateMinerAutoDamage = (miner: IMiner): BigNumber => {
    const type = getMinerById(miner.id);
  
    if(type.bonusType !== EBonuses.AUTO_DAMAGE_ADDITION)
      return new BigNumber(0);
  
    const baseDmg = type.bonusValue.times(type.factors.bonusValue.pow(BigNumber(miner.level)));
  
    var upgradeDmgAddition = BigNumber(0);
    var upgradeDmgMultiplier = BigNumber(1);
  
    var playerDmgMultiplier = BigNumber(1).plus(getUserBonus(EBonuses.AUTO_DAMAGE_USER_MULTIPLIER)).plus(getUserBonus(EBonuses.ALL_DAMAGE_USER_MULTIPLIER));

    for (let i = 0; i < miner.upgradeIDs.length; i++) {
      const upgrade = type.upgrades.find(upgrade => upgrade.id === miner.upgradeIDs[i]);

      if(!upgrade){
        console.error(`Upgrade with id ${miner.upgradeIDs[i]} not found for miner ${miner.id}`);
        continue;
      }

      switch(upgrade.bonusType){
        case EBonuses.AUTO_DAMAGE_ADDITION:
          upgradeDmgAddition = upgradeDmgAddition.plus(upgrade.bonusValue);
          break;
        case EBonuses.AUTO_DAMAGE_SELF_MULTIPLIER:
          upgradeDmgMultiplier = upgradeDmgMultiplier.plus(upgrade.bonusValue);
          break;
      }
    }
  
    return baseDmg.plus(upgradeDmgAddition).times(upgradeDmgMultiplier).times(playerDmgMultiplier);
}

export const calculateMinerClickDamage = (miner: IMiner): BigNumber => {
    const type = getMinerById(miner.id);
  
    if(type.bonusType !== EBonuses.CLICK_DAMAGE_ADDITION)
      return new BigNumber(0);
  
    const baseDmg = type.bonusValue.times(type.factors.bonusValue.pow(BigNumber(miner.level)));
  
    var upgradeDmgAddition = BigNumber(0);
    var upgradeDmgMultiplier = BigNumber(1);
  
    var playerDmgMultiplier = BigNumber(1).plus(getUserBonus(EBonuses.CLICK_DAMAGE_USER_MULTIPLIER)).plus(getUserBonus(EBonuses.ALL_DAMAGE_USER_MULTIPLIER));
  
    for (let i = 0; i < miner.upgradeIDs.length; i++) {
      const upgrade = type.upgrades[miner.upgradeIDs[i]];
      switch(upgrade.bonusType){
        case EBonuses.CLICK_DAMAGE_ADDITION:
          upgradeDmgAddition = upgradeDmgAddition.plus(upgrade.bonusValue);
          break;
        case EBonuses.CLICK_DAMAGE_SELF_MULTIPLIER:
          upgradeDmgMultiplier = upgradeDmgMultiplier.plus(upgrade.bonusValue);
          break;
      }
    }
  
    return baseDmg.plus(upgradeDmgAddition).times(upgradeDmgMultiplier).times(playerDmgMultiplier);
}
  
export const userHasMiner = (id: number): boolean => {
    const currentState = store.getState();
    return currentState.game.miner.some(miner => miner.id === id);
}

export const getMinerById = (id: number): IMinerType => {
    return minerData.find(miner => miner.id === id) as IMinerType;
}

export const getOwnedMinerById = (id: number): IMiner => {
    const currentState = store.getState();
    return currentState.game.miner.find(miner => miner.id === id) as IMiner;
}
import BigNumber from "bignumber.js";
import { useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Dropdown, ListGroup, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { itemData } from "../../data/itemData";
import { handleEquipItemAction, handleUnequipItemAction } from "../../engine/gameActions/ItemActions";
import { RootState } from "../../redux/store";
import { EItemType } from "../../types/EItemType";
import { ItemEntry } from "../../types/ItemEntry";
import { EquipmentSlots } from "../EquipmentSlots";
import { InventorySlot } from "../InventorySlot/InventorySlot";

import "./InventoryTab.css";

enum SortType {
	NONE = "none",
	ID = "id",
	NAME = "name",
	TYPE = "type",
	RARITY = "rarity",
	LEVEL = "level",
}

const InventoryTab = () => {
	const { t } = useTranslation();

	const items = useSelector((state: RootState) => state.game.items);
	const equipment = useSelector((state: RootState) => state.game.equipment);

	const [filterType, setFilterType] = useState(null as EItemType | null);
	const [sortType, setSortType] = useState(SortType.ID);


	const handleEquipItem = (item: ItemEntry, type: EItemType, index?: number) => {
		handleEquipItemAction(item, type, index);
	};

	const handleUneqiupItem = (type: EItemType, index?: number) => {
		handleUnequipItemAction(type, index);
	};

	// Filter and sort items based on selected criteria
	var filteredItems: ItemEntry[] = [];

	Object.values(items).map((item) => {
		if (filterType === null || itemData[item.itemID].type === filterType) {
			filteredItems.push(item);
		}
		return null;
	});

	switch (sortType) {
		case SortType.ID:
			filteredItems = filteredItems.sort((a, b) => a.itemID - b.itemID);
			break;
		case SortType.NAME:
			filteredItems = filteredItems.sort((a, b) =>
				t(`item.${a.itemID}.name`).localeCompare(t(`item.${b.itemID}.name`))
			);
			break;
		case SortType.TYPE:
			filteredItems = filteredItems.sort((a, b) => itemData[a.itemID].type - itemData[b.itemID].type);
			break;
		case SortType.RARITY:
			filteredItems = filteredItems.sort((a, b) => itemData[a.itemID].rarity - itemData[b.itemID].rarity);
			break;
		case SortType.LEVEL:
			filteredItems = filteredItems.sort((a, b) => BigNumber(a.count).minus(b.count).toNumber());
			break;
		default:
			break;
	}

	return (
		<Card className="full-y full-x inventory-tab">
			<CardHeader>
				<CardTitle>
					{t("tabs.inventory")}
				</CardTitle>
			</CardHeader>
			<CardBody>
				<Row>
					<Col sm={12} md={6} xl={5}>
						<EquipmentSlots onEquip={handleEquipItem} onUnequip={handleUneqiupItem} equipment={equipment} />
					</Col>
					<Col sm={12} md={6} xl={7}>
						<div className="">
							<Card>
								<CardHeader>
									<div className="inventory-controls">
										<Dropdown>
											<Dropdown.Toggle variant="primary" id="dropdown-basic">
												{t("inventory.filter.label")}
											</Dropdown.Toggle>
											<Dropdown.Menu>
												<Dropdown.Item onClick={() => setFilterType(null)}>{t("inventory.filter.none")}</Dropdown.Item>
												<Dropdown.Divider />
												{[
													EItemType.PICKAXE,
													EItemType.HELMET,
													EItemType.ARMOR,
													EItemType.BOOTS,
													EItemType.GLOVES,
													EItemType.RELIC,
													EItemType.POTION,
													EItemType.PRODUCT,
												].map((type, index) => (
													<Dropdown.Item key={"ifv:" + type} onClick={() => setFilterType(index)}>
														{t(`item.type.${EItemType[type]}.name`)}
													</Dropdown.Item>
												))}
											</Dropdown.Menu>
										</Dropdown>
										&nbsp;
										<Dropdown>
											<Dropdown.Toggle variant="primary" id="dropdown-basic">
												{t("inventory.sort.label")}
											</Dropdown.Toggle>
											<Dropdown.Menu>
												<Dropdown.Item onClick={() => setSortType(SortType.ID)}>{t("inventory.sort.id")}</Dropdown.Item>
												<Dropdown.Item onClick={() => setSortType(SortType.NAME)}>
													{t("inventory.sort.name")}
												</Dropdown.Item>
												<Dropdown.Item onClick={() => setSortType(SortType.TYPE)}>
													{t("inventory.sort.type")}
												</Dropdown.Item>
												<Dropdown.Item onClick={() => setSortType(SortType.RARITY)}>
													{t("inventory.sort.rarity")}
												</Dropdown.Item>
												<Dropdown.Item onClick={() => setSortType(SortType.LEVEL)}>
													{t("inventory.sort.level")}
												</Dropdown.Item>
											</Dropdown.Menu>
										</Dropdown>
									</div>
								</CardHeader>
								<CardBody className="inventory-items">
									<ListGroup>
										{filteredItems.map((item) => (
											<div key={item.id}>
												<InventorySlot item={item} />
											</div>
										))}
									</ListGroup>
								</CardBody>
							</Card>
						</div>
					</Col>
				</Row>
			</CardBody>
		</Card>
	);
};

export default InventoryTab;

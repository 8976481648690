import React, { useState } from 'react';
import { Row, Form, Button, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Code } from '../../engine/Code';

const CodeInputComponent = () => {
    const { t } = useTranslation();
    const [code, setCode] = useState('');

    const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCode(event.target.value);
    };

    const handleSubmit = (event: React.ChangeEvent<HTMLFormElement>) => {
        event.preventDefault();
        
        if(code == '')
        {
            toast.error(t('codes.noCode'));
            return;
        }

        const codeInstance = new Code(code);

        if(codeInstance.activate()){
            toast.success(t('codes.activated', { code: code }));
            setCode('');
        }
    };

    return (
        <Row>
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="codeInput">
                    <Form.Label>{t("codes.enterCode")}</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={t("codes.placeholder")}
                        value={code}
                        onChange={handleCodeChange}
                    />
                </Form.Group>
                <Button variant="primary" type="submit">
                    {t("codes.validate")}
                </Button>
            </Form>
        </Row>
    );
};

export default CodeInputComponent;

import { gameInitialState } from "../../../redux/slices/gameSlice";
import { RootState } from "../../../redux/store";

export default function migrate_1_to_2(state: RootState) {
    const newState = { 
        ...state,
        user: { ...state.user },
        game: { ...state.game, lastGridUpdate: gameInitialState.lastGridUpdate }
    };
    
    return newState;
}
import BigNumber from "bignumber.js";
import { getCollectedRefineryCount } from "../engine/Refinery";
import { ICode } from "../types/ICode";
import { EMissionRewardTypes } from "../types/IMissionReward";

export const codesData: Record<string, ICode> = {
    "HAPPYLAUNCH": {
        "code": "HAPPYLAUNCH",
        "available": () => {
            return getCollectedRefineryCount() <= 3;
        },

        "expires": (new Date('2024-06-30T00:00:00Z')).getTime(),

        "rewards": [
            {
                "type": EMissionRewardTypes.REFINERY,
                "value": () => BigNumber(1)
            },
            {
                "type": EMissionRewardTypes.ITEM,
                "identifier": "104",
                "value": () => BigNumber(1),
            }
        ]
    }
}
import { toast } from "react-toastify";
import { oresData } from "../../data/oresData";
import { shopData } from "../../data/shopData";
import i18n from "../../i18n/i18n";
import { addCredits, addItems, addRefinery, addShopPurchaseEntry } from "../../redux/slices/gameSlice";
import { store } from "../../redux/store";
import { EOreTypes } from "../../types/EOreTypes";
import { EShopEntryType } from "../../types/EShopEntryType";
import { formatNumber } from "../gameUtils";
import { getShopItemPurchases } from "../gameUtils/ShopUtils";
import Ore from "../Ore";

export const confirmBuyShopItemAction = (shopItemId: number) => {
    const shopItem = shopData[shopItemId];

    if (!shopItem) {
        toast.error(i18n.t("shop.itemNotFound"));
    }

    if (shopItem.maxPurchases > 0 && getShopItemPurchases(shopItemId) >= shopItem.maxPurchases) {
        toast.error(i18n.t("shop.maxPurchasesReached"));
        return;
    }

    switch (shopItem.type) {
        case EShopEntryType.PERM_BOOST:
            store.dispatch(addShopPurchaseEntry(shopItemId))
            toast.success(i18n.t("shop.addedPermBoost"));
            break;
        case EShopEntryType.CREDIT_MINUTES:
            const referenceOre = new Ore(EOreTypes.Gold);
            const creditsGain = referenceOre.getCreditGainOverTime(shopItem.data.value);

            store.dispatch(addCredits(creditsGain.toString()));
            toast.success(i18n.t("shop.addCredits", { value: formatNumber(creditsGain) }));
            break;
        case EShopEntryType.REFINERY:
            store.dispatch(addShopPurchaseEntry(shopItemId))
            store.dispatch(addRefinery())
            toast.success(i18n.t("shop.addedRefinery"));
            break;
        case EShopEntryType.ITEM:
            store.dispatch(addShopPurchaseEntry(shopItemId))
            store.dispatch(addItems({itemId: shopItem.data.type as number, amount: shopItem.data.value.toString()}))
            toast.success(i18n.t("shop.addedItems"))
            break;
    }
}
import BigNumber from "bignumber.js";
import { EOreTypes } from "../types/EOreTypes";
import { store } from "../redux/store";
import { calculateDamagePerSecond } from "./gameUtils";
import { oresData } from "../data/oresData";
import { OreType } from "../types/OreType";
import { getUserBonus } from "./gameState";
import EBonuses from "../types/EBonuses";
import config from "../config";
import IOre from "../types/IOre";
import IMiner from "../types/IMiner";
import { calculateMinerAutoDamage } from "./gameUtils/MinerUtils";
import { addCredits, addResources, setCurrentDepth, setMaxDepth, setOreHp, setOreType } from "../redux/slices/gameSlice";
import { handleActionOpenCrate } from "./gameActions/CrateActions";
import { cratesData } from "../data/cratesData";
import { handleChangeMaxDepthAction, handleDestoryOreAction } from "./gameActions/MissionActions";
import { ImageSize, assetsBasePath } from "../utils/assetsUtils";
import GameEventManager from "./GameEventManager";
import GameEvent from "../types/GameEvent";
import { handleGenerateNewGridAction } from "./gameActions";
import { EConfig } from "../types/EConfig";
import { calculateEffectivVolume } from "../Sound";
import Planet from "./Planet";

export interface OreProbabilities {
	[key: number]: number;
}

export default class Ore {
	type: EOreTypes;
	interface?: IOre;

	constructor(ore: EOreTypes | IOre) {
		if (typeof ore === "object") {
			this.type = ore.oreType;
			this.interface = ore;
		} else {
			this.type = ore;
		}
	}

	damage(damage: BigNumber) {
		if(!this.interface)
			throw new Error("Ore has no interface");

		var newHp = BigNumber(this.getCurrentHealth()).minus(damage);

		if(newHp.isLessThan(0))
			newHp = new BigNumber(0);

		//this.interface.currentHp = newHp.toString();
		store.dispatch(setOreHp({ x: this.getInterface().x, y: this.getInterface().y, hp: newHp.toString() }));		
	}

	clickDamageAction() {
		if(this.getCurrentHealth().isLessThanOrEqualTo(0))
			return;

		var damage = BigNumber(getUserBonus(EBonuses.CLICK_DAMAGE_CALC));
		var critChance = BigNumber(getUserBonus(EBonuses.CRITICAL_HIT_CHANCE));

		while(critChance.isGreaterThan(0)){
			if(Math.random() < critChance.toNumber()){
				damage = damage.times(getUserBonus(EBonuses.CRITICAL_HIT_DAMAGE));
				critChance = critChance.minus(1);
			}
		}

		this.damage(damage);

		if(this.getCurrentHealth().isLessThanOrEqualTo(0))
			this.destroy();

		else {
			const sound = this.getMineSound()
			sound.volume = calculateEffectivVolume(EConfig.CLICK_VOLUME);
			sound.play();			
		}
	}

	autoDamageAction(miner: IMiner) {
		const damage = calculateMinerAutoDamage(miner);

		this.damage(damage);

		if(this.getCurrentHealth().isLessThanOrEqualTo(0))
			this.destroy();
	}

	destroy() {
		if(!this.interface)
			throw new Error("Ore has no interface");

		if(this.getOreType() === EOreTypes.Crate){
			handleActionOpenCrate(cratesData[0]);
			store.dispatch(setOreType({ x: this.getInterface().x, y: this.getInterface().y, ore: EOreTypes.Empty}));
		} else {			
			handleDestoryOreAction(this.getOreType());
			this.getMineSound().play();

			store.dispatch(addResources({ oreType: this.getOreType(), amount: this.getMineOreReward().toString() }));
			store.dispatch(setOreType({ x: this.getInterface().x, y: this.getInterface().y, ore: EOreTypes.Empty}));
			store.dispatch(addCredits(this.getSellPrice().toString()));
		}

		if(Planet.countRemainingOres() <= 0){
			GameEventManager.getInstance().registerEvent(new GameEvent(() => true, () => handleGenerateNewGridAction(), 100, false));

			if(store.getState().game.currentDepth === store.getState().game.maxDepth){
				if(store.getState().game.config[EConfig.AUTO_PROCEED_LEVEL] === true)
                	store.dispatch(setCurrentDepth(store.getState().game.maxDepth + 1));

				handleChangeMaxDepthAction(store.getState().game.maxDepth + 1);
				store.dispatch(setMaxDepth(store.getState().game.maxDepth + 1));
			}
		}
	}

	getInterface(): IOre {
		if (!this.interface) {
			throw new Error("Ore has no interface");
		}

		return this.interface;
	}

	getOreType(): EOreTypes {
		return this.type;
	}

	getValue(): BigNumber {
		return BigNumber(store.getState().game.resources[this.type]);
	}

	getBaseData(): OreType {
		return oresData[this.type];
	}

	getResouceGainPerSecond(): BigNumber {
		const damagePerSecond = calculateDamagePerSecond();
		const health = this.getBaseData().hp;

		return damagePerSecond.dividedBy(health);
	}

	getResouceGainOverTime(seconds: BigNumber | number): BigNumber {
		return this.getResouceGainPerSecond().times(seconds).times(getUserBonus(EBonuses.AFK_MINE_REWARD_MULTIPLIER));
	}

	getBaseMaxHealth(): BigNumber {
		return BigNumber(this.getBaseData().hp);
	}

	getCurrentHealth(): BigNumber {
		return new BigNumber(this.getInterface().currentHp);
	}

	getMaxHealth(): BigNumber {
		var baseHp = BigNumber(this.getBaseData().hp);
		const reduceHP = getUserBonus(EBonuses.REDUCE_ORE_HP);
		const depth = store.getState().game.currentDepth;

		var bossMultiplier = new BigNumber(1);

		if (depth % 10 === 0) {
			bossMultiplier = config.game.bossHealthMultiplier;
			baseHp = new Ore(
				store.getState().game.planet.ores[store.getState().game.planet.ores.length - 1]
			).getBaseMaxHealth();
		}

		return baseHp
			.dividedBy(reduceHP)
			.multipliedBy(config.game.oreDepthHPFactor.pow(depth))
			.multipliedBy(bossMultiplier);
	}

	getCreditGainOverTime(seconds: BigNumber | number): BigNumber {
		return this.getResouceGainOverTime(seconds).times(this.getBaseData().sell_price);
	}

	getCount(): BigNumber {
		return BigNumber(store.getState().game.resources[this.type]);
	}

	getOreTypeString(): string {
		return EOreTypes[this.type];
	}

	getMineSound() {
		return new Howl({
			src: [`./sounds/mine_${this.getOreTypeString().toLowerCase()}.wav`],
			volume: calculateEffectivVolume(EConfig.ORE_DESTROY_VOLUME),
			preload: true
		});
	}

	getImage(size: ImageSize = ImageSize.Original) {
		return `${assetsBasePath}/ore/${oresData[this.type].identifier}${size !== ImageSize.Original ? /*"_" + size*/ "" : ""}.webp`;
	}

	getMineOreReward(): BigNumber {
		const { currentDepth } = store.getState().game;

		const userBonus = getUserBonus(EBonuses.MINE_REWARD_MULTIPLIER).plus(1);
		return new BigNumber(config.game.rewardExponentialFactor.pow(currentDepth)).times(userBonus);
	}

	getSellPrice(): BigNumber {
		const { currentDepth } = store.getState().game;

		const userBonus = getUserBonus(EBonuses.MINE_REWARD_MULTIPLIER).plus(1);
		return new BigNumber(this.getBaseData().sell_price)
			.times(userBonus)
			.times(config.game.rewardExponentialFactor.pow(currentDepth));
	}

	static selectOreBasedOnRarity(ores: EOreTypes[]): Ore {
		const userBonus = getUserBonus(EBonuses.RARITY_BONUS);
		const depthBonus = store.getState().game.currentDepth / 10;

		const playerRarity = userBonus.plus(depthBonus);

		// check if crate should be selected
		let randomCrate = new BigNumber(Math.random()).times(oresData[EOreTypes.Crate].rarity);

		if (randomCrate.isLessThan(getUserBonus(EBonuses.CRATE_SPAWN_RATE_MULTIPLIER))) {
			return new Ore(EOreTypes.Crate);
		}

		var oreProbabilities: OreProbabilities = {};
		var totalProbability: number = 0;

		for (const ore of ores) {
			if (playerRarity.isLessThan(oresData[ore].rarity)) {
				oreProbabilities[ore] = playerRarity.dividedBy(oresData[ore].rarity).toNumber();
			} else {
				oreProbabilities[ore] = 1;
			}

			totalProbability += oreProbabilities[ore];
		}

		const randomNumber = new BigNumber(Math.random()).times(totalProbability);

		var currentNumber = 0;

		for (const ore of ores) {
			currentNumber += oreProbabilities[ore];

			if (randomNumber.isLessThan(currentNumber)) {
				return new Ore(ore);
			}
		}

		return new Ore(ores[0]);
	}
}

import BigNumber from "bignumber.js";
import { EOreTypes } from "../types/EOreTypes";
import { EResearchTypes } from "../types/EResearchTypes";
import ResearchType from "../types/ResearchType";
import EBonuses from "../types/EBonuses";

export const researchData: Record<EResearchTypes, ResearchType> = {
    [EResearchTypes.Efficiency]: {
        "id": 1,
        "type": EResearchTypes.Efficiency,
        "costs": [
            {
                "oreType": EOreTypes.Iron,
                "value": BigNumber(2),
                "logarithmicFactor": BigNumber(1.25)
            }
        ],
        "bonusType": EBonuses.ALL_DAMAGE_USER_MULTIPLIER,
        "bonusValue": BigNumber(0.05)
    },
    [EResearchTypes.Tradespost]: {
        "id": 2,
        "type": EResearchTypes.Tradespost,
        "costs": [
            {
                "oreType": EOreTypes.Gold,
                "value": BigNumber(10),
                "logarithmicFactor": BigNumber(1.25)
            },
            {
                "oreType": EOreTypes.Copper,
                "value": BigNumber(20),
                "logarithmicFactor": BigNumber(1.25)
            }
        ],
        "bonusType": EBonuses.MINE_REWARD_MULTIPLIER,
        "bonusValue": BigNumber(0.10)
    },
    [EResearchTypes.AsteroidMining]: {
        "id": 3,
        "type": EResearchTypes.AsteroidMining,
        "costs": [
            {
                "oreType": EOreTypes.Graphite,
                "value": BigNumber(5),
                "logarithmicFactor": BigNumber(1.3)
            },
            {
                "oreType": EOreTypes.Titanium,
                "value": BigNumber(2),
                "logarithmicFactor": BigNumber(1.3)
            }
        ],
        "bonusType": EBonuses.AUTO_DAMAGE_USER_MULTIPLIER,
        "bonusValue": BigNumber(0.1)
    },
    
    [EResearchTypes.DeepCoreTech]: {
        "id": 4,
        "type": EResearchTypes.DeepCoreTech,
        "costs": [
            {
                "oreType": EOreTypes.GalacticStone,
                "value": BigNumber(1),
                "logarithmicFactor": BigNumber(1.5)
            }
        ],
        "bonusType": EBonuses.CRITICAL_HIT_CHANCE,
        "bonusValue": BigNumber(0.05)
    },
    
    [EResearchTypes.QuantumAnalysis]: {
        "id": 5,
        "type": EResearchTypes.QuantumAnalysis,
        "costs": [
            {
                "oreType": EOreTypes.MysticQuartz,
                "value": BigNumber(10),
                "logarithmicFactor": BigNumber(1.2)
            },
            {
                "oreType": EOreTypes.Graphite,
                "value": BigNumber(15),
                "logarithmicFactor": BigNumber(1.2)
            }
        ],
        "bonusType": EBonuses.MINE_REWARD_MULTIPLIER,
        "bonusValue": BigNumber(0.15)
    },
    
    [EResearchTypes.SpaceTimeDilation]: {
        "id": 6,
        "type": EResearchTypes.SpaceTimeDilation,
        "costs": [
            {
                "oreType": EOreTypes.GalacticStone,
                "value": BigNumber(3),
                "logarithmicFactor": BigNumber(1.4)
            }
        ],
        "bonusType": EBonuses.AUTO_CLICKS_PER_SECOND,
        "bonusValue": BigNumber(0.1)
    },
    
    [EResearchTypes.StarlightRefining]: {
        "id": 7,
        "type": EResearchTypes.StarlightRefining,
        "costs": [
            {
                "oreType": EOreTypes.Gold,
                "value": BigNumber(20),
                "logarithmicFactor": BigNumber(1.25)
            },
            {
                "oreType": EOreTypes.Titanium,
                "value": BigNumber(5),
                "logarithmicFactor": BigNumber(1.3)
            }
        ],
        "bonusType": EBonuses.REFINERY_SPEED,
        "bonusValue": BigNumber(0.2)
    },
    [EResearchTypes.EnergyEfficiencyOptimization]: {
        "id": 8,
        "type": EResearchTypes.EnergyEfficiencyOptimization,
        "costs": [
            {
                "oreType": EOreTypes.Copper,
                "value": BigNumber(25),
                "logarithmicFactor": BigNumber(1.3)
            },
            {
                "oreType": EOreTypes.Iron,
                "value": BigNumber(30),
                "logarithmicFactor": BigNumber(1.3)
            }
        ],
        "bonusType": EBonuses.UPGRADE_COST_REDUCTION,
        "bonusValue": BigNumber(0.01)
    },
    
    [EResearchTypes.CriticalResourceDetection]: {
        "id": 9,
        "type": EResearchTypes.CriticalResourceDetection,
        "costs": [
            {
                "oreType": EOreTypes.MysticQuartz,
                "value": BigNumber(8),
                "logarithmicFactor": BigNumber(1.35)
            },
            {
                "oreType": EOreTypes.GalacticStone,
                "value": BigNumber(2),
                "logarithmicFactor": BigNumber(1.35)
            }
        ],
        "bonusType": EBonuses.RARITY_BONUS,
        "bonusValue": BigNumber(0.1)
    }    
};
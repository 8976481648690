import { faDiamond } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BigNumber from "bignumber.js";
import React, { useState } from "react";
import { Button, Card, CardBody, CardHeader, Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ascensionSkillData } from "../../../data/ascensionSkillData";
import { formatNumber } from "../../../engine/gameUtils";
import { RootState } from "../../../redux/store";
import { EAscensionSkill } from "../../../types/EAscensionSkill";
import AscensionBonusInfo from "../../AscensionBonusInfo";
import AscensionSkillImage from "../../AscensionSkillImage";
import AscensionSkill from "../../../engine/AscensionSkill";
interface AscensionTabProps {
	amount: BigNumber;
	handleAmountChange: () => void;
}

const AscensionTab: React.FC<AscensionTabProps> = ({ amount, handleAmountChange }) => {
	const { t } = useTranslation();
	const { ascensionPoints, maxDepth, planet } = useSelector(
		(state: RootState) => state.game
	);

	const [showModal, setShowModal] = useState(false);

	// Funktion zum Öffnen des Modals
	const handleOpenModal = () => {
		setShowModal(true);
	};

	// Funktion zum Schließen des Modals
	const handleCloseModal = () => {
		setShowModal(false);
	};

	const AscensionModal = () => (
		<Modal show={showModal} onHide={handleCloseModal}>
			<Modal.Header closeButton>
				<Modal.Title>{t("ascension.modalTitle")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{t("ascension.receivePoints", { points: formatNumber(AscensionSkill.getResetPoints()) })}
				<br />
				{t("ascension.modalIntro")}
				<ul>
					<li>{t("ascension.effectDetail1")}</li>
					<li>{t("ascension.effectDetail2")}</li>
					<li>{t("ascension.effectDetail3")}</li>
					<li>{t("ascension.effectDetail4")}</li>
				</ul>
				{t("ascension.additionalInfo")}
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={handleCloseModal}>
					{t("ascension.cancelButton")}
				</Button>
				<Button variant="primary" onClick={() => {
					AscensionSkill.performAscension();
					handleCloseModal();
				}}>
					{t("ascension.confirmButton")}
				</Button>
			</Modal.Footer>
		</Modal>
	);

	const handleAscension = () => {
		handleOpenModal();
	};

	const handlePurchaseSkill = (skillType: EAscensionSkill) => {
		const skill = new AscensionSkill(skillType);
		skill.upgrade(amount);
	};

	const renderSkill = (skillType: EAscensionSkill, index: string) => {
		const skill: AscensionSkill = new AscensionSkill(skillType);

		let upgradePrice = BigNumber(0);

		upgradePrice = skill.getCost(amount);

		const currentEffect = skill.getEffect();
		const nextEffect = skill.getEffect(amount);

		return (
			<Card key={index} className="mb-3">
				<Card.Body>
					<Card.Title>{t(`ascension.skills.${skill.getIdentifier()}.title`)}</Card.Title>
					<Row>
						<Col md={3}>
							<AscensionSkillImage skill={skill.getType()} />
						</Col>
						<Col md={6}>
							<Card.Subtitle>
								{t(`ascension.skills.${skill.getIdentifier()}.description`, { effect: formatNumber(currentEffect) })}
							</Card.Subtitle>
							<AscensionBonusInfo
								type={skill.getBonusType()}
								baseValue={skill.getBonusAmount()}
								currentValue={currentEffect}
								nextValue={nextEffect}
							/>
						</Col>
						<Col className="align-right" md={3}>
							<Button disabled={upgradePrice.gt(ascensionPoints)} onClick={() => handlePurchaseSkill(skill.getType())}>
								<FontAwesomeIcon icon={faDiamond} /> {formatNumber(upgradePrice)}
							</Button>
						</Col>
					</Row>
				</Card.Body>
			</Card>
		);
	};

	return (
		<Card className="ascension-tab">
			<CardHeader>
				<Card.Title className="tab-title">
					<Row>
						<Col md={10} sm={8}>
							{t("tabs.ascension")}
						</Col>
						<Col md={2} sm={4}>
							<Button variant="primary" onClick={handleAmountChange} className="float-right">
								{amount.toString()}x
							</Button>
						</Col>
					</Row>
				</Card.Title>
			</CardHeader>
			<CardBody>
				<Row>
					<Col md={6}>
						<Button onClick={handleAscension} disabled={maxDepth < planet.ascendingDepth}>
							{maxDepth < planet.ascendingDepth
								? t("ascension.requirement", { level: planet.ascendingDepth })
								: t("ascension.ascendButton", { points: formatNumber(AscensionSkill.getResetPoints()) })}
						</Button>
						{AscensionModal()}
					</Col>
					<Col md={6}>
						<div>{t("ascension.availablePoints", { points: formatNumber(new BigNumber(ascensionPoints)) })}</div>
					</Col>
				</Row>
				<hr />
				<h3>{t("ascension.skillsTitle")}</h3>
				<div className="vh-70">
					{Object.entries(ascensionSkillData).map(([index, skill]) => renderSkill(skill.type, index))}
				</div>
			</CardBody>
		</Card>
	);
};

export default AscensionTab;

import GameEvent from "../../types/GameEvent";

class GameEventManager {
    private static instance: GameEventManager;
    private events: GameEvent[] = [];
    private lastTriggerTimes: Map<GameEvent, number> = new Map();
  
    // Privater Konstruktor, um direkte Instanziierung zu verhindern
    private constructor() {}
  
    // Öffentliche Methode, um auf die Singleton-Instanz zuzugreifen
    public static getInstance(): GameEventManager {
      if (!GameEventManager.instance) {
        GameEventManager.instance = new GameEventManager();
      }
      return GameEventManager.instance;
    }  
  
    // Methode zum Hinzufügen von Events
    registerEvent(event: GameEvent) {
      this.events.push(event);
      this.lastTriggerTimes.set(event, 0);
    }
  
    removeEvent(eventId: number) {
        const eventIndex = this.events.findIndex(event => event.id === eventId);
        if (eventIndex > -1) {
          this.events.splice(eventIndex, 1);
          this.lastTriggerTimes.delete(this.events[eventIndex]);
        }
    }    

    processEvents() {
      const currentTime = Date.now();
      this.events.forEach((event) => {
        const lastTriggerTime = this.lastTriggerTimes.get(event) || 0;
        if (event.checkAndTrigger(lastTriggerTime, currentTime)) {
          if (!event.repeat) {
            // Event entfernen, wenn es nicht wiederholt werden soll
            const index = this.events.indexOf(event);
            this.events.splice(index, 1);
          } else {
            // Aktualisiere die letzte Auslösezeit für wiederholbare Events
            this.lastTriggerTimes.set(event, currentTime);
          }
        }
      });
    }
  }
  
export default GameEventManager;
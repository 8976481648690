import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import config from "../config";
import EPaymentProvider from "../engine/Payment/EPaymentProvider";
import IPaymentProvider from "../engine/Payment/IPaymentProvider";
import { faGooglePay, faPaypal } from "@fortawesome/free-brands-svg-icons";
import i18n from "../i18n/i18n";

const paymentProviderData : Record<EPaymentProvider, IPaymentProvider> = {
    [EPaymentProvider.PAYPAL]: {
        type: EPaymentProvider.PAYPAL,

        calculateFee(price: number): number {
            if(price <= 5) {
                return price * 0.1 + 0.1;
            }
            return price * 0.0249 + 0.35;
        },

        renderPaymentButton(shopEntry, price, onApprove, onCancel, onConfirm, onError) {
            return (
                <PayPalScriptProvider options={{ clientId: config.paypal.clientId, currency: "EUR" }}>
                    <PayPalButtons
                        key={"shop_pp:" + shopEntry.id}
                        style={{ layout: "vertical" }}
                        createOrder={(data, actions) => {
                            return actions.order.create({
                                purchase_units: [
                                    {
                                        description: i18n.t(`shop.items.${shopEntry.identifier}.name`),
                                        amount: {
                                            value: price.toString(),
                                            currency_code: "EUR",
                                        },
                                    },
                                ],
                                intent: "CAPTURE",
                            });
                        }}
                        onApprove={(data, actions) => {
                            if (actions.order) {
                                return actions.order.capture().then((details) => {
                                    onApprove(shopEntry.id);
                                });
                            }

                            return Promise.resolve();
                        }}
                        onCancel={() => onCancel(shopEntry.id)}
                        onError={(err) => onError(shopEntry.id)}
                    />
                </PayPalScriptProvider>
            )
        },

        icon: faPaypal
    },
    
/*
    [EPaymentProvider.GOOGLE_PAY]: {
        type: EPaymentProvider.GOOGLE_PAY,

        calculateFee(price: number): number {
            return price * 0.15;
        },

        renderPaymentButton(shopEntry, price, onApprove, onCancel, onConfirm, onError) {
            return (
                <>
                    <script src="https://pay.google.com/gp/p/js/pay.js" async></script>
                    <button onClick={() => onApprove(shopEntry.id)}>
                        {i18n.t("shop.buy")} for {price} €
                    </button>
                </>
            )
        },

        icon: faGooglePay
    }
    */
}

export default paymentProviderData;
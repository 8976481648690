// GameView.tsx
import { Card, CardHeader, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import CreditsDisplay from "../components/CreditsDisplay/CreditsDisplay";
import DepthNavigation from "../components/DepthNavigation";
import MiningScreen from "../components/MiningScreen/MiningScreen";
import PlanetImage from "../components/PlanetImage";
import StatusEffects from "../components/StatusEffects/StatusEffects";
import UpgradeScreen from "../components/UpgradeScreen/UpgradeScreen";
import { RootState } from "../redux/store";
import { ImageSize as EImageSize } from "../utils/assetsUtils";
import "./GameView.css";
import TimerBox from "../components/TimerBox";

const GameView = () => {
	const { planet } = useSelector((state: RootState) => state.game);

	return (
		<div className="">
			<Row>
				<Col md={5} xl={5} sm={12}>
					<Card className="mining-screen">
						<CardHeader>
							<Row>
								<Col md={1} sm={1}>
									<PlanetImage planet={planet} size={EImageSize.Medium} />
								</Col>
								<Col md={11} sm={11}>
									<CreditsDisplay />
								</Col>
							</Row>
						</CardHeader>
						<Card.Body>
							<Row>
								<Col md={6}>
									<StatusEffects />
								</Col>
								<Col md={3}>
									<TimerBox />
								</Col>
								<Col md={3}>
									<DepthNavigation />
								</Col>
							</Row>
							<MiningScreen />
						</Card.Body>
					</Card>
				</Col>
				<Col md={7} xl={7} sm={12}>
					<div className="upgrade-screen">
						<UpgradeScreen />
					</div>
				</Col>
			</Row>
		</div>
	);
};

export default GameView;

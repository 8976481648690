// Importiere zusätzlich useState, um den Level-Status zu speichern
import { faHammer } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	CardTitle,
	Col,
	FormCheck,
	FormControl,
	InputGroup,
	ListGroup,
	Row,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";

import BigNumber from "bignumber.js";
import { craftingRecipesData } from "../../../data/craftingRecipesData"; // Angenommen, du hast solche Daten
import { formatNumber } from "../../../engine/gameUtils";
import { calculateCraftingMultiplier } from "../../../engine/gameUtils/CraftingUtils";
import { getItemCountById } from "../../../engine/gameUtils/ItemUtils";
import { getImageByItem } from "../../../utils/assetsUtils";

import { PerformCraftAction as performCraftAction } from "../../../engine/gameActions/CraftingActions";
import ICraftingRecipt from "../../../types/ICraftingRecipt";
import "./index.css";

const CraftingTab = () => {
	const { t } = useTranslation();

	const [hideUnavailable, setHideUnavailable] = useState(true);
	const [selectedLevels, setSelectedLevels] = useState<Record<number, number>>({});

	const canCraft = (recipe: ICraftingRecipt, level: number) => {
		var isCraftable: boolean = true;

		recipe.input.forEach((inputItem) => {
			const itemCount = getItemCountById(inputItem.itemId);
			const requiredQuantity = BigNumber(inputItem.quantity).times(calculateCraftingMultiplier(level || 1));

			if (itemCount.lt(requiredQuantity)) {
				isCraftable = false;
			}
		});

		return isCraftable;
	};

	const handleCraftItem = (recipeId: number, level: number) => {
		performCraftAction(recipeId, level);
	};

	const handleChangeLevel = (recipeId: number, value: number) => {
		setSelectedLevels({ ...selectedLevels, [recipeId]: value });
	};

	useEffect(() => {}, [selectedLevels]);

	return (
		<Card className="full-y full-x crafting-tab">
			<CardHeader>
				<Row>
					<Col md={8}>
						<CardTitle>
							{t("tabs.crafting")}
						</CardTitle>
					</Col>
					<Col md={4}>
						<FormCheck
							type="checkbox"
							label={t("crafting.hideUnavailable")}
							checked={hideUnavailable}
							onChange={(e) => setHideUnavailable(e.target.checked)}
						/>
					</Col>
				</Row>
			</CardHeader>
			<CardBody>
				<Row>
					{Object.values(craftingRecipesData).map((recipe, index) =>
						hideUnavailable && !canCraft(recipe, 1) ? null : (
							<Col key={index} sm={12} md={6} lg={4} xl={3}>
								<Card
									className="mb-4"
									style={{ backgroundImage: `url(${getImageByItem(recipe.output)})`, backgroundSize: "cover" }}
								>
									<CardHeader className="crafting-label">{t(`items.${recipe.id}.name`)}</CardHeader>
									<CardBody>
										<ListGroup variant="flush">
											{recipe.input.map((inputItem, inputIndex) => (
												<ListGroup.Item key={inputIndex}>
													<Row
														className={
															getItemCountById(inputItem.itemId).gte(
																BigNumber(inputItem.quantity).times(
																	calculateCraftingMultiplier(selectedLevels[recipe.id] || 1)
																)
															)
																? "requirement-green"
																: "requirement-red"
														}
													>
														<Col md={8}>{t(`items.${inputItem.itemId}.name`)}</Col>
														<Col md={4} className="align-right">
															{formatNumber(
																BigNumber(inputItem.quantity).times(
																	calculateCraftingMultiplier(selectedLevels[recipe.id] || 1)
																)
															)}
														</Col>
													</Row>
												</ListGroup.Item>
											))}
										</ListGroup>
										<InputGroup className="mt-3">
											<InputGroup.Text>{t("crafting.level")}</InputGroup.Text>
											<FormControl
												type="number"
												value={selectedLevels[recipe.id] || 1}
												onChange={(e) => handleChangeLevel(recipe.id, parseInt(e.target.value))}
												min="1"
											/>
										</InputGroup>
										<Button
											variant="primary"
											onClick={() => handleCraftItem(recipe.id, selectedLevels[recipe.id] || 0)}
											className="mt-3"
											disabled={!canCraft(recipe, selectedLevels[recipe.id] || 1)}
										>
											<FontAwesomeIcon icon={faHammer} /> {t("crafting.craft")}
										</Button>
									</CardBody>
								</Card>
							</Col>
						)
					)}
				</Row>
			</CardBody>
		</Card>
	);
};

export default CraftingTab;

import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import paymentProviderData from "../../data/paymentProviderData";
import EPaymentProvider from "./EPaymentProvider";
import IPaymentProvider from "./IPaymentProvider";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import IShopEntry from "../../types/IShopEntry";

export default class PaymentProvider {
    type: EPaymentProvider
    interface: IPaymentProvider

    constructor(type: EPaymentProvider) {
        this.type = type;
        this.interface = paymentProviderData[type];
    }

    calculateFee(price: number): number {
        return Math.round(this.interface.calculateFee(price) * 100) / 100;
    }

    getFontAwesomeIcon(): IconDefinition {
        return this.interface.icon;
    }

    renderPaymentButton(shopEntry: IShopEntry, price: number, onApprove: (shopItemId: number) => void, onCancel: (shopItemId: number) => void, onConfirm: (shopItemId: number) => void, onError: (shopItemId: number) => void) {
        return this.interface.renderPaymentButton(shopEntry, price, onApprove, onCancel, onConfirm, onError);
    }
}
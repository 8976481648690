import EBonuses from "../types/EBonuses";
import { EShopEntryType } from "../types/EShopEntryType";
import IShopEntry from "../types/IShopEntry";

export const shopData: Record<number, IShopEntry> = {
    1: {
        id: 1,
        identifier: "blackmarket",
        maxPurchases: 4,
        price: 1.99,
        type: EShopEntryType.PERM_BOOST,
        data: {
            type: EBonuses.MINE_REWARD_MULTIPLIER,
            value: 1
        }
    },
    2: {
        id: 2,
        identifier: "materialscience",
        maxPurchases: 8,
        price: 0.99,
        type: EShopEntryType.PERM_BOOST,
        data: {
            type: EBonuses.ALL_DAMAGE_USER_MULTIPLIER,
            value: 0.5
        }
    },
    3: {
        id: 3,
        identifier: "refineries",
        maxPurchases: 3,
        price: 1.99,
        type: EShopEntryType.REFINERY,
        data: {
            value: 1
        }
    },

    10: {
        id: 10,
        identifier: "credits_60",
        maxPurchases: 0,
        price: 0.49,

        type: EShopEntryType.CREDIT_MINUTES,
        data: {
            value: 60
        }
    },
    11: {
        id: 11,
        identifier: "credits_720",
        maxPurchases: 0,
        price: 1.29,

        type: EShopEntryType.CREDIT_MINUTES,
        data: {
            value: 720
        }
    },
    12: {
        id: 12,
        identifier: "credits_1440",
        maxPurchases: 0,
        price: 1.99,

        type: EShopEntryType.CREDIT_MINUTES,
        data: {
            value: 1440
        }
    },

    30: {
        id: 30,
        identifier: "mystic_quartz",

        maxPurchases: 1,
        price: 0.99,

        type: EShopEntryType.ITEM,
        data: {
            type: 1006,
            value: 100
        }
    },
    31: {
        id: 31,
        identifier: "galactic_stone",

        maxPurchases: 1,
        price: 0.99,

        type: EShopEntryType.ITEM,
        data: {
            type: 1007,
            value: 50
        }
    }
}
import BigNumber from "bignumber.js";
import React, { useState } from "react";
import { ItemEntry } from "../../types/ItemEntry";
import ItemIconComponent from "../ItemIconComponent";

import { useDispatch, useSelector } from "react-redux";
import { itemData } from "../../data/itemData";
import { handleCombineItemsAction } from "../../engine/gameActions/ItemActions";
import { setConfig } from "../../redux/slices/gameSlice";
import { RootState } from "../../redux/store";
import { EConfig } from "../../types/EConfig";
import { EItemType } from "../../types/EItemType";
import "./index.css";

interface InventorySlotProps {
	item: ItemEntry;
	//onAction: (action: string, item: ItemEntry) => void; // 'action' könnte z.B. 'equip', 'sell', 'discard' sein
}

export const InventorySlot: React.FC<InventorySlotProps> = ({ item }) => {
	const dispatch = useDispatch();
	const [showTooltip, setShowTooltip] = useState<boolean>(true);

	const { config } = useSelector((state: RootState) => state.game);

	const handleDragStart = (event: React.DragEvent<HTMLDivElement>) => {
		setTimeout(() => setShowTooltip(false), 100);
		dispatch(setConfig({ key: EConfig.DRAGGED_ITEM, value: JSON.stringify(item) }));
	};

	const handleRightClick = (event: React.MouseEvent) => {
		event.preventDefault();
	};

	const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
		const draggedItem: ItemEntry = JSON.parse(config[EConfig.DRAGGED_ITEM] || "{}");

		if (
			draggedItem.itemID === item.itemID &&
			draggedItem.id !== item.id &&
			draggedItem.level === item.level &&
			[
				EItemType.ARMOR,
				EItemType.BOOTS,
				EItemType.GLOVES,
				EItemType.HELMET,
				EItemType.PICKAXE,
				EItemType.RELIC,
			].includes(itemData[draggedItem.itemID].type)
		)
			event.preventDefault();
	};

	const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
		const draggedItem: ItemEntry = JSON.parse(config[EConfig.DRAGGED_ITEM] || "{}");
		handleCombineItemsAction(item, draggedItem);
	};

	return (
		<div
			className="inventory-slot"
			onDragOver={handleDragOver}
			onDragEnd={() => setShowTooltip(true)}
			onDragEndCapture={() => setShowTooltip(true)}
			onDrop={handleDrop}
			onContextMenu={handleRightClick}
			draggable
			onDragStart={handleDragStart}
		>
			<ItemIconComponent  itemId={item.itemID} count={BigNumber(item.count)} item={item} showTooltip={showTooltip} setOnDragEnd={setShowTooltip} />
		</div>
	);
};

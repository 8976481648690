import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import config from "../../config";
import { blueprintData } from "../../data/blueprintData";
import { isBlueprintRequirementMeet } from "../../engine/gameUtils/BlueprintUtils";
import { startProduction } from "../../redux/slices/gameSlice";
import RefineryState from "../../types/RefineryState";
import BlueprintSelectionModal from "./BlueprintSelectionModal";
import RefineryInfoComponent from "./RefineryInfoComponent";
import { getItemCountById } from "../../engine/gameUtils/ItemUtils";
import { formatNumber } from "../../engine/gameUtils";
import BigNumber from "bignumber.js";
import Refinery from "../../engine/Refinery";

const RefineryComponent: React.FC<{ refinery: Refinery }> = ({ refinery }) => {
	const [showBlueprintModal, setShowBlueprintModal] = useState(false);
	const [productionProgress, setProductionProgress] = useState(0);

	const dispatch = useDispatch();
	const { t } = useTranslation();
	
	useEffect(() => {
		const updateProgress = () => {
			const currentBlueprint = refinery.getBlueprint();

			if (
				currentBlueprint === null ||
				!refinery.canRefine()
			) {
				setProductionProgress(0);
				return;
			}

			const lastProcessed = refinery.lastRefined;

			if (lastProcessed && currentBlueprint) {
				const speed = refinery.calculateSpeedMultiplier();
				const totalProductionTime = refinery.calculateProductionTime();
				const diff = Date.now() - lastProcessed;
				const progress = (diff / (totalProductionTime * 1000)) * 100;
				setProductionProgress(Math.min(progress, 100)); 
			}
		};

		const timer = setInterval(updateProgress, config.game.updateInterfaceInterval);

		return () => clearInterval(timer);
	}, [refinery.lastRefined, refinery.selectedBlueprintId, refinery.level]);

	const handleSelectBlueprint = (blueprintId: number) => {
		dispatch(startProduction({ refineryId: refinery.id, blueprintId }));
		setShowBlueprintModal(false);
	};

	return (
		<Card className="refinery">
			<CardHeader>
				<Row>
					<Col md={6}>
						<CardTitle>
							{t("refinery.title")} #{refinery.id + 1}
						</CardTitle>
					</Col>
					<Col md={6} className="align-right">
						{t("general.level", { level: refinery.level })}
					</Col>
				</Row>
			</CardHeader>

			<CardBody>
				<RefineryInfoComponent
					blueprint={
						refinery.selectedBlueprintId !== undefined ? blueprintData[refinery.selectedBlueprintId] : undefined
					}
					productionProgress={productionProgress}
					refinery={refinery}
				/>

				<BlueprintSelectionModal
					key={refinery.id}
					show={showBlueprintModal}
					onHide={() => setShowBlueprintModal(false)}
					onSelectBlueprint={handleSelectBlueprint}
					blueprints={Object.values(blueprintData)}
				/>

				<Row>
					<Col>
						<Button onClick={() => setShowBlueprintModal(true)}>{t("blueprints.select")}</Button>
					</Col>
					<Col align="right">
						<Button
							variant="danger"
							onClick={() => refinery.upgrade()}
							disabled={!refinery.canUpgrade()}
						>
							{t("refinery.upgradeFor", {
								current: formatNumber(getItemCountById(config.itemIds.soulOfMiner)),
								cost: formatNumber(refinery.calculateUpgradeCost()),
							})}
						</Button>
					</Col>
				</Row>
			</CardBody>
		</Card>
	);
};

export default RefineryComponent;

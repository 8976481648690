import { gameInitialState } from "../../../redux/slices/gameSlice";
import { RootState } from "../../../redux/store";

export default function migrate_2_to_3(state: RootState) {
    const newState: RootState = { 
        ...state,
        user: { ...state.user },
        game: { ...state.game, powerPlants : gameInitialState.powerPlants }
    };
    
    return newState;
}
import BigNumber from "bignumber.js";
import { store } from "../redux/store";
import { EOreTypes } from "../types/EOreTypes";
import IMission from "../types/IMission";
import { EMissionRewardTypes } from "../types/IMissionReward";
import { EMissingSubjectivTypes as EMissionSubjectivTypes } from "../types/IMissionSubjectiv";
import { oresData } from "./oresData";
import { getCollectedRefineryCount } from "../engine/Refinery";
import Ore from "../engine/Ore";

const missionData: Record<number, IMission> = {
	1: {
		id: 1,
		isAvailable: () => {
			return (
				store.getState().game.planet.ores.includes(EOreTypes.Iron) &&
				store.getState().game.planet.ores.includes(EOreTypes.Gold)
			);
		},
		time: 3600,

		subjective: {
			1: {
				id: 1,
				type: EMissionSubjectivTypes.DESTROY_ORES,

				subjectiveItemId: EOreTypes.Iron,
				value: () => (new Ore(EOreTypes.Iron)).getResouceGainOverTime(100),
			},
			2: {
				id: 2,
				type: EMissionSubjectivTypes.DESTROY_ORES,

				subjectiveItemId: EOreTypes.Gold,
				value: () => (new Ore(EOreTypes.Gold)).getResouceGainOverTime(10),
			},
		},
		rewards: [
			{
				type: EMissionRewardTypes.CURRENCY,
				value: () => {
					return (new Ore(EOreTypes.Sand)).getCreditGainOverTime(600)
				},
			},
			{
				type: EMissionRewardTypes.ITEM,
				identifier: "2000",
				value: () => {
					return BigNumber(3);
				},
			},
		],
	},

	2: {
		id: 2,
		isAvailable: () => {
			return (
				store.getState().game.planet.ores.includes(EOreTypes.Iron) &&
				store.getState().game.planet.ores.includes(EOreTypes.Rock)
			);
		},
		time: 3600,

		subjective: {
			1: {
				id: 1,
				type: EMissionSubjectivTypes.DESTROY_ORES,

				subjectiveItemId: EOreTypes.Rock,
				value: () => (new Ore(EOreTypes.Rock)).getResouceGainOverTime(600),
			},
			2: {
				id: 2,
				type: EMissionSubjectivTypes.DESTROY_ORES,

				subjectiveItemId: EOreTypes.Iron,
				value: () => (new Ore(EOreTypes.Iron)).getResouceGainOverTime(60),
			},
		},

		rewards: [
			{
				type: EMissionRewardTypes.CURRENCY,
				value: () => {
					return (new Ore(EOreTypes.Sand)).getCreditGainOverTime(600)
				},
			},
			{
				type: EMissionRewardTypes.ITEM,
				identifier: "2000",
				value: () => {
					return BigNumber(3);
				},
			},
		],
	},

	3: {
		id: 3,
		isAvailable: () => {
			return (
				store.getState().game.planet.ores.includes(EOreTypes.Coal) &&
				store.getState().game.planet.ores.includes(EOreTypes.Iron)
			);
		},

		time: 7200,

		subjective: {
			1: {
				id: 1,
				type: EMissionSubjectivTypes.DELIVER_ORES,

				subjectiveItemId: EOreTypes.Coal,
				value: () => (new Ore(EOreTypes.Coal)).getResouceGainOverTime(1200),
			},
			2: {
				id: 2,
				type: EMissionSubjectivTypes.DELIVER_ITEMS,

				subjectiveItemId: 1100,
				value: () => (new Ore(EOreTypes.Iron)).getResouceGainOverTime(60),
			},
		},

		rewards: [
			{
				type: EMissionRewardTypes.CURRENCY,
				value: () => {
					return (new Ore(EOreTypes.Sand)).getCreditGainOverTime(1200)
				},
			},
			{
				type: EMissionRewardTypes.ITEM,
				identifier: "301",
				value: () => {
					return BigNumber(1);
				},
			},
			{
				type: EMissionRewardTypes.ITEM,
				identifier: "2000",
				value: () => {
					return BigNumber(5);
				},
			},
		],
	},

	4: {
		id: 4,
		isAvailable: () => {
			return (
				store.getState().game.planet.ores.includes(EOreTypes.Gold) &&
				store.getState().game.planet.ores.includes(EOreTypes.Iron)
			);
		},

		time: 7200,

		subjective: {
			1: {
				id: 1,
				type: EMissionSubjectivTypes.DELIVER_ORES,

				subjectiveItemId: EOreTypes.Gold,
				value: () => (new Ore(EOreTypes.Gold)).getResouceGainOverTime(120),
			},
			2: {
				id: 2,
				type: EMissionSubjectivTypes.DELIVER_ORES,

				subjectiveItemId: EOreTypes.Iron,
				value: () => (new Ore(EOreTypes.Iron)).getResouceGainOverTime(1200),
			},
		},

		rewards: [
			{
				type: EMissionRewardTypes.CURRENCY,
				value: () => {
					return (new Ore(EOreTypes.Sand)).getCreditGainOverTime(1200)
				},
			},
			{
				type: EMissionRewardTypes.ITEM,
				identifier: "2000",
				value: () => {
					return BigNumber(6);
				},
			},
		],
	},

	5: {
		id: 5,
		isAvailable: () => {
			return (
				store.getState().game.planet.ores.includes(EOreTypes.Titanium) &&
				store.getState().game.planet.ores.includes(EOreTypes.Sand)
			);
		},

		time: 60 * 60 * 12,

		subjective: {
			1: {
				id: 1,
				type: EMissionSubjectivTypes.DELIVER_ORES,

				subjectiveItemId: EOreTypes.Titanium,
				value: () => (new Ore(EOreTypes.Titanium)).getResouceGainOverTime(600),
			},
			2: {
				id: 2,
				type: EMissionSubjectivTypes.DELIVER_ORES,

				subjectiveItemId: EOreTypes.Sand,
				value: () => (new Ore(EOreTypes.Sand)).getResouceGainOverTime(60*60*24*50),
			},
		},

		rewards: [
			{
				type: EMissionRewardTypes.CURRENCY,
				value: () => {
					return (new Ore(EOreTypes.Sand)).getCreditGainOverTime(3600)
				},
			},
			{
				type: EMissionRewardTypes.ITEM,
				identifier: "202",
				value: () => {
					return BigNumber(1);
				},
			},
			{
				type: EMissionRewardTypes.ITEM,
				identifier: "2000",
				value: () => {
					return BigNumber(12);
				},
			},
		],
	},

	6: {
		id: 6,
		isAvailable: () => {
			return (
				store.getState().game.planet.ores.includes(EOreTypes.Sand)
			);
		},

		time: 3600 * 6,

		subjective: {
			1: {
				id: 1,
				type: EMissionSubjectivTypes.DELIVER_ITEMS,

				subjectiveItemId: 1000,
				value: () => (new Ore(EOreTypes.Sand)).getResouceGainOverTime(600),
			}
		},

		rewards: [
			{
				type: EMissionRewardTypes.CURRENCY,
				value: () => {
					return (new Ore(EOreTypes.Sand)).getCreditGainOverTime(3600)
				},
			},
			{
				type: EMissionRewardTypes.ITEM,
				identifier: "102",
				value: () => {
					return BigNumber(1);
				},
			},
			{
				type: EMissionRewardTypes.ITEM,
				identifier: "2000",
				value: () => {
					return BigNumber(11);
				},
			},
		],
	},

	100: {
		id: 100,

		isAvailable: () => {
			return (
				store.getState().game.planet.ores.includes(EOreTypes.Sand) &&
				store.getState().game.planet.ores.includes(EOreTypes.Copper)
			);
		},

		time: 7200,

		subjective: {
			1: {
				id: 1,
				type: EMissionSubjectivTypes.DELIVER_ORES,

				subjectiveItemId: EOreTypes.Sand,
				value: () => (new Ore(EOreTypes.Sand)).getResouceGainOverTime(14400),
			},
			2: {
				id: 2,
				type: EMissionSubjectivTypes.DELIVER_ORES,

				subjectiveItemId: EOreTypes.Copper,
				value: () => (new Ore(EOreTypes.Copper)).getResouceGainOverTime(600),
			},
		},

		rewards: [
			{
				type: EMissionRewardTypes.ITEM,

				identifier: "101",
				value: () => {
					return BigNumber(1);
				},
			},
			{
				type: EMissionRewardTypes.ITEM,
				identifier: "2000",
				value: () => {
					return BigNumber(5);
				},
			},
		],
	},
	101: {
		id: 101,

		isAvailable: () => {
			return (
				store.getState().game.planet.ores.includes(EOreTypes.Rock) &&
				store.getState().game.planet.ores.includes(EOreTypes.Iron)
			);
		},

		time: 7200,

		subjective: {
			1: {
				id: 1,
				type: EMissionSubjectivTypes.DELIVER_ORES,

				subjectiveItemId: EOreTypes.Rock,
				value: () => (new Ore(EOreTypes.Rock)).getResouceGainOverTime(14400),
			},
			2: {
				id: 2,
				type: EMissionSubjectivTypes.DELIVER_ORES,

				subjectiveItemId: EOreTypes.Iron,
				value: () => (new Ore(EOreTypes.Iron)).getResouceGainOverTime(600),
			},
		},

		rewards: [
			{
				type: EMissionRewardTypes.ITEM,

				identifier: "201",
				value: () => {
					return BigNumber(1);
				},
			},
			{
				type: EMissionRewardTypes.ITEM,
				identifier: "2000",
				value: () => {
					return BigNumber(5);
				},
			},
		],
	},

	200: {
		id: 200,

		isAvailable: () => {
			return (
				true
			);
		},

		time: 3600,

		subjective: {
			1: {
				id: 1,
				type: EMissionSubjectivTypes.COMPLETE_DEPHS,

				value: () => (store.getState().game.maxDepth + (store.getState().game.planet.ascendingDepth / 5)) * 1.1,
			}
		},

		rewards: [
			{
				type: EMissionRewardTypes.CURRENCY,
				value: () => (new Ore(EOreTypes.Sand)).getCreditGainOverTime(1800)
			},
			{
				type: EMissionRewardTypes.ITEM,
				identifier: "2000",
				value: () => {
					return BigNumber(5);
				},
			},
		],
	},

	201: {
		id: 201,

		isAvailable: () => {
			return (
				true
			);
		},

		time: 7200,

		subjective: {
			1: {
				id: 1,
				type: EMissionSubjectivTypes.COMPLETE_DEPHS,

				value: () => (store.getState().game.maxDepth + (store.getState().game.planet.ascendingDepth / 4)) * 1.3,
			}
		},

		rewards: [
			{
				type: EMissionRewardTypes.CURRENCY,
				value: () => (new Ore(EOreTypes.Sand)).getCreditGainOverTime(3600)
			},
			{
				type: EMissionRewardTypes.ITEM,
				identifier: "2000",
				value: () => {
					return BigNumber(7);
				},
			},
		],
	},
	202: {
		id: 202,
		isAvailable: () => {
			return (
				true
			);
		},

		time: 60*60*12,

		subjective: {
			1: {
				id: 1,
				type: EMissionSubjectivTypes.COMPLETE_DEPHS,

				value: () => (store.getState().game.maxDepth + (store.getState().game.planet.ascendingDepth / 3)) * 1.5,
			},
		},

		rewards: [
			{
				type: EMissionRewardTypes.CURRENCY,
				value: () => (new Ore(EOreTypes.Sand)).getCreditGainOverTime(7200)
			},
			{
				type: EMissionRewardTypes.ITEM,
				identifier: "2000",
				value: () => {
					return BigNumber(13);
				},
			},
		],
	},
	203: {
		id: 203,
		isAvailable: () => {
			return (
				true
			);
		},

		time: 60*60*24,

		subjective: {
			1: {
				id: 1,
				type: EMissionSubjectivTypes.COMPLETE_DEPHS,

				value: () => (store.getState().game.maxDepth + (store.getState().game.planet.ascendingDepth / 2)) * 1.3,
			},
		},

		rewards: [
			{
				type: EMissionRewardTypes.CURRENCY,
				value: () => (new Ore(EOreTypes.Sand)).getCreditGainOverTime(14400)
			},
			{
				type: EMissionRewardTypes.ITEM,
				identifier: "2000",
				value: () => {
					return BigNumber(20);
				},
			},
		],
	},
	301: {
		id: 301,
		isAvailable: () => {
			return (
				getCollectedRefineryCount() <= 3 &&
				store.getState().game.currentDepth >= store.getState().game.planet.ascendingDepth
			);
		},

		time: 60*60*12,

		subjective: {
			1: {
				id: 1,
				type: EMissionSubjectivTypes.COMPLETE_DEPHS,

				value: () => (store.getState().game.maxDepth) * 1.25,
			},
			2: {
				id: 2,
				type: EMissionSubjectivTypes.DELIVER_ITEMS,

				subjectiveItemId: 1005,
				value: () => (new Ore(EOreTypes.Graphite)).getResouceGainOverTime(10),
			},
			3: {
				id: 3,
				type: EMissionSubjectivTypes.DELIVER_ITEMS,

				subjectiveItemId: 1004,
				value: () => (new Ore(EOreTypes.Gold)).getResouceGainOverTime(30),
			},
		},

		rewards: [
			{
				type: EMissionRewardTypes.REFINERY,

				value: () => {
					return BigNumber(1);
				},
			},
		],
	}
};

export default missionData;

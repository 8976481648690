import { EOreTypes } from "../types/EOreTypes";
import { PlanetType } from "../types/PlanetType";

export const planetData: PlanetType[] = [
	{
		id: 1,
		identifier: "moon",
		size_x: 5,
		size_y: 5,
		ascendingDepth: 25,
		requiredPlanets: [],
		ores: [
			EOreTypes.Sand,
			EOreTypes.Iron,
			EOreTypes.Coal,
			EOreTypes.Copper,
			EOreTypes.Graphite,
			EOreTypes.GalacticStone,
		],
	},
	{
		id: 2,
		identifier: "mars",
		size_x: 6,
		size_y: 6,
		ascendingDepth: 50,
		requiredPlanets: [1],
		ores: [
			EOreTypes.Sand,
			EOreTypes.Rock,
			EOreTypes.Iron,
			EOreTypes.Copper,
			EOreTypes.Coal,
			EOreTypes.Gold,
			EOreTypes.Uranium,
		],
	},
	{
		id: 3,
		identifier: "europa",
		size_x: 6,
		size_y: 6,
		ascendingDepth: 75,
		requiredPlanets: [1],
		ores: [
			EOreTypes.Sand,
			EOreTypes.Rock,
			EOreTypes.Iron,
			EOreTypes.Copper,
			EOreTypes.Gold,
			EOreTypes.Graphite,
			EOreTypes.Titanium,
		],
	},
	{
		id: 4,
		identifier: "titan",
		size_x: 7,
		size_y: 7,
		ascendingDepth: 200,
		requiredPlanets: [2],
		ores: [
			EOreTypes.Rock,
			EOreTypes.Iron,
			EOreTypes.Copper,
			EOreTypes.Gold,
			EOreTypes.Graphite,
			EOreTypes.Uranium,
			EOreTypes.Titanium,
			EOreTypes.MysticQuartz,
		],
	},
	{
		id: 5,
		identifier: "enceladus",
		size_x: 10,
		size_y: 10,
		ascendingDepth: 250,
		requiredPlanets: [3],
		ores: [
			EOreTypes.Iron,
			EOreTypes.Copper,
			EOreTypes.Gold,
			EOreTypes.Graphite,
			EOreTypes.Uranium,
			EOreTypes.Titanium,
			EOreTypes.MysticQuartz,
		],
	},
	{
		id: 6,
		identifier: "centauriB",
		size_x: 10,
		size_y: 10,
		ascendingDepth: 500,
		requiredPlanets: [4, 5],
		ores: [
			EOreTypes.Iron,
			EOreTypes.Copper,
			EOreTypes.Gold,
			EOreTypes.Graphite,
			EOreTypes.Titanium,
			EOreTypes.Uranium,
			EOreTypes.MysticQuartz,
			EOreTypes.GalacticStone,
		],
	},
];

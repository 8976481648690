import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setConfig } from '../../redux/slices/gameSlice';
import { RootState } from '../../redux/store';
import { EConfig } from '../../types/EConfig';
import { Card, CardBody, CardHeader, CardTitle, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const SoundSettings = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const config = useSelector((state: RootState) => state.game.config )
    
    const handleVolumeChange = (type: EConfig, value: number | boolean) => {
        dispatch(setConfig({ key: type, value }));

        if(type === EConfig.SOUND_VOLUME){
            dispatch(setConfig({ key: EConfig.EFFECTS_VOLUME_OVERRIDE, value: false }));
            dispatch(setConfig({ key: EConfig.MUSIC_VOLUME_OVERRIDE, value: false }));
        }

        if(type === EConfig.SOUND_VOLUME || type === EConfig.EFFECTS_VOLUME) {
            dispatch(setConfig({ key: EConfig.CLICK_VOLUME_OVERRIDE, value: false }));
            dispatch(setConfig({ key: EConfig.ORE_DESTROY_VOLUME_OVERRIDE, value: false }));
            dispatch(setConfig({ key: EConfig.UPGRADE_VOLUME_OVERRIDE, value: false }));
        }
    };
    
    const handleOverrideChange = (type: EConfig, value: any) => {
        dispatch(setConfig({ key: type, value }));
    };

    const handleDisabledClick = (volumeType: EConfig, overrideType: EConfig) => { 
        if(volumeType !== EConfig.SOUND_VOLUME && !config[overrideType]) {
            handleOverrideChange(overrideType, true);
        }
    };

    const renderSlider = (label: string, volumeType: EConfig, overrideType: EConfig) => (
        <Form.Group onClick={() => handleDisabledClick(volumeType, overrideType)}>
            <Form.Label>{label}</Form.Label>
            <Form.Control
                type="range"
                value={config[volumeType]}
                onChange={(e) => handleVolumeChange(volumeType, parseFloat(e.target.value))}
                min={0}
                max={1}
                step={0.01}
                disabled={!config[overrideType] && volumeType !== EConfig.SOUND_VOLUME}
            />
            {volumeType !== EConfig.SOUND_VOLUME && (
                <Form.Check
                    checked={config[overrideType]}
                    onChange={(e) => handleOverrideChange(overrideType, e.target.checked)}
                    label={t("settings.sound.override.label")}
                />
            )}
        </Form.Group>
    );

    return (
        <Card>
            <CardHeader>
                <CardTitle>
                    {t("settings.sound.label")}
                </CardTitle>
            </CardHeader>
            <CardBody>
                <Form.Check
                    checked={config[EConfig.SOUND_ENABLED]}
                    onChange={(e) => handleVolumeChange(EConfig.SOUND_ENABLED, e.target.checked)}
                    label={t("settings.sound." + EConfig[EConfig.SOUND_ENABLED].toLowerCase() + ".label")}
                />
                <Form.Check
                    checked={config[EConfig.MUSIC_ENABLED]}
                    onChange={(e) => handleVolumeChange(EConfig.MUSIC_ENABLED, e.target.checked)}
                    label={t("settings.sound." + EConfig[EConfig.MUSIC_ENABLED].toLowerCase() + ".label")}
                />
                <Card>
                    <CardBody>
                        {renderSlider(t("settings.sound." + EConfig[EConfig.SOUND_VOLUME].toLowerCase() + ".label"), EConfig.SOUND_VOLUME, EConfig.SOUND_VOLUME_OVERRIDE)}
                        <Card>
                            <CardBody>
                                {renderSlider(t("settings.sound." + EConfig[EConfig.MUSIC_VOLUME].toLowerCase() + ".label"), EConfig.MUSIC_VOLUME, EConfig.MUSIC_VOLUME_OVERRIDE)}
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                {renderSlider(t("settings.sound." + EConfig[EConfig.EFFECTS_VOLUME].toLowerCase() + ".label"), EConfig.EFFECTS_VOLUME, EConfig.EFFECTS_VOLUME_OVERRIDE)}
                                <Card>
                                   <CardBody> 
                                        {renderSlider(t("settings.sound." + EConfig[EConfig.CLICK_VOLUME].toLowerCase() + ".label"), EConfig.CLICK_VOLUME, EConfig.CLICK_VOLUME_OVERRIDE)}
                                        {renderSlider(t("settings.sound." + EConfig[EConfig.UPGRADE_VOLUME].toLowerCase() + ".label"), EConfig.UPGRADE_VOLUME, EConfig.UPGRADE_VOLUME_OVERRIDE)}
                                        {renderSlider(t("settings.sound." + EConfig[EConfig.ORE_DESTROY_VOLUME].toLowerCase() + ".label"), EConfig.ORE_DESTROY_VOLUME, EConfig.ORE_DESTROY_VOLUME_OVERRIDE)}
                                    </CardBody>
                                </Card>
                            </CardBody>
                        </Card>
                    </CardBody>
                </Card>
            </CardBody>
        </Card>
    );
};

export default SoundSettings;

import { gameInitialState } from "../../../redux/slices/gameSlice";
import { userInitialState } from "../../../redux/slices/userSlice";
import { RootState } from "../../../redux/store";

export default function migrate_0_to_1(state: RootState) {
    const newState = { 
        ...state,
        user: { ...state.user, lastPlayed: userInitialState.lastPlayed },
        game: { ...state.game, missions: gameInitialState.missions }
    };
    
    return newState;
}
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BigNumber from "bignumber.js";
import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { formatNumber, getFontAwesomeIconForType, isBonusTypePercentage } from "../../engine/gameUtils";
import EBonuses from "../../types/EBonuses";

interface AscensionBonusInfoProps {
	type: EBonuses;
	baseValue: number;
	currentValue: BigNumber;
	nextValue: BigNumber;
}

const AscensionBonusInfo: React.FC<AscensionBonusInfoProps> = ({ type, baseValue, currentValue, nextValue }) => {
	const { t } = useTranslation();

	const popover = (
		<Popover id={`popover-${type}`}>
			<Popover.Header as="h3">{t(`bonuses.${type}.name`)}</Popover.Header>
			<Popover.Body>{t(`bonuses.${type}.description`, { amount: formatNumber(BigNumber(baseValue), 1, isBonusTypePercentage(type)) })}</Popover.Body>
		</Popover>
	);

	return (
		<OverlayTrigger trigger={["hover", "focus"]} placement="auto" overlay={popover}>
			<div className="bonus-info">
				<FontAwesomeIcon icon={getFontAwesomeIconForType(type)} className="bonus-icon" />
				<span className="bonus-info-text">
					{formatNumber(currentValue, 1, isBonusTypePercentage(type))} → {formatNumber(nextValue, 1, isBonusTypePercentage(type))}
				</span>
			</div>
		</OverlayTrigger>
	);
};

export default AscensionBonusInfo;

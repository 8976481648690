import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { setConfig, setCurrentDepth } from '../../redux/slices/gameSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown, faPause, faPlay } from '@fortawesome/free-solid-svg-icons';
import './index.css'; // Stelle sicher, dass du die zugehörige CSS-Datei erstellst
import { EConfig } from '../../types/EConfig';
import { handleGenerateNewGridAction } from '../../engine/gameActions';

const DepthNavigation = () => {
    const { currentDepth, maxDepth, config } = useSelector((state: RootState) => state.game);

    // config[EConfig.AUTO_PROCEED_LEVEL] : boolean

    const dispatch = useDispatch();

    const toggleAutoProceed = () => {
        const newValue = !config[EConfig.AUTO_PROCEED_LEVEL];
        dispatch(setConfig({ key: EConfig.AUTO_PROCEED_LEVEL, value: newValue }));
    };

    const goDeeper = () => {
        if (currentDepth < maxDepth) {
            dispatch(setCurrentDepth(currentDepth + 1));
            handleGenerateNewGridAction();
        }
    };

    const goHigher = () => {
        if (currentDepth > 1) {
            dispatch(setCurrentDepth(currentDepth - 1));
            handleGenerateNewGridAction();
        }
    };

    return (
        <div className="depth-navigation">
            <button onClick={goHigher} disabled={currentDepth === 1}>
                <FontAwesomeIcon icon={faArrowUp} />
            </button>
            <span>{currentDepth}/{maxDepth}</span>
            <button onClick={goDeeper} disabled={currentDepth === maxDepth}>
                <FontAwesomeIcon icon={faArrowDown} />
            </button>
            <button onClick={toggleAutoProceed}>
                <FontAwesomeIcon icon={config[EConfig.AUTO_PROCEED_LEVEL] ? faPause : faPlay} />
            </button>
        </div>
    );
};

export default DepthNavigation;

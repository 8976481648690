import BigNumber from "bignumber.js";
import { EPowerPlantType } from "./types/EPowerPlantType";
import { EPlattforms } from "./types/EPlattforms";

const config = {
    plattform: EPlattforms.WEB,
    
    game: {
        updateInterfaceInterval: 50,
        tickDuration: 300,
        ascendingPointsFactor: 1.5,
        maxItemId: 1000000,
        maxMissionId: 1000000,
        oreDepthHPFactor: BigNumber(1.53),
        rewardExponentialFactor: BigNumber(1.42),
        saveEncryptionKey: "c5IH08TT96x8lq4wrRLfLhUoMQZ0G9Jd",
        bossHealthMultiplier: BigNumber(5),
        bossTimer: 30,
    },
    itemIds: {
        soulOfMiner: 2000
    },
    mission: {
        maxMissionsOffered: 3,
        maxMissionsActive: 2,

        missionOfferInterval: 3600,
    },
    powerPlant: {
        factors: {
            [EPowerPlantType.COAL]: {
                basePrice: BigNumber("1e6"),
                priceFactor: 8,
                baseResourceCosts: BigNumber(4),
                baseProductionFactor: BigNumber(2)
            },

            [EPowerPlantType.NUCLEAR]: {
                basePrice: BigNumber("1e9"),
                priceFactor: 16,
                baseResourceCosts: BigNumber(1),
                baseProductionFactor: BigNumber(4)
            }
        }
    },
    item: {
        bonusValueExponentialFactor: BigNumber(1.5)
    },
    miner: {
        priceExponentialFactor: BigNumber(1.13)
    },
    paypal: {
        clientId: "AUcCK_ic4GReR-XUlkGTUieYe5OEsfq-eOXYF9b4UwwuXZ_clNJnI_1sLRzLLBoLmNTlXWE2y22IWefO"
    },
    social: {
        discord: "https://discord.gg/XZfgQPk5RA"
    }
} 

export default config;
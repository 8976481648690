async function getPasswordKey(password : string) {
    const enc = new TextEncoder();
    return window.crypto.subtle.importKey(
        'raw',
        enc.encode(password),
        { name: 'PBKDF2' },
        false,
        ['deriveKey']
    );
}

async function deriveKey(passwordKey : CryptoKey, salt : Uint8Array, keyUsage: KeyUsage[]) {
    return window.crypto.subtle.deriveKey(
        {
            name: 'PBKDF2',
            salt: salt,
            iterations: 250000,
            hash: 'SHA-256',
        },
        passwordKey,
        { name: 'AES-GCM', length: 256 },
        false,
        keyUsage
    );
}

export async function encrypt(text: string, password: string) {
    const salt = window.crypto.getRandomValues(new Uint8Array(16));
    const iv = window.crypto.getRandomValues(new Uint8Array(12));
    const passwordKey = await getPasswordKey(password);
    const key = await deriveKey(passwordKey, salt, ['encrypt']);

    const enc = new TextEncoder();
    const encryptedContent = await window.crypto.subtle.encrypt(
        {
            name: 'AES-GCM',
            iv: iv,
        },
        key,
        enc.encode(text)
    );

    const encryptedContentArr = new Uint8Array(encryptedContent);
    let buffer = new Uint8Array(salt.byteLength + iv.byteLength + encryptedContentArr.byteLength);
    buffer.set(salt, 0);
    buffer.set(iv, salt.byteLength);
    buffer.set(encryptedContentArr, salt.byteLength + iv.byteLength);

    return buffer;
}

export async function decrypt(encryptedData: Uint8Array, password: string) {
    const salt = encryptedData.slice(0, 16);
    const iv = encryptedData.slice(16, 28);

    const data = encryptedData.slice(28);

    const passwordKey = await getPasswordKey(password);
    const key = await deriveKey(passwordKey, salt, ['decrypt']);

    const decryptedContent = await window.crypto.subtle.decrypt(
        {
            name: 'AES-GCM',
            iv: iv,
        },
        key,
        data
    );

    const dec = new TextDecoder();
    return dec.decode(decryptedContent)
}

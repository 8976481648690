import { useEffect } from 'react';
import { gameEngine } from '../engine';
import config from '../config';

export function useGameEngine() {
  useEffect(() => {
    gameEngine.start();
    const interval = setInterval(gameEngine.tick, config.game.tickDuration); 

    return () => clearInterval(interval);
  }, []);
}